import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-activity";
import { useStateContext } from "pages/context/StateContext";

const ViewPendingLvs = () => {
  const navigate = useNavigate();
  const [approveIsLoading, setApproveIsLoading] = useState(false);
  const [declineIsLoading, setDeclineIsLoading] = useState(false);
  const { baseUrl, config, PendingLeaveRequest } = useStateContext();
  const { id } = useParams();

  const data = PendingLeaveRequest?.data;

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await axios.get(
  //         `${baseUrl}/admin/leave-request/${id}`,
  //         config()
  //       );
  //       const res = response.data.data;
  //       setData(res);

  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   fetchData();
  // }, []);

  const handleApproval = async (status) => {
    try {
      setApproveIsLoading(true);
      const response = await axios.put(
        `${baseUrl}/admin/leave-request/${id}`,
        { status },
        config()
      );
      toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/home/leave");
      }, 3000);
    } catch (error) {
      console.log(error);
    } finally {
      setApproveIsLoading(false);
    }
  };

  const declineApproval = async (status) => {
    try {
      setDeclineIsLoading(true);
      const response = await axios.put(
        `${baseUrl}/admin/leave-request/${id}`,
        { status },
        config()
      );
      toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/home/leave");
      }, 3000);
    } catch (error) {
      console.log(error);
    } finally {
      setDeclineIsLoading(false);
    }
  };

  return (
    <div className="text-start mt-8 bg-white font-semibold">
      <h1 className="font-semibold text-4xl mb-8 text-center">
        View Leave Request
      </h1>
      <form className="mx-auto md:w-1/2 p-4">
        <div className="mb-4">
          <label className="block">Name</label>
          <p className="border p-3">{data?.user?.firstname}</p>
        </div>

        <div className="mb-4">
          <label className="block">Leave Start Date</label>
          <p className="border p-3">{data?.leave_start_date}</p>
        </div>

        <div className="mb-4">
          <label className="block">Leave End Date</label>
          <p className="border p-3">{data?.leave_end_date}</p>
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-4">
          <button
            type="button"
            onClick={() => handleApproval("approved")}
            className="bg-green-500 flex justify-center items-center text-white px-10 py-3 border rounded"
          >
            {approveIsLoading ? <Spinner /> : "Approve Leave Request"}
          </button>
          <button
            type="button"
            onClick={() => declineApproval("declined")}
            className="bg-red-500 flex justify-center items-center text-white px-10 py-3 border rounded"
          >
            {declineIsLoading ? <Spinner /> : "Decline Leave Request"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ViewPendingLvs;
