import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Spinner } from "react-activity";
import Delete from "../../Assest/images/QuestIcon.png";
import { useStateContext } from "pages/context/StateContext";

const DeleteInventoryModal = ({ isOpen, handleCloseModal, itemId }) => {
  const { getDeleteInventory } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);


  const handleDelete = async () => {
    setIsLoading(true);
    await getDeleteInventory(itemId);
    setIsLoading(false);
    handleCloseModal();
    
  };

  return (
    <>
      {isOpen && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-10 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-1/3 max-h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <div className="text-center">
              <img src={Delete} alt="Delete Icon" className="mx-auto mb-4" />
              <p className="mb-4">Are you sure you want to delete this item?</p>
              <div className="flex justify-center">
                <button
                  onClick={handleDelete}
                  className="bg-red-500 text-white px-6 py-2 rounded mr-4"
                >
                  {isLoading ? <Spinner /> : "Yes"}
                </button>
                <button
                  onClick={handleCloseModal}
                  className="bg-gray-300 px-6 py-2 rounded"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteInventoryModal;
