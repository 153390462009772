import React from "react";
import Table from "../../../Utils/Table";
import { Link } from "react-router-dom";
import SingleRequest from "./singleRequest";

const tableHeader = [
  "S/N",
  "Date",
  "Customer Name",
  "Price",
  "Commission",
  "Payment Status",
  "Order Status",
  "Phone Number",
  "Address",
];
const RequestTable = ({ data, total }) => {
  return (
    <div>
      <div className="pl-6 flex justify-between">
        <h1 className=" font-bold">Total Affiliate Requests({total})</h1>
        <Link to="affiliaterequest" className="text-[#049805] pr-6">
          View all
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="m-6 bg-white shadow-x rounded-lg overflow-hidden w-full max-w-6xl">
          <Table
            headerContent={tableHeader}
            minSize={"1300px"}
            // loadingState={isLoading}
            cols={9}
            data={data}
            notPaginated={true}
          >
            {data?.map((item, index) => (
              <div key={index}>
                <SingleRequest item={item} index={index} />
                <hr className="my-4 border-green-50" />
              </div>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RequestTable;
