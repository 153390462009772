import React, { useMemo, useState } from "react";
import {
	useReactTable,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
} from "@tanstack/react-table";
import Pagination from "./pagination";
import TanBody from "./body";
import SearchComp from "./searchComp";
import { Spinner } from "react-activity";

export const TanTable = ({ columnData, data, loadingState }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const [sorting, setSorting] = useState([]);
	const [pageIndex, setPageIndex] = useState(1);
	const [columnFilters, setColumnFilters] = useState([]);

	const tableData = useMemo(() => data, [data]);
	const columns = useMemo(() => columnData, [columnData]);
	const pageSize = 10;

	const options = {
		data: tableData,
		columns,
		state: {
			globalFilter: searchTerm,
			sorting: sorting,
			columnFilters: columnFilters,
		},
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onGlobalFilterChange: setSearchTerm,
		onSortingChange: setSorting,
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		columnSizing: {},
		columnSizingInfo: {
			startOffset: null,
			startSize: null,
			deltaOffset: null,
			deltaPercentage: null,
			isResizingColumn: false,
			columnSizingStart: [],
		},
	};

	const table = useReactTable(options);
	const startPage = useMemo(() => {
		return Math.max(0, pageIndex - Math.floor(pageSize / 2));
	}, [pageIndex, pageSize]);

	const endPage = useMemo(() => {
		return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
	}, [table, startPage, pageSize]);

	function isPageActive(pageIndex, currentPage) {
		return pageIndex === currentPage;
	}

	const currentPage = table.getState().pagination.pageIndex;

	const paginationButtons = useMemo(() => {
		const buttons = [];
		for (let i = startPage; i <= endPage; i++) {
			buttons.push(
				<button
					key={i}
					onClick={() => {
						table.setPageIndex(i);
						setPageIndex(i);
					}}
					className={`{${
						isPageActive(i, currentPage)
							? "current-page text-white bg-green-600 shadow-md"
							: "text-[#A7B1C5]"
					}} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
				>
					{i + 1}
				</button>
			);
		}
		return buttons;
	}, [startPage, endPage, currentPage]);

	return (
		<div className='bg-white p-4'>
			<SearchComp
				setSearchTerm={setSearchTerm}
				setPageIndex={setPageIndex}
				searchTerm={searchTerm}
			/>
			{loadingState ? (
				<div className='flex items-center justify-center my-10'>
					<Spinner />
				</div>
			) : data.length < 1 ? (
				<h2>No Data to display</h2>
			) : (
				<TanBody table={table} loadingState={loadingState} />
			)}

			<Pagination buttons={paginationButtons} table={table} />
		</div>
	);
};
