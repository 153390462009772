import React from 'react'
import PendingLeave from './components/StaffApp/PendingLeave';
const PendingLeavePage = () => {
  return (
    <div>
      <PendingLeave/>
    </div>
  )
}

export default PendingLeavePage
