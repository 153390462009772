import React, { useMemo, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import ActionModal from "./ActionModal";
import AddPoints from "./AddPoints";
import StaffProfile from "./StaffProfile";
import DeleteStaff from "./DeleteStaff";
import PointHistory from "./PointHistory";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";

const StaffHomeTable = () => {
  const { allStaff, getStaffProfile, isLoading, isStaffDeactivated, getStaffPoint } =
    useStateContext();
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [staffProfile, setStaffProfile] = useState(null);
  const [IsDeactivateButtonClicked, setIsDeactivateButtonClicked] =
    useState(false);
  const [pointHistoryModal, setPointHistoryModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const viewShowModal = () => {
    setShowModal(!showModal);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "data.firstname",
    },
    {
      header: "Phone Number",
      accessorKey: "data.staff_profile.phone_number",
      cell: (item) => (
        <span>
          {item.row.original.data.staff_profile?.phone_number || "Null"}
        </span>
      ),
    },
    {
      header: "Address",
      accessorKey: "data.staff_profile.address",
      cell: (item) => (
        <span>{item.row.original.data.staff_profile?.address || "Null"}</span>
      ),
    },
    {
      header: "Point",
      accessorKey: "point",
    },

    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => {
        const staffData = row.original;
        const handleStaffProfile = (item) => {
          setStaffProfile(item);
        };

        return (
          <>
            <div
              onClick={() => handleStaffProfile(staffData)}
              className="relative"
            >
              <button
                onClick={() => {
                  const currentItem = row.original.data.id;
                  setSelectedRow(currentItem);
                  viewShowModal();
                }}
                className="text-black"
              >
                <BsThreeDots />
              </button>
            </div>
            <StaffProfile
              id={row.original.id}
              isOpen={openUpdateModal}
              onClose={() => setOpenUpdateModal(false)}
              staffProfile={staffProfile}
            />
          </>
        );
      },
    },
  ];
  const data = useMemo(() => allStaff, [allStaff]);
  const columns = useMemo(() => columnData, [columnData]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };
  const table = useReactTable(options);
  const handleFilter = (value) => {
    setFilters(value);
  };
  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center p-4">
        <div className="flex-1 relative mt-4 md:mt-0 w-1/3 md:w-1/2 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-1/3 focus:outline-none focus:ring focus:border-blue-300"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch />
          </div>
        </div>
      </div>

      <table className="mx-auto w-full mt-4">
        <thead className="justify-between">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className=" p-5 text-left bg-gray-200"
                  key={header.id}
                  w={header.getSize()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr className="" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <>
                    <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                      <div className="truncate-text">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <div className="flex items-center justify-between">
        <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {paginationButtons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>
      <ActionModal
        showModal={showModal}
        setShowModal={setShowModal}
        setShowUpdateModal={setShowUpdateModal}
        setOpenUpdateModal={setOpenUpdateModal}
        IsDeactivateButtonClicked={IsDeactivateButtonClicked}
        setIsDeactivateButtonClicked={setIsDeactivateButtonClicked}
        pointHistoryModal={pointHistoryModal}
        setPointHistoryModal={setPointHistoryModal}
        currentEditItem={selectedRow}
      />

      <AddPoints
        isOpen={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        staffId={selectedRow}
      ></AddPoints>

      <DeleteStaff
        isOpen={IsDeactivateButtonClicked}
        onClose={() => setIsDeactivateButtonClicked(false)}
        staffId={selectedRow}
        staffProfile={staffProfile}
      ></DeleteStaff>

      <PointHistory
        isOpen={pointHistoryModal}
        onClose={() => setPointHistoryModal(false)}
        staffId={selectedRow}
      ></PointHistory>
    </div>
  );
};

export default StaffHomeTable;
