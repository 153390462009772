import { config } from "Utils/apiConfig";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import React, { useState } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const DHLModal = ({ isOpen, handleCloseModal }) => {
  const { zones, baseUrl, getShipmentPrices } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [zone, setZone] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}/admin/shipment-price`;
    try {
      const res = await axios.post(
        url,
        { ...formDetails, country: "Nigeria" },
        config()
      );
      toast.success(res.data.message || "Success");
      getShipmentPrices();
      handleCloseModal();
      console.log(res);
    } catch (error) {
      console.log(false);
    } finally {
      setIsLoading(false);
    }
    setFormDetails({});
  };

  const handleInputChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const addPrice = () => {
    const url = `${baseUrl}/admin/shipment-price
`;
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center lg:ml-[13rem] bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Add DHL/UPS Price
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Select Kg:
                </label>
                <select
                  name="range"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={formDetails?.range || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Kg</option>
                  <option value="0.5-2.5">0.5 - 2.5kg</option>
                  <option value="3">3kg</option>
                  <option value="3.5">3.5kg</option>
                  <option value="4">4kg</option>
                  <option value="4.5">kg</option>
                  <option value="5">5kg</option>
                  <option value="6.5">6kg</option>
                  <option value="7">7kg</option>
                  <option value="7.5">7.5kg</option>
                  <option value="8">8kg</option>
                  <option value="8.5">8.5kg</option>
                  <option value="9">9kg</option>
                  <option value="9.5">9.5kg</option>
                  <option value="10">10kg</option>
                </select>
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter Country Name:
                </label>
                <input
                  type="text"
                  name="zone"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="e.g America"
                  value={"Nigeria"}
                  readOnly
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter DHL Price
                </label>
                <input
                  type="number"
                  name="dhl"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="0.00"
                  value={formDetails?.dhl || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter UPS Price
                </label>
                <input
                  type="number"
                  name="ups"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="0.00"
                  value={formDetails?.ups || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Select Zone:
                </label>
                <select
                  name="zone"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={formDetails?.zone || ""}
                  onChange={handleInputChange}
                >
                  <option value="">Select Zone</option>
                  {zones.map(( zone ) => (
                    <option key={zone.id} value={zone.zone}>
                      {zone.zone}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default DHLModal;
