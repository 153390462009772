import React from 'react'
import Location from './components/location/Location'

const locationPage = () => {
  return (
    <div>
      <Location/>
    </div>
  )
}

export default locationPage
