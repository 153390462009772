import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";

const SingleBatchDetails = ({ item, index, openModal }) => {
  const {
    batch,
    country,
    good_declared_value,
    insurance,
    payment_mode,
    payment_status,
    price,
    receiver_address,
    receiver_email,
    receiver_name,
    receiver_phone,
    shipment_id,
    special_delivery_note,
    total_kg,
    tracking_number,
  } = item || {};
  // console.log(item);
  return (
    <div className="grid text-sm grid-cols-6 gap-2">
      <h2>{index + 1}</h2>
      <h2>{tracking_number}</h2>
      <h2>{batch}</h2>
      <h2>{country}</h2>
      <h2>{price}</h2>
      {/* <h2>{total_kg}kg</h2> */}

      <button
        className=" px-4 py-1 rounded"
        onClick={() => openModal(item)} // Set Modal to open when button is clicked
      >
        <FiMoreHorizontal />
      </button>
    </div>
  );
};

export default SingleBatchDetails;
