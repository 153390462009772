import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";

const Modal = ({ show, onClose, onVendorAdded }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allBanks, setAllBanks] = useState([]);
  const [banksLoading, setBanksLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(""); // State to handle error messages
  const { config, token, baseUrl, getAllVendors } = useStateContext();
  const [formData, setFormData] = useState({
    vendor_name: "",
    vendor_address: "",
    vendor_phone_number: "",
    vendor_bank_id: "",
    vendor_bank_account_number: "",
    vendor_bank_account_name: "",
  });

  // Fetch banks data from the server
  const fetchBanks = useCallback(async () => {
    setBanksLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/banks`, config(token));
      setAllBanks(Array.isArray(response.data.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching banks:", error.response?.data || error.message);
      setAllBanks([]);
    } finally {
      setBanksLoading(false);
    }
  }, [baseUrl, config, token]);

  // Effect to fetch banks when modal is shown
  useEffect(() => {
    if (show) {
      fetchBanks();
    }
  }, [show, fetchBanks]);

  // Return null if the modal is not shown
  if (!show) return null;

  // Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}/admin/vendors`;

    try {
      const response = await axios.post(url, formData, config(token));
      onVendorAdded(response.data.data);// Corrected to access the data property
      getAllVendors()
      onClose();
      setFormData({
        vendor_name: "",
        vendor_address: "",
        vendor_phone_number: "",
        vendor_bank_id: "",
        vendor_bank_account_number: "",
        vendor_bank_account_name: "",
      });
    } catch (error) {
      setErrorMessage("Error adding vendor. Please try again."); // Set error message on failure
      console.error("Error adding vendor:", error.response?.data || error.message); // Improved error logging
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl p-6 h-auto max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Vendor Information</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-2xl font-semibold"
          >
            &times;
          </button>
        </div>
        {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>} {/* Display error message */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col">
            <label htmlFor="vendor_name" className="mb-1">
              Name of Vendor
            </label>
            <input
              type="text"
              id="vendor_name"
              name="vendor_name"
              value={formData.vendor_name}
              onChange={handleChange}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="vendor_address" className="mb-1">
              Address
            </label>
            <input
              type="text"
              id="vendor_address"
              name="vendor_address"
              value={formData.vendor_address}
              onChange={handleChange}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="vendor_phone_number" className="mb-1">
              Phone Number
            </label>
            <input
              type="tel"
              id="vendor_phone_number"
              name="vendor_phone_number"
              value={formData.vendor_phone_number}
              onChange={handleChange}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="vendor_bank_id" className="mb-1">
              Bank Name
            </label>
            <select
              id="vendor_bank_id"
              name="vendor_bank_id"
              value={formData.vendor_bank_id}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select a bank</option>
              {banksLoading ? (
                <option value="" disabled>Loading banks...</option>
              ) : allBanks.length > 0 ? (
                allBanks.map((bank) => (
                  <option key={bank.id} value={bank.id}>
                    {bank.bank_name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No banks available</option>
              )}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="vendor_bank_account_number" className="mb-1">
              Bank Account Number
            </label>
            <input
              type="text"
              id="vendor_bank_account_number"
              name="vendor_bank_account_number"
              value={formData.vendor_bank_account_number}
              onChange={handleChange}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="vendor_bank_account_name" className="mb-1">
              Account Name
            </label>
            <input
              type="text"
              id="vendor_bank_account_name"
              name="vendor_bank_account_name"
              value={formData.vendor_bank_account_name}
              onChange={handleChange}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-[#049805] text-white rounded-md hover:bg-[#049805] focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? "Submitting..." : "Submit"} {/* Button text based on loading state */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
