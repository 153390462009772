import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../Utils/Table";
import SingleAffiliateBank from "./SingleAffiliateBank";
import { useStateContext } from "pages/context/StateContext";

const tableHeader = ["S/N", "Acc Name", "Acc No", "Bank Name"];

const AffiliateBankAccount = ({ data, total }) => {
  const { isLoading } = useStateContext();

  return (
    <div>
      <div className="pl-6 flex justify-between">
        <h1 className=" font-bold">Affiliates Bank Account ({total || 0})</h1>
        <Link to="totalbankdetails" className="text-[#049805] pr-6">View all</Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="m-6 bg-white shadow-x rounded-lg overflow-hidden w-full max-w-6xl">
          <Table
            headerContent={tableHeader}
            minSize={"800px"}
            loadingState={isLoading}
            cols={4}
            data={data}
            notPaginated={true}
          >
            {data?.map((item, index) => (
              <div key={index}>
                <SingleAffiliateBank
                  // openModal={handleOpenModal}
                  item={item}
                  index={index}
                />
                <hr className="my-4 border-green-50" />
              </div>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AffiliateBankAccount;