import React from "react";
import { FiMoreHorizontal, FiX } from "react-icons/fi";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import Question from "../../Assest/images/QuestIcon.png";
import Table from "../../../Utils/Table";
import SingleTotalAffiliate from "./SingleTotalAffiliate";
import { useStateContext } from "pages/context/StateContext";
import affpictures from "../../Assest/images/affpictures.png";
// import { useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";

const tableHeader = [
  "S/N",
  "Name",
  // "Amount Earned",
  "Phone number",
  "Date Joined",
  "Action",
];

const TotalAffiliateTable = ({ data, total }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [isBlockModalOpen, setBlockModalOpen] = useState(false);
  const [blockMessage, setBlockMessage] = useState("");
  const [isTopupAffiliateWallet, setIsTopupAffiliateWallet] = useState(null);
  const [isDeductAffiliateWallet, setIsDeductAffiliateWallet] = useState(null);
  // const [singleAffiliate, setSingleAffiliate] = useState();
  const { isLoading, setIsLoading, baseUrl, config, token, imageUrl } =
    useStateContext();

  const getSingleAffiliate = async (id) => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/single-affiliate/${id}`;
    try {
      const res = await axios.get(url, config(token));
      setSelectedAffiliate(res.data.data);
      console.log(res);
      // setSingleAffiliate(res.data.data);
      console.log(res);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    //  getSingleAffiliate()
  }, []);
  //  console.log(id);
  // console.log(singleAffiliate);
  // Function to close the main modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Function to handle opening the details modal
  const handleDetailsModal = (item) => {
    setDetailsModalOpen(true);
    setSelectedAffiliate(item);
    console.log(selectedAffiliate);
  };

  // Function to handle opening the topup modal
  const topupAffiliateWallet = (item) => {
    setIsTopupAffiliateWallet(true);
    setSelectedAffiliate(item);
    setModalOpen(true); // Open the main modal
  };

  // Function to handle opening the deduct modal
  const deductAffiliateWallet = (item) => {
    setIsDeductAffiliateWallet(true);
    setSelectedAffiliate(item);
    setModalOpen(true); // Open the main modal
  };

  // Function to close the details modal
  const handleCloseDetailsModal = () => {
    setDetailsModalOpen(false);
  };

  // Function to handle blocking an affiliate
  const blockAffiliate = () => {
    // Add logic to block the affiliate here
    setBlockMessage(`Affiliate ${selectedAffiliate?.name} has been blocked.`);
    setBlockModalOpen(false);
    setSelectedAffiliate(null);
    // You may want to update your data or make an API call to reflect the blocking.
  };

  // Function to handle opening the main modal
  const handleOpenModal = (item) => {
    setSelectedAffiliate(item);
    setModalOpen(true);
  };

  return (
    <div>
      <div className="pl-6 flex justify-between">
        <h1 className=" font-bold">Total Affiliates ({total || 0})</h1>
        <Link to="totalaffiliate" className="text-[#049805] pr-6">
          View all
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="m-6 bg-white shadow-x rounded-lg overflow-hidden w-full max-w-6xl">
          {/* Table */}
          <Table
            notPaginated={true}
            headerContent={tableHeader}
            minSize={"1000px"}
            loadingState={isLoading}
            cols={5}
            data={data}
          >
            {data?.map((item, index) => (
              <div key={index}>
                <SingleTotalAffiliate
                  openModal={handleOpenModal}
                  item={item}
                  index={index}
                />
                <hr className="my-4 border-green-50" />
              </div>
            ))}
          </Table>
        </div>
      </div>

      {blockMessage && (
        <p className="text-green-600 text-center mt-4">{blockMessage}</p>
      )}

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-8 rounded shadow-lg ">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseModal}
            />
            <button
              className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
              onClick={() => handleDetailsModal(selectedAffiliate)}
            >
              View Affiliate Details
            </button>
            <button
              className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
              onClick={() => topupAffiliateWallet(selectedAffiliate)}
            >
              Topup Affiliate
            </button>
            <button
              className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
              onClick={() => deductAffiliateWallet(selectedAffiliate)}
            >
              Deduct Affiliate
            </button>
            <button
              className="w-full bg-red-500 text-white p-2 rounded"
              onClick={() => setBlockModalOpen(true)}
            >
              Block Affiliate
            </button>
          </div>
        </div>
      )}

      {detailsModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg w-full md:w-[27%]">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseDetailsModal}
            />
            <div className="flex flex-col items-center md:flex-row md:gap-3">
              <div>
                {selectedAffiliate?.profile_photo ? (
                  <img
                    src={`${imageUrl}/${selectedAffiliate.profile_photo}`} 
                    alt="affiliate"
                    className="w-[8rem] h-28"
                  />
                ) : (
                  <div className="w-[8rem] h-28 bg-gray-200 flex items-center justify-center">
                    No Photo
                  </div>
                )}
              </div>
              <div>
                <h2 className="text-xl mb-4">Affiliate Details</h2>
                <p>
                  <strong>Name:</strong> {selectedAffiliate?.firstname}{" "}
                  {selectedAffiliate?.lastname}
                </p>
                <p>
                  <strong>Wallet Balance:</strong>{" "}
                  {selectedAffiliate?.wallet?.wallet_balance}
                </p>
                <p>
                  <strong>Date Joined:</strong>{" "}
                  {selectedAffiliate?.created_at
                    ? format(
                        new Date(selectedAffiliate?.created_at),
                        "yyyy-MM-dd"
                      )
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isBlockModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg w-1/2 flex flex-col items-center">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setBlockModalOpen(false)}
            />
            <h2 className="text-xl mb-4">Block Affiliate Confirmation</h2>
            {/* <img src={selectedAffiliate?.profile_photo || imageUrl || affpictures}  alt="logo" className="w-[9rem] h-24 mb-4" /> */}
            <img src={Question} alt="question" className="w-[16rem] mb-4" />
            <p className="text-center">
              Do you want to block the Affiliate {selectedAffiliate?.name}?
            </p>
            <div className="mt-4 flex justify-center w-full">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={blockAffiliate}
              >
                Block
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => setBlockModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalAffiliateTable;
