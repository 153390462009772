import { config } from "Utils/apiConfig";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const EditModalPrice = ({ isOpen, handleCloseModal, handleSave, editData }) => {
  const { baseUrl, getShipmentPrices } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({});

  useEffect(() => {
    if (editData) {
      setFormDetails(editData);
    }
  }, [editData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}/admin/shipment-price/${editData.id}`;
    try {
      const res = await axios.put(url, { ...formDetails, country: "Nigeria" }, config());
      toast.success(res.data.message || "Price updated successfully");
      getShipmentPrices();
      handleSave();
    } catch (error) {
      toast.error("Error updating price");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Edit DHL/UPS Price
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter DHL Price
                </label>
                <input
                  type="number"
                  name="dhl"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="0.00"
                  value={formDetails.dhl || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter UPS Price
                </label>
                <input
                  type="number"
                  name="ups"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="0.00"
                  value={formDetails.ups || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default EditModalPrice;
