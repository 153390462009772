import React from "react";
import { FiMoreHorizontal, FiX } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import Question from "../../Assest/images/QuestIcon.png";
import Table from "../../../Utils/Table";
import SingleTotalAffiliate from "./SingleTotalAffiliate";
import { useStateContext } from "pages/context/StateContext";
import SingleUnsuccessful from "./SingleUnsuccessful";

const tableHeader = [
  "S/N",
  "Name",
  "Account Number",
  "Bank Name",
  "Date",
  "Amount",
  "Status",
];

const TotalAffiliateTable = ({ data, total }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const { isLoading } = useStateContext();

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDetailsModal = (item) => {
    setDetailsModalOpen(true);
    setSelectedAffiliate(item);
  };

  const handleCloseDetailsModal = () => {
    setDetailsModalOpen(false);
  };

  const handleDeleteAffiliate = () => {
    setModalOpen(false);
    setDeleteModalOpen(false);
    setSelectedAffiliate(null);
  };

  const handleOpenModal = (item) => {
    // console.log(item);
    setModalOpen(true);
  };

  return (
    <div>
      <div className="pl-6 flex justify-between">
        <h1 className=" font-bold">
          Total Unsuccessful Withdrawal({total || 0})
        </h1>
        <Link to="totalUnsuccessful" className="text-[#049805] pr-6">
          View all
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="m-6 bg-white shadow-x rounded-lg overflow-hidden w-full max-w-6xl">
          {/* Table */}
          <Table
            notPaginated={true}
            headerContent={tableHeader}
            minSize={"1000px"}
            loadingState={isLoading}
            cols={7}
            data={data}
          >
            {data?.map((item, index) => (
              <div key={index}>
                <SingleUnsuccessful
                  openModal={handleOpenModal}
                  item={item}
                  index={index}
                />
                <hr className="my-4 border-green-50" />
              </div>
            ))}
          </Table>
        </div>
      </div>

      {deleteMessage && (
        <p className="text-green-600 text-center mt-4">{deleteMessage}</p>
      )}

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseModal}
            />
            <button
              className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
              onClick={() => handleDetailsModal(selectedAffiliate)}
            >
              View Affiliate Details
            </button>
            <button
              className="w-full bg-red-500 text-white p-2 rounded"
              onClick={() => setDeleteModalOpen(true)}
            >
              Delete Affiliate Details
            </button>
          </div>
        </div>
      )}

      {detailsModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg w-1/2">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseDetailsModal}
            />
            <h2 className="text-xl mb-4">Affiliate Details</h2>
            <p>
              <strong>Name:</strong> {selectedAffiliate?.name}
            </p>
            <p>
              <strong>Amount Earned:</strong> {selectedAffiliate?.AmountEarned}
            </p>
            <p>
              <strong>Date Joined:</strong> {selectedAffiliate?.DateJoined}
            </p>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg w-1/2 flex flex-col items-center">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setDeleteModalOpen(false)}
            />
            <h2 className="text-xl mb-4">Delete Confirmation</h2>
            <img src={MyAfrimall} alt="logo" className="w-[9rem] h-24 mb-4" />
            <img src={Question} alt="question" className="w-[16rem] mb-4" />
            <p className="text-center">
              Do you want to delete the Affiliate {selectedAffiliate?.name} from
              the Affiliate Records?
            </p>
            <div className="mt-4 flex justify-center w-full">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleDeleteAffiliate}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalAffiliateTable;
