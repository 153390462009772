// EditModal.js

import React, { useState } from "react";
import { Spinner } from "react-activity";

const EditModal = ({ closeModal }) => {
  const [editedName, setEditedName] = useState("");
  const [editedRole, setEditedRole] = useState("");
  const [editedPassword, setEditedPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleEdit = async () => {
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
      closeModal();
    }, 1000);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-md w-1/2">
        <h2 className="text-xl font-bold mb-4 text-center">Edit Admin Records</h2>
        <label className="block mb-2 font-bold" htmlFor="editedName">
          Admin Name
        </label>
        <input
          type="text"
          id="editedName"
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
          className="w-full border p-2 mb-4"
        />

        <label className="block mb-2 font-bold" htmlFor="editedRole">
          Admin Role
        </label>
        <input
          type="text"
          id="editedRole"
          value={editedRole}
          onChange={(e) => setEditedRole(e.target.value)}
          className="w-full border p-2 mb-4"
        />

              
        <label className="block mb-2 font-bold" htmlFor="editedRole">
          Password
        </label>
        <input
          type="password"
          id="password"
          value={editedPassword}
          onChange={(e) => setEditedPassword(e.target.value)}
          className="w-full border p-2 mb-4"
        />

        <div className="flex justify-between">
          <button
            onClick={handleEdit}
            disabled={isSaving}
            className="bg-[#049805] text-white px-4 py-2 rounded "
          >
            {isSaving ? <Spinner /> : "Update Admin Records"}
          </button>
          <button
            onClick={closeModal}
            className="bg-red-700 text-white px-4 py-2 rounded ml-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
