import React from 'react'
import Complains from './components/StaffApp/Complains';
const ComplainsPage = () => {
  return (
    <div>
      <Complains/>
    </div>
  )
}

export default ComplainsPage
