import React from 'react'
import CargoPrices from './components/Prices/Cargo/CargoPrices'

const CargoPricesPage = () => {
  return (
    <div>
      <CargoPrices/>
    </div>
  )
}

export default CargoPricesPage
