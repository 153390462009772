import React from 'react'
import SeaCargoTable from './components/SeaCargoPlacement/SeaCargoTable'
// import SeaCargo from './components/SeaCargoPlacement/SeaCargo'

const SeaCargoPage = () => {
  return (
    <div>
      <SeaCargoTable/>
      {/* <SeaCargo/> */}
    </div>
  )
}

export default SeaCargoPage

