import React from 'react'
import Admin from './components/Admin/Admin'
const AdminPage = () => {
  return (
    <div>
      <Admin/>
    </div>
  )
}

export default AdminPage
