import React from "react";
import { FiMoreHorizontal, FiX } from "react-icons/fi";
import { useState, useMemo } from "react";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import Question from "../../Assest/images/QuestIcon.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import affpictures from "../../Assest/images/affpictures.png";
import { useStateContext } from "pages/context/StateContext";
import { AiOutlineSearch } from "react-icons/ai";
import { Spinner } from "react-activity";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { format } from 'date-fns';


import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";

const TotalAffiliateTable = () => {
  //  const [tableData, setTableData] = useState();
  const { viewAllAffisuccesswithdraw,  GetViewAllAffisuccesswithdraw, isLoading } =
    useStateContext();

  const [isModalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => (
        <span>{item.row.index + 1}</span>
      ),
    },
    {
      header: "Affiliate Name",
      accessorKey: "firstname", 
      cell: (item) => (
        <span>{item.row.original.account_name}</span>
      ),
    },
    {
      header: "Amount Withdrawn ",
      accessorKey: "amount_to_withdraw",
      cell: (item) => (
        <span>{item.row.original.amount_to_withdraw
        }</span>
      ),
    },
    {
      header: "Bank ",
      accessorKey: "bank_name",
      cell: (item) => (
        <span>{item.row.original.bank_name}</span>
      ),
    },
    {
      header: "Status ",
      accessorKey: "status",
      cell: (item) => (
    <span>{item.row.original.status}</span>  
      ),
    },
    
    {
      header: "Action",
      accessorKey: "action",
      cell: (item) => (
        <button
          onClick={() => {
            const currentItem = item.row.original;
            handleOpenModal()
            setSelectedRow(currentItem);
          }}
          className='text-black'
        >
          <BsThreeDots />
        </button>
      ),
    },
  ];
  

  const data = useMemo(() => viewAllAffisuccesswithdraw, [viewAllAffisuccesswithdraw]); //my data state
  const columns = useMemo(() => columnData, [columnData]);

  const pageSize = 10;
  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnSizing: {},
    columnSizingInfo: {
      startOffset: null,
      startSize: null,
      deltaOffset: null,
      deltaPercentage: null,
      isResizingColumn: false,
      columnSizingStart: [],
    },
  };

  const table = useReactTable(options);

  const handleFilter = (value) => {
    setFilters(value);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDetailsModal = (item) => {
    setDetailsModalOpen(true);
    setSelectedAffiliate(item);
  };

  const handleCloseDetailsModal = () => {
    setDetailsModalOpen(false);
  };

  // const handleDeleteAffiliate = () => {
  //   if (selectedAffiliate) {
  //     const newData = tableData.filter(
  //       (item) => item.serialNo !== selectedAffiliate.serialNo
  //     );
  //    // setTableData(newData);
  //     setDeleteMessage(
  //       `You have successfully deleted ${selectedAffiliate.name} from the Affiliate Records.`
  //     );
  //     setModalOpen(false);
  //     setDeleteModalOpen(false);
  //     setSelectedAffiliate(null);
  //   }
  // };

  const handleOpenModal = (item) => {
    // console.log(item);
    // setItemToDelete(item);
    setModalOpen(true);
  };

  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            // navigate.push(
            //   {
            //     pathname: router.pathname,
            //     query: { page: i + 1 },
            //   },
            //   undefined,
            //   { shallow: true }
            // );
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);

  return (
    <div className=" grid ">
      <div className="p-6">
        <Link to="/home/affiliate">
          {" "}
          <AiOutlineArrowLeft />
        </Link>
      </div>
      <div className="p-6">
        <h1 className=" font-bold text-center">Total Number of Successful Withdraw</h1>
        {/* <Link to="totalaffiliate" className="text-[#049805] pr-6">View all</Link> */}
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center p-4">
        <div className="flex-1 relative mt-4 md:mt-0 w-full md:w-1/2 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-full focus:outline-none focus:ring focus:border-blue-300"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch />
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center items-center">
        <div className="m-6 bg-white shadow-x rounded-lg overflow-hidden w-full max-w-6xl"> */}
      {/* Table */}
      <table>
      <thead>
  {table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <th
          className='p-3 text-left'
          key={header.id}
          w={header.getSize()}
          onClick={header.column.getToggleSortingHandler()}
        >
          {header.column.columnDef.header}
        </th>
      ))}
    </tr>
  ))}
</thead>


        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr className="" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <>
                    <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                      <div className="truncate-text">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {/* PAGINATION*/}
   
      <div className="flex items-center justify-between">
        <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {paginationButtons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>
      {deleteMessage && (
        <p className="text-green-600 text-center mt-4">{deleteMessage}</p>
      )}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-8 rounded shadow-lg">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseModal}
            />
            <button
              className="mb-2 w-full bg-blue-500 text-white p-2 rounded"
              onClick={() => handleDetailsModal(selectedAffiliate)}
            >
              View Affiliate Details
            </button>
            <button
              className="w-full bg-red-500 text-white p-2 rounded"
              onClick={() => setDeleteModalOpen(true)}
            >
              Delete Affiliate Details
            </button>
          </div>
        </div>
      )}
      {detailsModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg w-full md:w-[27%]">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={handleCloseDetailsModal}
            />
            <div className="flex flex-col items-center md:flex-row md:gap-3">
              <div>
                <img
                  src={affpictures}
                  alt="affiliate"
                  className="w-[8rem] h-28"
                />
              </div>
              <div>
                <h2 className="text-xl mb-4">Affiliate Details</h2>
                <p>
                  <strong>Name:</strong> {selectedAffiliate?.name}
                </p>
                <p>
                  <strong>Amount Earned:</strong>{" "}
                  {selectedAffiliate?.AmountEarned}
                </p>
                <p>
                  <strong>Date Joined:</strong> {selectedAffiliate?.DateJoined}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white p-4 rounded shadow-lg w-1/2 flex flex-col items-center">
            <FiX
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setDeleteModalOpen(false)}
            />
            <h2 className="text-xl mb-4">Delete Confirmation</h2>
            <img src={MyAfrimall} alt="logo" className="w-[9rem] h-24 mb-4" />
            <img src={Question} alt="question" className="w-[16rem] mb-4" />
            <p className="text-center">
              Do you want to delete the Affiliate {selectedAffiliate?.name} from
              the Affiliate Records?
            </p>
            <div className="mt-4 flex justify-center w-full">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                // onClick={handleDeleteAffiliate}
              >
                Delete
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded"
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalAffiliateTable;
