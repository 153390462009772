import React from "react";
import bluebarcode from "../../Assest/images/bluebarcode.png";
import greenbarcode from "../../Assest/images/greenbarcode.png";
import blackhouse from "../../Assest/images/blackhouse.png";
import redtransit from "../../Assest/images/redtransit.png";
import { FiRefreshCcw } from "react-icons/fi";
import Chart from "../Scanner/Chart";
import HomeTable from "../Scanner/HomeTable";

const ScannerApp = () => {
  return (
    <div className="bg-[#FFFFFF]">
      <h1 className="font-bold text-center">HISTORY</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-8">
        <div className="bg-[#F7F8F8] rounded-lg shadow-md w-full flex flex-col gap-3 items-start p-3">
          <div className="flex justify-between gap-3 items-center">
            <h2 className="text-sm">Total Number of QR Code</h2>
            {/* <img src={bluebarcode} alt="barcode" className="w-8" /> */}
          </div>
          <p class="text-gray-600 font-bold">3860</p>{" "}
          <div className="flex items-center gap-2">
            <FiRefreshCcw className="w-3" />
            <p className="text-gray-600 text-xs">Last updated on 06/03/2024</p>
          </div>
        </div>

        <div className="bg-[#F7F8F8] rounded-lg shadow-md w-full flex flex-col gap-3 items-start p-3">
          <div className="flex justify-between gap-3 items-center">
            <h2 className="text-sm">Total Packages At Warehouse</h2>
            {/* <img src={blackhouse} alt="barcode" className="w-8" /> */}
          </div>
          <p class="text-gray-600 font-bold">3860</p>{" "}
          <div className="flex items-center gap-2">
            <FiRefreshCcw className="w-3" />
            <p className="text-gray-600 text-xs">Last updated on 06/03/2024</p>
          </div>
        </div>

        <div className="bg-[#F7F8F8] rounded-lg shadow-md w-full flex flex-col gap-3 items-start p-3">
          <div className="flex justify-between gap-3 items-center">
            <h2 className="text-sm">Total Packages In Transit</h2>
            {/* <img src={redtransit} alt="barcode" className="w-8" /> */}
          </div>
          <p class="text-gray-600 font-bold">3860</p>{" "}
          <div className="flex items-center gap-2">
            <FiRefreshCcw className="w-3" />
            <p className="text-gray-600 text-xs">Last updated on 06/03/2024</p>
          </div>
        </div>
      </div>
      {/* chart and table */}
  
      <div className="lg:grid grid-cols-3">
        <div className="md:col-span-2">
          <Chart />
        </div>
        <div className="md:col-span-1">
          <HomeTable />
        </div>
      </div>
    </div>
  );
};

export default ScannerApp;
