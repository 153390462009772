import React from "react";

const SearchComp = ({ searchTerm, setSearchTerm, setPageIndex }) => {
  return (
    <div className="lg:flex justify-between my-3">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setPageIndex(1);
        }}
        className="border rounded px-2 py-1"
      />
    </div>
  );
};

export default SearchComp;
