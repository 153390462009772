import { useStateContext } from "pages/context/StateContext";
import React, { useEffect } from "react";
import { Spinner } from "react-activity";

const Landing = () => {
  const { dashDetails, isLoading } = useStateContext();

  const {
    total_affiliate,
    total_air_cargo,
    total_air_in_transit,
    total_bank_account,
    total_completed_air,
    total_completed_sea,
    total_customers,
    total_processed_air,
    total_processed_sea,
    total_sea_cargo,
    total_sea_in_transit,
    total_successful_withdrawal,
    total_withdrawal_request,
    getAffilateDetails,
    getNews,
  } = dashDetails;
  // console.log(dashDetails);

  if (isLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="p-3">
      <div className="flex gap-2 text-[#049805]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="#049805"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
      </div>

      {/* 1st card */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center my-12 ">
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">Customers</h2>
          <p className="text-gray-600 font-bold">{total_customers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">
            Total Air <br /> Cargo Request
          </h2>
          <p className="text-gray-600 font-bold">{total_air_cargo}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="md:text-xl font-bold">
            Total Sea <br />
            Cargo Request
          </h2>
          <p className="text-gray-600 font-bold">{total_sea_cargo}</p>
        </div>
      </div>

      {/* 2nd card  */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center my-12">
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">
            Processed Sea Cargo Requests
          </h2>
          <p className="text-gray-600 font-bold">{total_processed_sea}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">
            Total Completed Sea Cargo Requests
          </h2>
          <p className="text-gray-600 font-bold">{total_completed_sea}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">
            Processed Air Cargo Requests
          </h2>
          <p className="text-gray-600 font-bold">{total_processed_air}</p>
        </div>
      </div>

      {/* 3nd card  */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center my-12 ">
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">
            Total Completed Air Cargo Requests
          </h2>
          <p className="text-gray-600 font-bold">{total_completed_air}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">Air Cargo In-Transit</h2>
          <p className="text-gray-600 font-bold">{total_air_in_transit}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md w-full md:w-23rem lg:w-23rem h-40 md:h-48 flex flex-col justify-center items-center">
          <h2 className="text-lg md:text-xl font-bold">Sea Cargo In-Transit</h2>
          <p className="text-gray-600 font-bold">{total_sea_in_transit}</p>
        </div>
      </div>

      {/* affiliate report  */}
      <div>
        <h1 className="mx-6 font-bold">Affiliates Report</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center my-12 mx-">
          {/* 1st affiliate card */}
          <div className="bg-[#343434] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of Affiliates
            </h2>
            <p className=" font-bold ">{total_affiliate}</p>
          </div>

          {/* 2nd affiliate card */}
          <div className="bg-[#3771C8] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of Bank Accounts
            </h2>
            <p className=" font-bold">{total_bank_account}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center my-12 ">
          {/* 3rd affiliate card */}
          <div className="bg-[#049805] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of <br />
              Withdrawals request
            </h2>
            <p className=" font-bold ">{total_withdrawal_request}</p>
          </div>

          {/* 4th affiliate card */}
          <div className="bg-[#FF0036] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of <br />
              Successful Withdrawals
            </h2>
            <p className=" font-bold">{total_successful_withdrawal}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
