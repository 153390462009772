import React from "react";
const SingleAffiliateBank = ({ item, index }) => {
  const { account_name, account_number, bank_name } = item || {};
  return (
    <div>
      <div className="grid text-sm grid-cols-4 gap-2">
        <h2>{index + 1}</h2>
        <h2>{account_name}</h2>
        <h2>{account_number}</h2>
        <h2>{bank_name}</h2>
      </div>
    </div>
  );
};

export default SingleAffiliateBank;
