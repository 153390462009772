import React from 'react'
import ShipmentBatch from '../pages/components/shipmentBatch/ShipmentBatch'



const ShipmentBatchPage = () => {
  return (
    <div>
          <ShipmentBatch />
    </div>
  )
}

export default ShipmentBatchPage
