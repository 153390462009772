import React, { useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { useStateContext } from "pages/context/StateContext";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-activity";

const LeaveRequestTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { allLeave, getPendingLeaveRequest } = useStateContext();

  const navigate = useNavigate();

  const handleViewButton = async (id) => {
    setIsLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    try {
      await getPendingLeaveRequest(id);
      navigate(`/home/leave/${id}`);
    } catch (error) {
    } finally {
      setIsLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "name",
      cell: (item) => <span>{item.row.original.user.firstname}</span>,
    },
    {
      header: "Type",
      accessorKey: "request_type",
    },
    {
      header: "Date",
      accessorKey: "leave_start_date",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (props) => (
        <p className="flex items-center justify-start">
          <span
            className={`inline-block px-2 py-1 rounded ${
              props.getValue() === "active"
                ? "bg-green-500 text-white"
                : "bg-blue-500 text-white"
            }`}
          >
            {props.getValue()}
          </span>
        </p>
      ),
    },
    {
      header: "Action",
      accessorKey: "status",
      cell: (item) => (
        <p className="flex items-center justify-start">
          <button
            onClick={() => handleViewButton(item.row.original.id)}
            className="underline text-[#3771C8] border p-2 px-4 rounded w-full flex items-center justify-center"
            disabled={isLoading[item.row.original.id]}
          >
            {isLoading[item.row.original.id] ? <Spinner /> : "View"}
          </button>
        </p>
      ),
    },
  ];
  const columns = useMemo(() => columnData, [columnData]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const data = allLeave ?? [];
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };
  const table = useReactTable(options);
  // const navigate = useNavigation()
  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);

  const handleFilter = (value) => {
    setFilters(value);
  };
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center p-4">
        <div className="flex-1 relative mt-4 md:mt-0 w-1/3 md:w-1/2 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-1/3 focus:outline-none focus:ring focus:border-blue-300"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch />
          </div>
        </div>
      </div>

      <table className="mx-auto w-full mt-4">
        <thead className="justify-between">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className=" p-5 text-left bg-gray-200"
                  key={header.id}
                  w={header.getSize()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr className="" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <>
                  <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                    <div className="truncate-text">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex items-center justify-between">
        <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {paginationButtons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveRequestTable;
