import React from 'react'
import History from './components/Scanner/History'
const HistoryPage = () => {
  return (
    <div>
      <History/>
    </div>
  )
}

export default HistoryPage
