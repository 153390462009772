import React from 'react'
import DhlPrice from './components/Prices/DHL/DhlPrice'

const DhlPricePage = () => {
  return (
    <div>
      <DhlPrice/>
    </div>
  )
}

export default DhlPricePage
