import React from "react";

const SingleViewItem = ({ item, index }) => {
  const { item_id, kg } = item || {};
  return (
    <div className="grid text-sm grid-cols-3 gap-2">
      <h2>{index + 1}</h2>
      <h2>{item_id}</h2>
      <h2>{kg}</h2>
    </div>
  );
};

export default SingleViewItem;
