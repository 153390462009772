import { flexRender } from "@tanstack/react-table";
import React from "react";

const TanRows = ({ table }) => {
	return (
		<tbody>
			{table.getRowModel().rows.map((row) => (
				<tr className='' key={row.id}>
					{row.getVisibleCells().map((cell) => (
						<td className='p-3' key={cell.id} w={cell.column.getSize()}>
							<div className='truncate-text whitespace-nowrap'>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</div>
						</td>
					))}
				</tr>
			))}
		</tbody>
	);
};

export default TanRows;
