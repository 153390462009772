import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart } from "chart.js/auto";

const PieChart = () => {
  const data = {
    labels: ["DHL Shipping", "Sea Cargo", "UPS Delivery", "Air Cargo"],
    datasets: [
      {
        data: [28, 12, 20, 40],
        backgroundColor: ["#0099ff", "#FC6A5A", "#FEAE0D", "#049805"],
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
      },

      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="w-80 h-auto flex flex-col items-center justify-center">
      <p className="font-bold text-center ml-[-2rem] md:ml-[-4rem] mt-2">
        Chart Summary
      </p>
      <div className="w-72 h-40 mt-3 md:ml-[4rem] ml-[6rem]">
        <Pie data={data} options={options} />
      </div>

      <div className="flex flex-col mt-4 ml-[2rem]">
        {data.labels.map((label, index) => (
          <div key={index} className="flex text-md mr-28">
            <div
              className="w-4 h-4 mr-2"
              style={{
                backgroundColor: data.datasets[0].backgroundColor[index],
              }}
            />
            <span className="text-sm flex">{label}</span>
            <div className="w-12 ">
              <span
                className="font-bold ml-10 "
                
              >
                {(
                  (data.datasets[0].data[index] /
                    data.datasets[0].data.reduce((a, b) => a + b, 0)) *
                  100
                ).toFixed(0)}
                %
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;
