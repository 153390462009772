import { useStateContext } from "pages/context/StateContext";
import TanTable from "Utils/TanTable";
import { Spinner } from "react-activity";
import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import EditModalPrice from "./EditModalPrice";

function DHLTable() {
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { getShipmentPrices, isLoading, shipmentPrices } = useStateContext();

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditData(null);
    setIsEditModalOpen(false);
  };

  const handleSave = () => {
    setIsEditModalOpen(false);
    getShipmentPrices();
  };

  const handleDelete = (id) => {
    // Implement your delete logic here
  };

  const columns = [
    {
      header: "S/N",
      accessorKey: "id",
      cell: ({ row }) => <h2>{row.index + 1}</h2>,
    },
    {
      header: "Kg",
      accessorKey: "range",
    },
    {
      header: "Zone",
      accessorKey: "zone",
    },
    {
      header: "UPS Price",
      accessorKey: "ups",
    },
    {
      header: "DHL Price",
      accessorKey: "dhl",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <div className="flex gap-4">
            {isDeleting ? (
              <Spinner />
            ) : (
              <>
                <RiDeleteBinLine
                  onClick={() => handleDelete(rowData.id)}
                  className="text-[#ff4242] cursor-pointer"
                />
                <FiEdit2
                  onClick={() => handleEdit(rowData)}
                  className="cursor-pointer text-[#4d6cf6]"
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <TanTable columnData={columns} data={shipmentPrices} loadingState={isLoading} />
      {isEditModalOpen && (
        <EditModalPrice
          isOpen={isEditModalOpen}
          handleCloseModal={handleEditModalClose}
          handleSave={handleSave}
          editData={editData}
        />
      )}
    </div>
  );
}

export default DHLTable;
