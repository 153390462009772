import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const EditShipmentOrder = () => {
  const [formData, setFormData] = useState({
    
    ReceiverName: "John Doe",
    ReceiverPhoneNumber: "1234567890",
    ReceiverAddress: "123 Main St",
    ReceiverEmail: "john@example.com",
    SenderName: "Jane Smith",
    SenderEmail: "jane@example.com",
    SenderAddress: "456 Elm St",
    SenderPhoneNumber: "9876543210",
    GoodsDeclaredValue: "100",
    SpecialDeliveryNote: "Fragile items inside",
    Price: "50",
    Packagesemail: "package@example.com",
    BoxPackages: "Box123",
    ModeofPayment: "Bank Transfer",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      uploadedFile: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    // navigate("createShipmentContd");
  };

  return (
    <div className="">
      <div className="p-4 font-bold text-center">
        <h1>Edit Shipment Orders</h1>
      </div>

      <form
        className=" shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        {/* First row */}
        <div className="flex mb-4">
       
        </div>

        {/* Second row */}
        
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Receiver Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="ReceiverName"
              name="ReceiverName"
              type="text"
              required
              onChange={handleChange}
              value={formData.ReceiverName}
            />
          </div>

          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Receiver Phone Number
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="ReceiverPhoneNumber"
              name="ReceiverPhoneNumber"
              type="number"
              onChange={handleChange}
              value={formData.ReceiverPhoneNumber}
            />
          </div>
        </div>

        {/* Third row */}
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Receiver Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="ReceiverAddress"
              name="ReceiverAddress"
              type="text"
              onChange={handleChange}
              value={formData.ReceiverAddress}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2 font-bold">
            Receiver Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="Receiver Email"
              name="Receiver Email"
              type="email"
              onChange={handleChange}
              value={formData.ReceiverEmail}
            />
          </div>
        </div>

        {/* Fourth row */}
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Sender Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="SenderName"
              name="SenderName"
              type="text"
              onChange={handleChange}
              value={formData.SenderName}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2 font-bold">Sender Email</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="SenderEmail"
              name="SenderEmail"
              type="email"
              onChange={handleChange}
              value={formData.SenderEmail}
            />
          </div>
        </div>

        {/* Fifth row */}
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Sender Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="SenderAddress"
              name="SenderAddress"
              type="text"
              onChange={handleChange}
              value={formData.SenderAddress}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2 font-bold">Senders Phone Number</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="SenderPhoneNumber"
              name="SenderPhoneNumber"
              type="SenderPhoneNumber"

              onChange={handleChange}
              value={formData.SenderPhoneNumber}
            />
          </div>
        </div>

        {/* Sixth row */}
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Goods Declared Value
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="GoodsDeclaredValue"
              name="GoodsDeclaredValue"
              type="text"
           
              onChange={handleChange}
              value={formData.GoodsDeclaredValue}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2 font-bold">Special Delivery Note</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="SpecialDeliveryNote"
              name="SpecialDeliveryNote"
              type="text"
              onChange={handleChange}
              value={formData.SpecialDeliveryNote}
            />
          </div>
        </div>

   
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
             Price
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="Price"
              name="Price"
              type="text"
              onChange={handleChange}
              value={formData.Price}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2 font-bold">Box Packages</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="BoxPackages"
              name="BoxPackages"
              type="text"
              placeholder=""
              onChange={handleChange}
              value={formData.BoxPackages}
            />
          </div>
        </div>

      
      
        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            Mode of Payment
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="ModeofPayment"
              name="ModeofPayment"
              type="text"
          
              onChange={handleChange}
              value={formData.ModeofPayment}
            />
          </div>
       
        </div>

        {/* Save button */}
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className={`bg-[#049805]  cursor-pointer text-white font-bold py-2 px-4 md:w-1/4 md:self-center rounded-lg focus:outline-none focus:shadow-outline`}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditShipmentOrder;
