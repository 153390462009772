import React from 'react'
import ViewAllAffiBankAcct from "./components/Affiliate/ViewAllAffiBankAcct"
const ViewAllAffiliateBankAcctPage = () => {
  return (
    <div>
     <ViewAllAffiBankAcct/> 
    </div>
  )
}

export default ViewAllAffiliateBankAcctPage
