import React, { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { IoImageOutline } from "react-icons/io5";
import { PiCurrencyNgn } from "react-icons/pi";
import { useStateContext } from "pages/context/StateContext";
import { format } from "date-fns";

const ImportsDetails = () => {
  const { isLoading, singleImport, imageUrl, getSingleImport } =
    useStateContext();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getSingleImport(id);
    }
  }, [id, getSingleImport]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!singleImport || Object.keys(singleImport).length === 0) {
    return <div>No data</div>;
  }

  const formattedDate = singleImport?.created_at
    ? format(new Date(singleImport.created_at), "MMMM dd, yyyy")
    : "";

  return (
    <div className="md:mt-[-2rem]">
      <div className="flex  md:ml-[7rem] ml-8 md:my-8 space-x-8 md:space-x-0 font-bold md:font-normal mb-5 md:mb-0 ">
        <Link to="/home/mobile/imports">
          <span className="md:ml-[16rem] ml-5  font-bold text-2xl md:text-4xl cursor-pointer hover:text-gray-400 transition ease-in-out duration-300 ">
            <BsArrowLeft />
          </span>
        </Link>
        <p className="flex text-xl md:text-3xl mt-7 md:mt-8 md:px-[8rem]">
          Import Details
        </p>
      </div>

      <span>
        <button className="w-44 h-9 bg-red-900 hover:bg-red-700 active:bg-red-600 transition ease-in-out duration-300 mt-4 ml-[2rem] md:ms-[68rem] items-end rounded text-sm text-white font-semibold ">
          Change Delivery status
        </button>
      </span>

      <div className="border-b-2">
        <div className="ml-[2rem] md:ml-[7rem] mt-5 md:flex md:space-x-[14rem] mb-5 space-y-6 md:space-y-0">
          <div>
            <h1 className="font-bold text-xl mb-2">Sender Information</h1>
            <div className="space-y-3">
              <p>Name: {singleImport?.sender_name}</p>
              <p>Country: {singleImport?.sender_country}</p>
              <p>State: {singleImport?.sender_state}</p>
              <p>Address: {singleImport?.sender_address} </p>
              <p>Phone Number: {singleImport?.sender_phone}</p>
              <p>Date of shipment Departure: {formattedDate}</p>
            </div>
          </div>
          <div>
            <h1 className="font-bold text-xl mb-2">Receiver Information</h1>
            <div className="space-y-3">
              <p>Name: {singleImport?.receiver_name}</p>
              <p>Country: {singleImport?.receiver_country}</p>
              <p>State: {singleImport?.receiver_state}</p>
              <p>Zip Code: {singleImport?.receiver_zip_code} </p>
              <p>Address: {singleImport?.receiver_address}</p>
              <p>Phone Number: {singleImport?.receiver_phone}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="ml-[2rem] md:ml-[7rem] mt-5 md:flex md:space-x-[18.5rem] mb-5 space-y-6 md:space-y-0">
        <div>
          <h1 className="font-bold text-xl mb-2">Package Information</h1>
          <div className="space-y-3">
            <p>Weight of goods: {singleImport?.total_shipment_kg}</p>

            {/* <p className="flex space-x-1 text-center">
              Picture of goods:{" "}
              <span className="flex space-x-1 ml-1 md:mt-1 cursor-pointer text-gray-600">
                <IoImageOutline /> <FiDownload />
              </span>
              <p>Download</p>
            </p> */}

            <div className="">
              <p className="p-1">Picture of good:</p>
              {singleImport?.picture_of_package &&
                singleImport?.picture_of_package && (
                  <div className="">
                    <span className="text-gray-600">
                      {/* <IoImageOutline /> */}
                    </span>
                    {/* Move the <img> tag inside the <p> element */}
                    <p>
                      <img
                        src={`${imageUrl}/${singleImport?.picture_of_package}`}
                        alt="Package"
                        className="h-24"
                      />
                    </p>

                    {/* Add a download link */}
                    <a
                      href={`${imageUrl}/${singleImport?.picture_of_package}`}
                      download="picture_of_package.jpg"
                      className="fle space-x-1 items-center text-gray-600"
                    >
                      <FiDownload className="w-24 h-10" />
                    </a>
                    <span>Download</span>
                  </div>
                )}
            </div>
            <p> Insurance: {singleImport?.insurance}</p>
            <p className="flex">
              Value of Package: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> {singleImport?.value_of_package}</p>
            </p>
          </div>
        </div>
        <div>
          <h1 className="font-bold text-xl mb-2">Shipment Items</h1>
          <div className="space-y-3">
            {singleImport?.shipment_item?.map((item, index) => (
              <div key={index} className="border-b pb-2">
                <p>Item: {item.shipment_item}</p>
                <p>Category: {item.category}</p>
                <p>Quantity: {item.quantity}</p>
                <p>Weight: {item.kg} kg</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h1 className="font-bold text-xl mb-2">Other Information</h1>
          <div className="space-y-3">
            <p className="flex">
              Amount of goods: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> 150,000</p>
            </p>
            <p className="flex">
              Insurance Amount: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> {singleImport?.insurance_amount}</p>
            </p>
            <p className="flex">
              Amount to be paid: <PiCurrencyNgn className="mt-1 ml-1" />
              <p> {singleImport?.price} </p>
            </p>
            <p>
              Status of payment:{" "}
              <span className="text-[#049805]">
                {" "}
                {singleImport?.payment_status}{" "}
              </span>
            </p>

            <p>
              Status of Delivery:{" "}
              <button className="w-24 h-8 rounded bg-[#049805] text-white">
                {singleImport?.delivery_status}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportsDetails;
