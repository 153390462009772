import React from 'react'
import StaffHome from './components/StaffApp/StaffHome';

const StaffPage = () => {
  return (
    <div>
      <StaffHome/>
    </div>
  )
}

export default StaffPage