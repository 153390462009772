import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { useStateContext } from "pages/context/StateContext";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
const ComplaintsTable = () => {
  const { allComplaints, getAllComplaints, getViewComplaints } =
    useStateContext();

  const [complains, setComplains] = useState();
  useEffect(() => {
    const result = async () => {
      await getAllComplaints();
    };
    result();
  }, []);
  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "name",
      cell: (item) => <span>{item.row.original.user.firstname}</span>,
    },
    {
      header: "Type",
      accessorKey: "comment_type",
    },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const date = row.original.created_at;
        return <div className="capitalize">{formatDate(date)}</div>;
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (props) => (
        <div className="flex items-center justify-start">
          <span className="capitalize">{props.row.original.status}</span>
          {/* Add view button with navigation */}
          <button
            onClick={() => handleViewButton(props.row.original.id)}
            className="ml-2 bg-[#3771C8] text-white border p-2 px-4 rounded"
          >
            View
          </button>
        </div>
      ),
    },
  ];
  const columns = useMemo(() => columnData, [columnData]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const data = allComplaints ?? [];
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };
  const table = useReactTable(options);
  // const navigate = useNavigation()
  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            // navigate.push(
            //   {
            //     pathname: router.pathname,
            //     query: { page: i + 1 },
            //   },
            //   undefined,
            //   { shallow: true }
            // );
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);
  //   useEffect(() => {
  //     // Read the "page" query parameter from the URL
  //     // const pageFromUrl = router.query.page;

  //     // Set the initial page for the table's pagination
  //     if (typeof pageFromUrl === "string" && !isNaN(Number(pageFromUrl))) {
  //       const initialPage = parseInt(pageFromUrl, 10) - 1;
  //       table.setPageIndex(initialPage);
  //       setPageIndex(initialPage);
  //     }
  //   }, [table]);

  const handleFilter = (value) => {
    setFilters(value);
  };

  const navigate = useNavigate();
  const handleViewButton = async (complaintId) => {
    console.log(complaintId);
    await getViewComplaints(complaintId);
    // Navigate to the details page with the complaintId
    navigate(`/home/complains/${complaintId}`);
  };
  return (
    <div>
      {/* SEARCH TAB */}
      <div className="flex flex-col md:flex-row justify-between items-center p-4">
        <div className="flex-1 relative mt-4 md:mt-0 w-1/3 md:w-1/2 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-1/3 focus:outline-none focus:ring focus:border-blue-300"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch />
          </div>
        </div>
      </div>      
      
      <table className="mx-auto w-full mt-4">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className=" p-6 text-left bg-gray-200"
                  key={header.id}
                  w={header.getSize()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr className="" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <>
                  <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                    <div className="truncate-text">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* PAGINATION */}
      <div className="flex items-center justify-between">
        <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {paginationButtons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComplaintsTable;
