import React, { useState } from "react";
import CreateShipmentTable from "./CreateSipmemtTable";
import { Link } from "react-router-dom";
import { useStateContext } from "pages/context/StateContext";

const CreateShipmentContd = () => {
  const [item, setItem] = useState("");
  const [kg, setKg] = useState("");
  const { shipmentDetails, setShipmentDetails } = useStateContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = shipmentDetails?.details || [];

    const details = [...data, { item_name: item, item_kg: kg }];
    setShipmentDetails({ ...shipmentDetails, details });
    console.log(shipmentDetails);
  };

  return (
    <div>
      <div className="p-6">
        <h1 className="text-center font-bold">Create a Shipment</h1>
      </div>

      <div className="p-3">
        <Link to="/home/create">Back</Link>
      </div>

      <div className="flex justify-between p-2 border-b-2">
        <h2>Items</h2>
        <h2>Kg</h2>
        <h2 className="px-6">Action</h2>
      </div>

      <div>
        <form
          onSubmit={handleSubmit}
          className="flex items-center justify-center space-x-4 p-6 border-b-2"
        >
          <input
            type="text"
            placeholder="Enter Your Items e.g Garri"
            value={item}
            onChange={(e) => setItem(e.target.value)}
            className="border border-gray-300 p-2 rounded-md w-full"
          />
          <input
            type="text"
            placeholder="Enter Item Kg e.g 15kg"
            value={kg}
            onChange={(e) => setKg(e.target.value)}
            className="border border-gray-300 p-2 rounded-md w-full"
          />
          <button
            type="submit"
            className="bg-[#049805] text-white p-2 rounded-md w-full md:max-w-md lg:max-w-lg"
          >
            Add Orders
          </button>
        </form>
        {/* <div className="p-6">
          <Pagination />
        </div> */}
        <div>
          <CreateShipmentTable />
        </div>
      </div>
    </div>
  );
};

export default CreateShipmentContd;
