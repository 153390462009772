import React from "react";
// import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";

const ActionModal = ({
  showModal,
  setShowModal,
  setShowUpdateModal,
  setOpenUpdateModal,
  IsDeactivateButtonClicked,
  setIsDeactivateButtonClicked,
  setPointHistoryModal,
  currentEditItem,
  onDelete,
}) => {
  const { isStaffDeactivated, getStaffPoint } = useStateContext();
  // console.log(currentEditItem);
  return (
    <>
      {showModal ? (
        <div className="fixed inset-0 z-50 flex items-start justify-between py-20 px-10 ">
          <div></div>
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 blur opacity-90"></div>
          <div className="bg-white p-5 rounded shadow-lg w-1/8 mt-10 ml-10 z-10">
            <span className="block text-right">
              <button onClick={() => setShowModal(false)}> &times;</button>
            </span>
            <div className="flex flex-col space-y-4 text-sm">
              <button
                className="bg-[#3771C8] text-white py-1 px-3 rounded"
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setShowUpdateModal(true); // Open AddPoints
                }}
              >
                Add Points
              </button>

              <button
                className="bg-[#049805] text-white py-1 px-3 rounded"
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setOpenUpdateModal(true); // Open View Profile
                }}
              >
                Profile
              </button>

              <button
                className={`${
                  isStaffDeactivated ? "bg-green-500" : "bg-[#FF0036]"
                } text-white py-1 px-3 rounded`}
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setIsDeactivateButtonClicked(!IsDeactivateButtonClicked);
                }}
              >
                {isStaffDeactivated ? "Activate Staff" : " Deactivate Staff"}
              </button>

              <button
                className="bg-[#049823] text-white py-1 px-3 rounded"
                onClick={async () => {
                  setShowModal(false); // Close ActionModal
                  await getStaffPoint(currentEditItem);
                  setPointHistoryModal(true); // Open Point History
                }}
              >
                Point History
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ActionModal;
