import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { FaHome, FaChevronRight } from "react-icons/fa";
import { TfiSearch } from "react-icons/tfi";
import Prices from "./Prices";
import sortIcon from "../../Assest/images/sort.png";

const data = [
  {
    id: 1,
    country_name: "Nigeria",
    zone: "Lagos",
    kg: "65",
    type: "Import",
    price: "₦500",
    action: "edit",
  },
  {
    id: 2,
    country_name: "United States",
    zone: "New York",
    kg: "80",
    type: "Export",
    price: "₦10000",
    action: "view",
  },
  {
    id: 3,
    country_name: "France",
    zone: "Paris",
    kg: "50",
    type: "Import",
    price: "₦7000",
    action: "edit",
  },
  {
    id: 4,
    country_name: "China",
    zone: "Beijing",
    kg: "100",
    type: "Export",
    price: "₦50000",
    action: "delete",
  },
  {
    id: 5,
    country_name: "Brazil",
    zone: "Rio de Janeiro",
    kg: "75",
    type: "Import",
    price: "₦8000",
    action: "view",
  },
  {
    id: 6,
    country_name: "India",
    zone: "Mumbai",
    kg: "90",
    type: "Export",
    price: "₦60000",
    action: "edit",
  },
  {
    id: 7,
    country_name: "Australia",
    zone: "Sydney",
    kg: "55",
    type: "Import",
    price: "₦8500",
    action: "edit",
  },
  {
    id: 8,
    country_name: "Canada",
    zone: "Toronto",
    kg: "70",
    type: "Export",
    price: "₦12000",
    action: "delete",
  },
  {
    id: 9,
    country_name: "Germany",
    zone: "Berlin",
    kg: "85",
    type: "Import",
    price: "₦9000",
    action: "view",
  },
  {
    id: 10,
    country_name: "Japan",
    zone: "Tokyo",
    kg: "95",
    type: "Export",
    price: "₦55000",
    action: "edit",
  },
  {
    id: 11,
    country_name: "South Africa",
    zone: "Johannesburg",
    kg: "60",
    type: "Import",
    price: "₦8000",
    action: "edit",
  },
  {
    id: 12,
    country_name: "United Kingdom",
    zone: "London",
    kg: "75",
    type: "Export",
    price: "₦10000",
    action: "delete",
  },
];

const columns = [
  {
    header: "S/N",
    accessorKey: "id",
  },
  {
    header: "Country Name",
    accessorKey: "country_name",
  },
  {
    header: "Zone",
    accessorKey: "zone",
  },
  {
    header: "Kg",
    accessorKey: "kg",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Price",
    accessorKey: "price",
  },
  {
    header: "Action",
    accessorKey: "action",
  },
];

function PricesTable() {
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState("");

  const handleFilter = (value) => {
    if (value !== filters) {
      setFilters(value);
    }
  };

  useEffect(() => {
    // This will only run when sorting or filters change
    console.log("Sorting or filters changed:", sorting, filters);
  }, [sorting, filters]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: filters,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: (newGlobalFilter) => {
      setFilters(newGlobalFilter);
    },
  });

  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="p-2 flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center md:mb-2 pt-5 mb-5 ">
          <FaHome className="m-0" />
          <p className="text-black">Home</p>
          <FaChevronRight className="m-1 text-[#049805]" />
          <p className="text-[#049805]">Prices</p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col m-6 space-y-6 md:ml-2 mt-[0.3rem] w-1/2 md:w-full ">
          <div className="relative flex items-center ">
            {/* Input starts */}
            <input
              type="text"
              className="rounded-3xl w-1/2  lg:w-[52rem] py-3 outline-none text-xl placeholder-gray-400 pl-12 bg-gray-100"
              placeholder="All"
              value={filters}
              onChange={(e) => handleFilter(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-4 pointer-events-none ">
              <TfiSearch />
            </div>
            <div>
              <img src={sortIcon} className="w-6 h-6 ml-12" />
            </div>
          </div>

          {/* Input Ends */}

          <Prices />

          <table className="">
            <thead className="bg-gray-100 h-12 rounded ">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="text-start pl-3 font-md"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "up", desc: "down" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className="">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="font-sans pt-3 py-3 justify-center border-b-2 text-start pl-3"
                    >
                      <div className="truncate-text">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row space-x-3">
            <button onClick={() => table.setPageIndex(0)}>First page</button>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              Previous page
            </button>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              Next page
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              Last page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricesTable;
