import { useState } from "react";
import TanTable from "Utils/TanTable";
import { AiOutlineHome } from "react-icons/ai";
import { FaChevronRight } from "react-icons/fa";
import Modal from './Modal';
import { useStateContext } from "pages/context/StateContext";

const Vendor = () => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const { isLoading, allVendors, setAllVendors } = useStateContext();

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Vendor's Name",
      accessorKey: "vendor_name",
    },
    {
      header: "Address",
      accessorKey: "vendor_address",
    },
    {
      header: "Phone Number",
      accessorKey: "vendor_number",
    },
    {
      header: "Bank",
      accessorKey: "vendor_accounts.vendor_bank_name",
    },
    {
      header: "Account Number",
      accessorKey: "vendor_accounts.vendor_bank_account_number",
    },
    {
      header: "Account Name",
      accessorKey: "vendor_accounts.vendor_bank_account_name",
    },
  ];

  const handleVendorAdded = (newVendor) => {
   
    setAllVendors((prevVendors) => [...prevVendors, newVendor]);
  
    setSuccessMessage("Vendor added successfully!");
    
    setTimeout(() => setSuccessMessage(""), 3000);
  };

  const toggleModal = () => {
    setShowModal(!showModal); 
  };

  return (
    <div>
      <div className="flex gap-2 text-black p-3">
        <AiOutlineHome className="w-8 h-6" />
        <h1>Home</h1>
        <FaChevronRight className="w-8 h-5" />
        <h1 className="text-[#049805]">Vendors</h1>
      </div>
      <div className="flex justify-end p-6">
        <button
          className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
          onClick={toggleModal}
        >
          Add Vendor
        </button>
      </div>
      {successMessage && (
        <div className="p-4 mb-4 text-green-800 border border-green-300 rounded bg-green-50" role="alert">
          {successMessage}
        </div>
      )}
      <div>
        <TanTable
          columnData={columnData}
          data={allVendors}
          loadingState={isLoading}
        />
      </div>
      <Modal 
        show={showModal} 
        onClose={toggleModal} 
        onVendorAdded={handleVendorAdded} // Pass the new function as a prop
      />
    </div>
  );
};

export default Vendor;
