import React, { useEffect } from "react";
import CreateBatches from "./CreateBatchesTable";
import { useStateContext } from "../../context/StateContext";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const ShipmentBatch = () => {
  const {
    formData,
    setFormData,
    countries,
    token,
    baseUrl,
    config,
    getAllBatches,
    getAllCountries,
    isLoading,
    setIsLoading,
    page,
  } = useStateContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}/admin/create-batch`;
    try {
      const res = await axios.post(url, formData, config(token));
      toast.success("Batch created successfully");
      getAllBatches();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <div className="my-2 font-bold">
        <h1>Create a New Batch</h1>
      </div>
      <form className="rounded px-8 py-2" onSubmit={handleSubmit}>
        {/* First row */}
        <div className="flex mb-4">
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2">
              Start Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="BatchStartingDate"
              name="start_date"
              required
              type="date"
              placeholder=""
              onChange={handleChange}
              value={formData?.start_date || Date.now()}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2">End Date</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="BatchEndingDate"
              name="end_date"
              type="date"
              required
              placeholder=""
              onChange={handleChange}
              value={formData?.end_date || Date.now()}
            />
          </div>
        </div>

        {/* Add more form fields as needed */}
        <div className="flex mb-4 ml-2">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Country
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="country"
              name="country"
              type="text"
              required
              placeholder="Enter Country"
              onChange={handleChange}
              value={formData?.country}
            >
              {countries?.map(({ country_name }, ind) => (
                <option key={ind} value={country_name}>
                  {country_name}
                </option>
              ))}
            </select>
          </div>

          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Shipment
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="shipment"
              name="shipment_type_id"
              required
              onChange={handleChange}
              value={formData?.shipment_type_id}
            >
              <option value="">Select Type</option>
              <option value="1">Air</option>
              <option value="2">Sea</option>
            </select>
          </div>
        </div>
        <div className="flex justify-center items-center p-6">
          <button
            type="submit"
            className="bg-[#049805]  cursor-pointer text-white font-bold py-2 px-4 md:w-1/4 md:self-center rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            {isLoading ? <Spinner /> : "Create New Batch"}
          </button>
        </div>
      </form>
      <CreateBatches />
    </div>
  );
};

export default ShipmentBatch;
