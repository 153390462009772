import React,{useState} from "react";
import Question from "../../Assest/images/QuestIcon.png";
import { Spinner } from "react-activity";
import { useStateContext } from "pages/context/StateContext";
import { ToastContainer } from "react-toastify";

const DeleteStaff = ({ isOpen, onClose, staffId, staffProfile, isLoading }) => {
  const { getDeleteStaff,getRestoreStaff } = useStateContext();
  const [isStaffDeactivated, setIsStaffDeactivated] = useState(false);

  const deleteStaff = async () => {
    await getDeleteStaff(staffId);
        setIsStaffDeactivated(true);
  };
  const handleActiveStaff = async () => {
    await getRestoreStaff(staffId);
  };

// console.log(staffId)
  return (
    <>
      {isOpen && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-10 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-1/2 max-h-full overflow-y-auto">
            <button onClick={onClose} className="float-right">
              X
            </button>
            <div className="text-center">
              <img src={Question} alt="Delete Icon" className="mx-auto mb-4" />
              <h1 className="mb-4">
                { isStaffDeactivated ? (
                   ` Do you want to activate ${staffProfile?.data?.staff_profile?.full_name}?`
                )
                :( ` Do you want to delete ${staffProfile?.data?.staff_profile?.full_name} from the staff record?`
                )}

                
              
              </h1>
              <div className="flex justify-center p-6">
                <button
                  onClick={onClose}
                  className="bg-gray-300 px-6 py-2  rounded mr-4"
                >
                  Cancel
                </button>
                {isStaffDeactivated ? ( <button
                  onClick={handleActiveStaff}
                  className="bg-green-500 text-white px-6 py-2 rounded"
                >
                  {isLoading ? <Spinner /> : "Activate Staff"}
                </button>):( <button
                  onClick={deleteStaff}
                  className="bg-red-500 text-white px-6 py-2 rounded"
                >
                  {isLoading ? <Spinner /> : "Deactivate Staff"}
                </button>)}
               
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer/>
    </>
  );
};

export default DeleteStaff;
