import React from 'react'
import TotalAffiliateRequest from './components/TotalAffiliateRequest/TotalAffiliateRequest'

const TotalAffiliateRequestPage = () => {
  return (
    <div>
      <TotalAffiliateRequest/>
    </div>
  )
}

export default TotalAffiliateRequestPage
