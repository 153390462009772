import React from 'react'
import ViewAllTotalAffiliate from "./components/Affiliate/ViewAllTotalAffiliate"
const ViewAllTotalAffiliatePage = () => {
  return (
    <div>
      <ViewAllTotalAffiliate/>
    </div>
  )
}

export default ViewAllTotalAffiliatePage
