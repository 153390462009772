import React from 'react'
import EditShipmentOrder from './components/AirCargoPlacement/EditShipmentOrder'

const EditShipMentOrderPage = () => {
  return (
    <div>
      <EditShipmentOrder/>
    </div>
  )
}

export default EditShipMentOrderPage
