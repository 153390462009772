import React from 'react'
import AdminOfficer from './components/AdminOfficer/AdminOfficer'
const AdminOfficerPage = () => {
  return (
    <div>
      <AdminOfficer/>
    </div>
  )
}

export default AdminOfficerPage

