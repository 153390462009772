import { useStateContext } from "pages/context/StateContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { format } from "date-fns";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { Link } from "react-router-dom";
import { FaHome, FaChevronRight } from "react-icons/fa";

const SeaCargoTable = () => {
  const { sea, getSingleSea, isLoading } = useStateContext();
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Shipment Date",
      accessorKey: "created_at",
      cell: (item) => (
        <span>
          {item.row.original.created_at
            ? format(new Date(item.row.original.created_at), "yyyy-MM-dd")
            : "N/A"}
        </span>
      ),
    },
    {
      header: "Name",
      accessorKey: "sender_name",
    },
    {
      header: "Location",
      accessorKey: "sender_country",
    },
    {
      header: "Destination Country",
      accessorKey: "receiver_country",
    },
    {
      header: "Status",
      accessorKey: "delivery_status",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (item) => (
        <button
           onClick={() => handleSingleSea(item.row.original.id)}
          className="view-btn bg-green-500 text-white rounded px-2 py-1"
        >
          view
        </button>
      ),
    },
  ];

  const navigate = useNavigate();
  const data = useMemo(() => sea, [sea]);
  const columns = useMemo(() => columnData, [columnData]);

  const pageSize = 10;

  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnSizing: {},
    columnSizingInfo: {
      startOffset: null,
      startSize: null,
      deltaOffset: null,
      deltaPercentage: null,
      isResizingColumn: false,
      columnSizingStart: [],
    },
  };

  const table = useReactTable(options);

  const handleFilter = (value) => {
    setFilters(value);
    setPageIndex(1); // Reset to the first page when applying a new filter
  };

  const handleSingleSea = async (id) => {
    await getSingleSea(id);
    navigate("/home/mobile/seacargodetails");
  };

  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;

  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            // navigate.push(
            //   {
            //     pathname: router.pathname,
            //     query: { page: i + 1 },
            //   },
            //   undefined,
            //   { shallow: true }
            // );
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);

  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="p-2 flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center md:mb-2 pt-5 mb-5 ">
          <FaHome className="m-0" />
          <p className="text-black">Home</p>
          <FaChevronRight className="m-1 text-[#049805]" />
          <p className="text-[#049805]">Sea Cargo</p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col m-6 space-y-6 md:ml-2 mt-[0.3rem] w-1/2 md:w-full">
          {/* Input starts */}
          <input
            type="text"
            className="border-2 rounded-xl w-1/2 py-1 outline-none text-xl placeholder-gray-400 pl-2"
            placeholder="Search"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          {/* Input Ends */}

          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className="p-3 text-left"
                      key={header.id}
                      w={header.getSize()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.column.columnDef.header}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <div className="flex items-center justify-center h-screen mx-auto">
                {/* <Spinner /> */}
              </div>
            ) : (
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr className="" key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <>
                        <td
                          className="p-3"
                          key={cell.id}
                          w={cell.column.getSize()}
                        >
                          <div className="truncate-text">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        </td>
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {/* PAGINATION*/}
          <div className="flex items-center justify-between">
            <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
              <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
                {" "}
                {table.getState().pagination.pageIndex + 1}{" "}
              </span>
              <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
            </div>

            <div className="flex">
              <button
                isDisabled={!table.getCanPreviousPage()}
                onClick={() => table.previousPage()}
                className="mr-5"
              >
                <BsChevronDoubleLeft />
              </button>
              <ul className="flex justify-end gap-5">
                {paginationButtons.map((u, index) => (
                  <li key={index}> {u}</li>
                ))}
              </ul>

              <button
                isDisabled={!table.getCanNextPage()}
                onClick={() => table.nextPage()}
                className="ml-5"
              >
                <BsChevronDoubleRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeaCargoTable;
