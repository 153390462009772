import React from 'react'
import BatchDetails from '../pages/components/shipmentBatchDetails/BatchDetails';


const BatchDetailsPage = () => {
  return (
    <div>
      <BatchDetails />
   
    </div>
  )
}

export default BatchDetailsPage
