import React from "react";
import { useStateContext } from "pages/context/StateContext";
import Table from "Utils/Table";
import DateFormatter from "Utils/DateFormatter";
const PointHistory = ({ isOpen, onClose, staffId }) => {
  const { staffPoint, isLoading } = useStateContext();
  const header = ["S/N", "Date", "Point", "Acknowledgment"];
  return (
    <>
      {isOpen && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded  h-3/4 overflow-y-auto">
            <button
              onClick={onClose}
              className="ml-auto block my-3 bg-red-600 text-white w-6  h-6 rounded-full"
            >
              X
            </button>
            <Table
              cols={4}
              data={staffPoint}
              headerContent={header}
              loadingState={isLoading}
              notPaginated={true}
            >
              {staffPoint.map(
                (
                  {
                    acknowledgment,
                    created_at,
                    date,
                    id,
                    point,
                    reason_for_point,
                  },
                  index
                ) => {
                  return (
                    <div
                      key={id}
                      className="grid grid-cols-4 my-4 text-sm gap-2"
                    >
                      <h2>{index + 1}</h2>
                      <DateFormatter time={created_at} />
                      <h2>{point}</h2>
                      <h2 className="whitespace-nowrap">{acknowledgment}</h2>
                    </div>
                  );
                }
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default PointHistory;
