import React, { useState } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import DHLModal from "./DHLModal";
import DHLTable from "./DHLTable";
import { useStateContext } from "pages/context/StateContext";

const DHL = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zones, setZones] = useState([]);
  const { shipmentPrices } = useStateContext();
  console.log(shipmentPrices);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddZone = (newZone) => {
    setZones([...zones, newZone]);
    setIsModalOpen(false); // Close modal after adding zone
  };

  return (
    <div>
      <div className="mx-auto mt-0 p-4 bg-white">
        <div className="p-2 flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-2 items-center md:mb-2 pt-3 mb-5">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">DHL/UPS Price</p>
          </div>
          <button
            className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
            onClick={() => setIsModalOpen(true)}
          >
            Add Price
          </button>
        </div>
      </div>
      {shipmentPrices.length > 0 ? (
        <DHLTable />
      ) : (
        <p className="text-gray-500 text-center my-20">
          No data has been added yet
        </p>
      )}
      {isModalOpen && (
        <DHLModal
          isOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          handleAddZone={handleAddZone}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default DHL;
