import React from "react";

const SingleViewBatchDetails = ({ item, index }) => {
  const {
    ReceiverName,
    TrackingNumber,
    ReceiverAddress,
    ReceiverEmail: email,
    ReceiverPhone,
    Country,
    Price,
    GoodsDeclaredValue,
    SpecialDeliveryNote,
    InsuranceTotalKg,
    PayModePaymentStatus,
    // PaymentStatus,
  } = item || {};
  return (
    <div className="grid text-sm grid-cols-12 gap-2">
      <h2>{index + 1}</h2>
      <h2>{ReceiverName}</h2>
      <h2>{TrackingNumber}</h2>
      <h2>{ReceiverAddress}</h2>
      <h2>{email.length > 20 ? `${email.slice(0, 20)}...` : email}</h2>
      <h2>{ReceiverPhone}</h2>
      <h2>{Country}</h2>
      <h2>{Price}</h2>
      <h2>{GoodsDeclaredValue}</h2>
      <h2>{SpecialDeliveryNote}</h2>
      <h2>{InsuranceTotalKg}</h2>
      <h2>{PayModePaymentStatus}</h2>
      {/* <h2>{PaymentStatus}</h2> */}
      {/* <h2>{TotalKg}</h2> */}

      <div className="flex gap-2"></div>
    </div>
  );
};

export default SingleViewBatchDetails;
