import React from 'react'
import { Link } from "react-router-dom";
import { FaHome, FaChevronRight } from "react-icons/fa";
import PendingLeaveTable from './PendingLeaveTable';
import { useStateContext } from 'pages/context/StateContext';

const PendingLeave = () => {
  const {getPendingLeaveRequest} = useStateContext()
  
  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="p-2 flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center md:mb-0 pt-7 mb-5 ">
          <FaHome className="m-0" />
          <p className="text-black">Home</p>
          <FaChevronRight className="m-1 text-[#049805]" />
          <p className="text-[#049805]"> Leave/Off Request</p>
        </div>
        <Link
          to="/home/leave"
          className="bg-[#3771C8] text-white rounded-md p-2"
        >
          Leave Requests({getPendingLeaveRequest.length})
        </Link>
      </div>
      <div className="flex items-center justify-center bg-gray-300 rounded-full p-4">
        <h1 className="text-4xl font-semibold text-white bg-green-600 rounded-full p-4">
          Leave/Off Requests
        </h1>
      </div>
      <h1 className="text-4xl font-semibold text-black p-4">
        Leave Requests <span className="text-green-600">({getPendingLeaveRequest.length})</span>
      </h1>
      <PendingLeaveTable/>
    </div>
  );

 };

export default PendingLeave;





















 