import React from "react";
import { Spinner } from "react-activity";
import TanHeader from "./header";
import TanRows from "./rows";

const TanBody = ({ table, loadingState }) => {
  return (
    <div className="overflow-x-scroll">
      <table className="w-full">
        <TanHeader table={table} />
        <TanRows table={table} />
      </table>
    </div>
  );
};

export default TanBody;
