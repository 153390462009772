import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "pages/context/StateContext";
import { AiOutlineSearch } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import SeaActionModal from "./SeaActionModal";
import SeaUpdateModal from "./SeaUpdateModal";
import UpdateTrackingModal from "./UpdateTrackingModal";
import SeaEditShipmentOrder from "./SeaEditShipmentOrder";
import { Spinner } from "react-activity";

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { ToastContainer } from "react-toastify";

const SeaCargoTable = () => {
  const { allSeaCargos, getSeaItems, isLoading } = useStateContext();
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [showModal, setShowModal] = useState();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [trackingDetails, setTrackingDetails] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("");

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Receiver Name",
      accessorKey: "customer_name",
    },
    {
      header: "Tracking Number",
      accessorKey: "tracking_number",
    },
    {
      header: "Batch Number",
      accessorKey: "batch_number",
    },

    {
      header: "Date Processed",
      accessorKey: "date_processed",
    },
    {
      header: "Payment Status",
      accessorKey: "payment_status",
    },
    {
      header: "Items",
      accessorKey: "items",
      cell: (item) => (
        <button
          onClick={() => handleViewSingleItem(item.row.original.id)}
          className="view-btn bg-blue-500 text-white rounded px-2 py-1"
        >
          view
        </button>
      ),
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Processed By",
      accessorKey: "processed_by",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (item) => (
        <button
          onClick={() => {
            const currentItem = item.row.original;
            setSelectedRow(currentItem);
            setTrackingDetails({ ...trackingDetails, ...currentItem });
            handleShowModal();
          }}
          className="text-black"
        >
          <BsThreeDots />
        </button>
      ),
      filterFn: () => {
        //   if (filterStatuses.length === 0) return true;
        //   const date = row.getValue(columnId);
        //   return filterStatuses.includes(date?.id);
      },
    },
  ];
  const data = useMemo(() => allSeaCargos, [allSeaCargos]);
  const columns = useMemo(() => columnData, [columnData]);

  const pageSize = 10;
  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnSizing: {},
    columnSizingInfo: {
      startOffset: null,
      startSize: null,
      deltaOffset: null,
      deltaPercentage: null,
      isResizingColumn: false,
      columnSizingStart: [],
    },
  };
  const table = useReactTable(options);

  const handleFilter = (value) => {
    setFilters(value);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const navigate = useNavigate();

  const handleViewSingleItem = async (id) => {
    await getSeaItems(id);
    navigate("/home/sea/viewitem");
  };
  const handleTrackingDetails = (e) => {
    setTrackingDetails({
      ...trackingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            // navigate.push(
            //   {
            //     pathname: router.pathname,
            //     query: { page: i + 1 },
            //   },
            //   undefined,
            //   { shallow: true }
            // );
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);
  //   useEffect(() => {
  //     // Read the "page" query parameter from the URL
  //     // const pageFromUrl = router.query.page;

  //     // Set the initial page for the table's pagination
  //     if (typeof pageFromUrl === "string" && !isNaN(Number(pageFromUrl))) {
  //       const initialPage = parseInt(pageFromUrl, 10) - 1;
  //       table.setPageIndex(initialPage);
  //       setPageIndex(initialPage);
  //     }
  //   }, [table]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-2 text-black p-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h1>Home</h1>
          <svg
            className="pt-2"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
              stroke="black"
              fill="#1F1E1E"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h1 className="text-[#049805]">Aircargo Placement</h1>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center p-4">
          <div className="flex-1 relative mt-4 md:mt-0 w-full md:w-1/2 lg:w-1/3">
            <input
              type="text"
              placeholder="Search..."
              className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-full focus:outline-none focus:ring focus:border-blue-300"
              value={filters}
              onChange={(e) => handleFilter(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <AiOutlineSearch />
            </div>
          </div>
        </div>
      </div>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="p-3 text-left"
                  key={header.id}
                  w={header.getSize()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr className="" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <>
                    <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                      <div className="truncate-text">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {/* PAGINATION*/}
      <div className="flex items-center justify-between">
        <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {paginationButtons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>

      <SeaActionModal
        showModal={showModal}
        setShowModal={setShowModal}
        setShowUpdateModal={setShowUpdateModal}
        setOpenUpdateModal={setOpenUpdateModal}
        currentEditItem={selectedRow}
        // onDelete={handleDelete}
      />

      <UpdateTrackingModal
        isOpen={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        data={selectedRow}
        trackingDetails={trackingDetails}
        handleTrackingDetails={handleTrackingDetails}
      >
        <SeaEditShipmentOrder dataToEdit={selectedRow} />
      </UpdateTrackingModal>

      <SeaUpdateModal
        isOpen={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        data={selectedRow}
        paymentStatus={paymentStatus}
        setPaymentStatus={setPaymentStatus}
      />
      <ToastContainer />
    </>
  );
};

export default SeaCargoTable;
