import { useStateContext } from "pages/context/StateContext";
import Table from "../../../Utils/Table";
import Pagination from "../shipmentBatch/Pagination";
import SinglePendingPayment from "./SinglePendingPayment";
import { useEffect, useState } from "react";

const tableHeader = [
  "S/N",
  "Customer Name",
  "ShipmentType",
  "Batch",
  "AmountDue",
];

const PendingPayment = () => {
  const { pendingPayment, isLoading } = useStateContext();
  const { data, air_cargo, sea_cargo } = pendingPayment;
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    const filteredData = data?.filter((item) =>
      item.customer_name?.includes(searchTerm)
    );
    setDisplayedData(filteredData);
  }, [searchTerm]);

  return (
    <div className="grid justify-center">
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Pending Payment Order</h1>
      </div>

      <div className="p-3 md:p-6 max-w-full overflow-x-auto">
        <h1 className="m-3 text-center md:text-left font-bold">
          Pending Payment Order
        </h1>
        <div className="text-center ">
          <h1 className="font-bold text-2xl my-6 mx-3">
            List of Orders Awaiting Payment
          </h1>
          <div className="flex justify-center items-center gap-3 my-6">
            <h1 className="font-bold text-1xl">Total Outstandings: </h1>
            <div className="text-[#049805]">
              <p>Air Cargo: ₦{air_cargo}</p>
              <p>sea Cargo: ₦{sea_cargo}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          {/* show 10 of entries */}
          <div>
            <Pagination />
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Search */}
            <div className="flex flex-col md:flex-row justify-between items-center m-2">
              <div className="relative mt-4 md:mt-0 w-full md:w-auto ">
                <input
                  type="text"
                  placeholder="Search..."
                  className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 w-full focus:outline-none focus:ring focus:border-blue-300"
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Table */}

        <Table
          headerContent={tableHeader}
          minSize={"1000px"}
          loadingState={isLoading}
          cols={5}
          data={displayedData}
        >
          {displayedData?.map((item, index) => (
            <div key={index}>
              <SinglePendingPayment
                // openModal={handleOpenModal}
                item={item}
                index={index}
              />
              <hr className="my-4 border-green-50" />
            </div>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default PendingPayment;
