import React from 'react'
import Vendor from "./components/Vendor/Vendor"

const VendorNewPage = () => {
  return (
    <div>
      <Vendor/>
    </div>
  )
}

export default VendorNewPage
