import React from "react";
import Table from "../../../Utils/Table";
import SingleSuccessful from "./SingleSuccessful";
import { Link } from "react-router-dom";

const tableHeader = ["S/N", "Name", "Amount Withdrawn", "Bank", "Status"];

const SuccessfulTable = ({ data, total }) => {
  return (
    <div>
      <div className="pl-6 flex justify-between">
        <h1 className=" font-bold">Successful Withdrawals({total})</h1>
        <Link to="successfulwithdrawals" className="text-[#049805] pr-6">
          View all
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <div className="m-6 bg-white shadow-x rounded-lg overflow-hidden w-full max-w-6xl">
          <Table
            headerContent={tableHeader}
            minSize={"1000px"}
            // loadingState={isLoading}
            cols={6}
            data={data}
            notPaginated={true}
          >
            {data?.map((item, index) => (
              <div key={index}>
                <SingleSuccessful item={item} index={index} />
                <hr className="my-4 border-green-50" />
              </div>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulTable;
