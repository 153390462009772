import React from "react";
import { useStateContext } from "pages/context/StateContext";
const StaffProfile = ({ isOpen, onClose, staffProfile }) => {
  const { imageUrl } = useStateContext();
  if (!isOpen) return null;
  const imagePath = staffProfile.data.staff_profile?.profile_picture;
  // console.log(staffProfile)

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-10 flex justify-center items-center">
      <div className="bg-white p-6 rounded w-1/2 max-h-full overflow-y-auto">
        <button onClick={onClose} className="float-right">
          X
        </button>
        <form>
          <div className="p-6">
            <h1 className="font-bold text-center">View Staff Profile</h1>
            <img src={`${imageUrl}${imagePath}`} alt="Profile" />
          </div>
          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Full Name
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.full_name}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Address
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.address}
              </p>
            </label>
          </div>

          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Date of Birth
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.dob}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Role
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.role}
              </p>
            </label>
          </div>

          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Phone Number
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.phone_number}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Sex
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.sex}
              </p>
            </label>
          </div>
          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Marital Status
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.marital_status}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Email
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.email}
              </p>
            </label>
          </div>
          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Age
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.marital_status}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Points Earned
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.point}
              </p>
            </label>
          </div>
          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Date of Employment
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.created_at}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Download CV
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                <a
                  href={`${imageUrl}${staffProfile.data.staff_profile.cv}`}
                  download
                  className="text-white-600 bg-green-500 p-4"
                >
                  Download CV
                </a>
              </p>
            </label>
          </div>

          {/* GUARANTOR SECTION */}
          <h2 className="text-2xl font-bold mb-7 mt-5 text-center">
            Guarantor Details
          </h2>
          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Guarantor Full Name
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.guarantor_full_name}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Guarantor Phone Number
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.guarantor_phone}
              </p>
            </label>
          </div>

          <div className="md:flex md:justify-between">
            <label className="flex-1 block mb-2 md:mr-4 text-sm font-medium">
              Guarantor Address
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                {staffProfile.data.staff_profile.guarantor_address}
              </p>
            </label>
            <label className="flex-1 block mb-2 text-sm font-medium">
              Guarantor Photo
              <p className="w-full p-4 bg-gray-100 rounded-md focus:outline-none">
                <a
                  href={`${imageUrl}${staffProfile.data.staff_profile.guarantor_photo}`}
                  download
                  className="text-white-600 bg-green-500 p-4"
                >
                  Download Guarantor Photo
                </a>
              </p>
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StaffProfile;
