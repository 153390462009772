import React from 'react'
import SeaViewItem from './components/SeaCargoPlacement/SeaViewItem'

const SeaViewItemPage = () => {
  return (
    <div>
      <SeaViewItem/>
    </div>
  )
}

export default SeaViewItemPage
