import React from 'react'
import ScannerApp from './components/Scanner/ScannerApp'

const Scanner = () => {
  return (
    <div>
      <ScannerApp/>
    </div>
  )
}

export default Scanner
