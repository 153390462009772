import React from 'react'
import Inventory from './components/Inventory/Inventory';

const InventoryPage = () => {
  return (
    <div>
      <Inventory/>
    </div>
  )
}

export default InventoryPage