import React, { useState } from "react";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";

const UpdateTrackingModal = ({
  isOpen,
  onClose,
  data,
  trackingDetails,
  handleTrackingDetails,
}) => {
  const { updateTracking, paymentLoading } = useStateContext();
  // console.log(trackingDetails);
  const handleSubmit = async (e) => {
    const data = {
      desc: trackingDetails?.tracking_description,
      status: trackingDetails?.status,
      id: trackingDetails?.id,
    };
    e.preventDefault();
    await updateTracking({
      ...data,
      type: "air",
    });
    console.log("Shipment Status:");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded w-1/2 h-2/2 overflow-y-auto">
        <button onClick={onClose} className="float-right">
          X
        </button>
        <form onSubmit={handleSubmit} className="m-3">
          <img src={MyAfrimall} alt="logo" className="w-[15rem] m-6" />
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Shipment Status
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="status"
              value={trackingDetails?.status || ""}
              onChange={handleTrackingDetails}
              required
            >
              <option value="Picked Up">Picked Up</option>
              <option value="Processed">Processed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Enroute">Enroute</option>
              <option value="Awaiting Customs Clearance">
                Awaiting Customs Clearance
              </option>
              <option value="Cleared Customs">Cleared Customs</option>
              <option value="Out for Delivery">Out for Delivery</option>
              <option value="Delivered">Delivered</option>
              <option value="Returned">Returned</option>
              <option value="Shipment Completed">Shipment Completed</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Update Tracking Description
            </label>
            <textarea
              required
              name="tracking_description"
              value={trackingDetails?.tracking_description || ""}
              onChange={handleTrackingDetails}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter tracking description"
              rows="4"
            />
          </div>
          <button
            type="submit"
            className="bg-[#049805] w-full text-white font-bold py-2 px-4 rounded focus:outline-none flex items-center justify-center focus:shadow-outline"
          >
            {paymentLoading ? <Spinner /> : "Update"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateTrackingModal;
