import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "pages/context/StateContext";

const EditInventoryModal = ({ isOpen, handleCloseModal, item }) => {
  const { config, baseUrl, getInventory } = useStateContext();
  const [quantity, setQuantity] = useState(item?.quantity);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Reset quantity when modal is opened for a new item
    if (isOpen) {
      setQuantity(item?.quantity);
    }
  }, [isOpen, item]);

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const url = `${baseUrl}/update-inventory/${item.id}`;
      const response = await axios.put(url, { quantity }, config());
      toast.success(response.data.message);
      getInventory(); // Update the table data
      handleCloseModal();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-10 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-1/3 max-h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Update Inventory
            </h2>
            <form>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Item Name:
                </label>
                <input
                  type="text"
                  value={item?.name}
                  disabled
                  className="w-full p-2 border rounded mt-1"
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Quantity:
                </label>
                <input
                  type="text"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className="w-full p-2 border rounded mt-1"
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Unit:
                </label>
                <input
                  type="text"
                  value={item?.unit}
                  disabled
                  className="w-full p-2 border rounded mt-1"
                />
              </div>
              <div className="flex justify-center w-full">
                <button
                  type="button"
                  onClick={handleUpdate}
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default EditInventoryModal;
