import React from 'react'
import ViewAllAffiliateReq from './components/Affiliate/ViewAllAffiliateReq'

const ViewAllAffilaiteReqPage = () => {
  return (
    <div>
      <ViewAllAffiliateReq/>
    </div>
  )
}

export default ViewAllAffilaiteReqPage
