function Modal({ isOpen, onClose, title, children }) {
  if (!isOpen) {
      return null;
  }

  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
          {/* This is the semi-transparent backdrop */}
          <div className="absolute inset-0 bg-black opacity-60"></div>

          {/* This is the modal content */}
          <div className="relative bg-white p-6 w-11/12 md:w-1/2 lg:w-1/3 rounded-xl shadow-lg z-10">
              <div className="flex justify-between items-start">
                  <h2 className="text-xl font-bold">{title}</h2>
                  <button onClick={onClose} className="text-lg">
                      &times;
                  </button>
              </div>
              <div className="mt-4">
                  {children}
              </div>
          </div>
      </div>
  );
}

export default Modal;
