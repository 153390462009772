import React from 'react'
import Affiliate from './components/Affiliate/Affiliate'

const AffiliatePage = () => {
  return (
    <div>
      <Affiliate/>
    </div>
  )
}

export default AffiliatePage
