import React from 'react'
import ViewBatchDetails from './components/shipmentBatchDetails/ViewBatchDetails'
const ViewBatchDetailsPage= () => {

  return (
    <div>
     <ViewBatchDetails/>
    </div>
  )
}

export default ViewBatchDetailsPage
