import React, { useState } from "react";
import { Spinner } from "react-activity"; 

const ResponseModal = ({ queryId, isOpen, onClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [managementResponse, setManagementResponse] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true); 
    await onSubmit(queryId, managementResponse); 
    setManagementResponse(""); 
    setIsLoading(false); 
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full md:w-1/2 lg:w-1/3 p-4 md:p-6">
        <h3 className="text-lg font-semibold mb-4">Add Management Response</h3>
        <textarea
          value={managementResponse}
          onChange={(e) => setManagementResponse(e.target.value)}
          placeholder="Add management response (short and concise)"
          className="w-full h-32 p-2 border rounded-md mb-4"
        />
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded-md mr-2"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Submit"}
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseModal;
