import React from 'react'
import CreateShipmentContd from './components/createShipment/CreateShipmentContd'
const CreateShipmentcontsPage = () => {
  return (
    <div>
      <CreateShipmentContd/>
    </div>
  )
}

export default CreateShipmentcontsPage
