import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { FaArrowLeftLong } from "react-icons/fa6";

import AdminOfficer from "../AdminOfficer/AdminOfficer"


const AdminEditForm = () => {
    const [AdminOfficer, setAdminOfficer] = useState(false);
  const [formData, setFormData] = useState({
    AffiliateName: "Moses Abimombola",
    CustomerName: "vera",
    CustomerAddress: "No 20 Toji,street,Lagos",
    CustomerPhone: "080123456789",
  });

  //   const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
    };
    
    // const handleGoBack = () => {
    //     setShowAdminEditForm(false);
    //   };


//   const handleFileChange = (e) => {
//     setFormData({
//       ...formData,
//       uploadedFile: e.target.files[0],
//     });
//   };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    // navigate("createShipmentContd");
  };

  return (
      <div className="">
         
      <div className="flex gap-2 text-black p-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Account Officer</h1>
      </div>
      <form
        className=" shadow- rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        <div className="flex mb-4"></div>

        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Affiliate Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="ReceiverName"
              name="ReceiverName"
              type="text"
              required
              onChange={handleChange}
              value={formData.AffiliateName}
            />
          </div>

          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Customer Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="CustomerName"
              name="CustomerName"
              type="text"
              onChange={handleChange}
              value={formData.CustomerName}
            />
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Customer Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="CustomerAddress"
              name="CustomerAddress"
              type="text"
              onChange={handleChange}
              value={formData.CustomerAddress}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Customer Phone
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="CustomerPhone"
              name="CustomerPhone"
              type="tel"
              onChange={handleChange}
              value={formData.CustomerPhone}
            />
          </div>
        </div>

        <div className="py-3">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Status
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="batch"
            name="batch"
            onChange={handleChange}
            value={formData.batch}
          >
            {/* Add your options here */}
            <option value="batch1">completed</option>
            <option value="batch2">Not completed</option>
          </select>
        </div>

        <div className="flex mb-4 justify-center py-6">
          <div className="w-2/3 text-center">
            <button
              type="btn"
              className={`bg-[#3771C8]  cursor-pointer text-xs text-white font-semibold py-2 px-4 md:w-1/4 md:self-center rounded-lg focus:outline-none focus:shadow-outline`}
            >
              Accept Request
            </button>
          </div>
          <div className="w-2/3 text-center">
            <button
              type="btn"
              className={`bg-[#FF0036]  cursor-pointer  text-xs text-white font-semibold py-2 px-4 md:w-1/4 md:self-center rounded-lg focus:outline-none focus:shadow-outline`}
            >
              Reject Request
            </button>
          </div>
        </div>

        <div className="flex justify-center items-center py-6">
          <button
            type="submit"
            className={`bg-[#049805]  w-full cursor-pointer text-white font-bold py-2 px-4 md:w-1/ md:self-center rounded-lg focus:outline-none focus:shadow-outline`}
          >
          Create a Shipment 
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminEditForm;
