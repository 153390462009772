import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaHome, FaChevronRight } from "react-icons/fa";
import TanTable from "Utils/TanTable";
import { useStateContext } from "pages/context/StateContext";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const ImportsTable = () => {
  const { allimports, getSingleImport, isLoading } = useStateContext(); // Renamed to match the actual function
  const navigate = useNavigate();

  // Define the handleSingleImport function inside the component to access navigate
  const handleSingleImport = async (id) => {
    await getSingleImport(id); // Call the function to get import details
    navigate("/mobile/importsdetails"); // Navigate to the details page
  };

  const columns = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: (item) => (
        <span>
          {item.row.original.created_at
            ? format(new Date(item.row.original.created_at), "yyyy-MM-dd")
            : "N/A"}
        </span>
      ),
    },
    {
      header: "Name",
      accessorKey: "sender_name",
    },
    {
      header: "Location",
      accessorKey: "receiver_country",
    },
    {
      header: "Import Origin",
      accessorKey: "sender_country",
    },
    {
      header: "Status",
      accessorKey: "delivery_status",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (item) => (
        <button
          onClick={() => handleSingleImport(item.row.original.id)}
          className="view-btn bg-green-500 text-white rounded px-2 py-1"
        >
          View
        </button>
      ),
    },
  ];

  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="p-2 flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center md:mb-2 pt-5 mb-5">
          <FaHome className="m-0" />
          <p className="text-black">Home</p>
          <FaChevronRight className="m-1 text-[#049805]" />
          <p className="text-[#049805]">Imports</p>
        </div>
      </div>

      <TanTable
        columnData={columns}
        data={allimports}
        loadingState={isLoading}
      />
    </div>
  );
};

export default ImportsTable;
