import { useStateContext } from "pages/context/StateContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateShipmentTable = () => {
  const [note, setNote] = useState("");
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const { shipmentDetails, setShipmentDetails, handleShipmentDetails } =
    useStateContext();

  const orders = shipmentDetails?.details;
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("Confirmation");
    // You can add further processing here
  };

  const deleteItem = (id) => {
    const newItems = orders.filter((item, index) => index !== id);
    setShipmentDetails({ ...shipmentDetails, details: newItems });
  };

  const sumItemKg = (items) => {
    const totalKg = items.reduce((sum, currentItem) => {
      const itemKg = parseFloat(currentItem.item_kg);
      if (!isNaN(itemKg)) {
        return sum + itemKg;
      } else {
        console.log(`Invalid item_kg value: ${currentItem.item_kg}`);
        return sum;
      }
    }, 0);

    return totalKg;
  };

  useEffect(() => {
    console.log(orders?.length);
    if (orders && orders?.length > 0) {
      const total = sumItemKg(orders);
      setShipmentDetails({ ...shipmentDetails, total_kg: total?.toString() });
    }
  }, [orders]);

  return (
    <div className="px-6 my-10">
      <table className="min-w-full bg-white border border-gray-200  ">
        <thead>
          <tr>
            <th className="border-b py-2 px-4 text-left">S/N</th>
            <th className="border-b py-2 px-4 text-left">Items</th>
            <th className="border-b py-2 px-4 text-left">KG</th>
            <th className="border-b py-2 px-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {orders?.length < 1 ? (
            <h2 className="p-3 my-10">No item added</h2>
          ) : (
            orders?.map(({ item_kg, item_name }, index) => (
              <tr key={index}>
                <td className="border-b py-2 px-4">{index + 1}</td>
                <td className="border-b py-2 px-4">{item_name}</td>
                <td className="border-b py-2 px-4">{item_kg}</td>
                <td className="border-b py-2 px-4">
                  <button
                    onClick={() => deleteItem(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div>
        <form onSubmit={handleSubmit}>
          <label className="block mt-4">Total kg</label>
          <input
            type="text"
            readOnly
            className="border rounded px-2 py-1 w-full"
            value={shipmentDetails?.total_kg}
          />
          <div className="my-4">
            <label className="block mt-4 mb-1">Special Delivery note</label>
            <textarea
              type="text"
              placeholder="Special Delivery Note"
              className="border rounded px-2 py-1 w-full"
              name="special_delivery_note"
              value={shipmentDetails?.special_delivery_note || ""}
              onChange={handleShipmentDetails}
            />
            <label className="block mt-4">Price</label>
            <input
              type="text"
              placeholder="Price"
              className="border rounded px-2 py-1 w-full"
              name="price"
              required
              value={shipmentDetails?.price || ""}
              onChange={handleShipmentDetails}
            />
          </div>
          <div className="text-center pb-6">
            <button
              type="submit"
              className="bg-[#049805] text-white px-4 py-2 rounded inline-block w-2/4"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateShipmentTable;
