import React from 'react'
import ViewItem from './components/AirCargoPlacement/ViewItem'

const ViewItemPage = () => {
  return (
    <div>
    <ViewItem/>
    </div>
  )
}

export default ViewItemPage
