import React, { useState} from "react";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "pages/context/StateContext";

const AddPoints = ({ isOpen, onClose, staffId }) => {
  const { config, baseUrl, getAllStaff } = useStateContext();

  const [date, setDate] = useState("");
  const [points, setPoints] = useState("");
  const [details, setDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = `${baseUrl}/admin/add-staff-point`;
      const options = {
        staff_id: staffId,
        date: date,
        point: points,
        reason_for_point: details,
      };
      const response = await axios.post(url, options, config());
      toast.success(response.data.message);
      getAllStaff();
      onClose();
      setDate("");
      setPoints("");
      setDetails("");
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-1/2 h-2/2 overflow-y-auto">
            <button onClick={onClose} className="float-right">
              X
            </button>
            <form onSubmit={handleSubmit} className="m-3">
              <div className="p-6">
                <h1 className="font-bold text-center">Add Points</h1>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="date"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Enter Date:
                </label>
                <input
                  type="date"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="border rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-none"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="points"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Enter Number of Points:
                </label>
                <select
                  id="points"
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                  required
                  className="border rounded w-full py-2 px-3 text-gray-700 bg-gray-200 leading-tight focus:outline-none focus:shadow-none"
                >
                  <option value="">Select Points</option>
                  <option value="0.5">0.5</option>
                  <option value="1">1</option>
                  <option value="1.5">1.5</option>
                  <option value="2">2</option>
                  <option value="2.5">2.5</option>
                  <option value="3">3</option>
                </select>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="details"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Give Details:
                </label>
                <textarea
                  id="details"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  required
                  className="border rounded w-full py-2 px-3 text-black-300 bg-gray-200 leading-tight focus:outline-none focus:shadow-none"
                />
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="bg-[#049805] text-white py-2 rounded-md focus:outline-none w-full flex items-center justify-center"
              >
                {isLoading ? <Spinner /> : "Add Points"}
              </button>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default AddPoints;
