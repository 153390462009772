import React from "react";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import success from "../../Assest/images/success.png";
import { FaTimes } from "react-icons/fa";

const EditsuccessMsg = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 w-full">
      <div className="bg-white rounded-lg p-6">
        <div className="flex justify-between">
          <img src={MyAfrimall} alt="logo" className="w-[10rem]" />
          <button
            onClick={onClose}
            className=" text-black font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {" "}
            <FaTimes />{" "}
          </button>
        </div>
        <div className="text-center">
          <img src={success} alt="success message" className="w-[18rem]" />
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default EditsuccessMsg;
