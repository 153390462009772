import React from "react";
import TotalAffiliateTable from "./TotalAffiliateTable";
import AffiliateBankAcct from "./AffiliateBankAcct";
import UnSuccessfulTable from "./UnsuccessfulTable";
import SuccessfulTable from "./SuccessfulTable";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";
import RequestTable from "./AffiliateRequestTable";

const Affiliate = () => {
  const { affiliateDetails, isLoading } = useStateContext();
  const {
    all_affiliate_request,
    all_affiliates,
    all_pending_withdrawals,
    all_successful_wothdrawls,
    bank_accounts,
    total_affiliate_request,
    total_affiliates,
    total_bank_account,
    total_pending_withdrawals,
    total_successful_withdrawals,
  } = affiliateDetails || {};
  return (
    <div>
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Affiliate Dashboard</h1>
      </div>
      <div>
        {/* <h1 className="mx-6 font-bold">Affiliates Report</h1> */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center my-12 mx-3">
          {/* 1st affiliate card */}
          <div className="bg-[#343434] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of Affiliates
            </h2>
            <p className=" font-bold ">{total_affiliates || 0}</p>
          </div>

          {/* 2nd affiliate card */}
          <div className="bg-[#3771C8] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of Bank Accounts
            </h2>
            <p className=" font-bold">{total_bank_account || 0}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-center my-12 mx-3">
          {/* 3rd affiliate card */}
          <div className="bg-[#049805] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of <br />
              Pending Withdrawals
            </h2>
            <p className=" font-bold ">{total_pending_withdrawals || 0}</p>
          </div>

          {/* 4th affiliate card */}
          <div className="bg-[#FF0036] text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
            <h2 className="text-lg md:text-xl font-bold">
              Total Number of <br />
              Successful Withdrawals
            </h2>
            <p className=" font-bold">{total_successful_withdrawals || 0}</p>
          </div>
          <div className="mx-4 md:mx-[15rem]">
  <div className="bg-gray-400 text-white p-4 rounded-lg shadow-md w-full md:w-[33rem] h-40 md:h-48 flex flex-col justify-center items-center">
    <h2 className="text-lg md:text-xl font-bold">
      Total <br />
      Affiliate Request
    </h2>
    <p className="font-bold">{total_affiliate_request || 0}</p>
  </div>
</div>

        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <TotalAffiliateTable total={total_affiliates} data={all_affiliates} />
          <AffiliateBankAcct total={total_bank_account} data={bank_accounts} />
          <UnSuccessfulTable
            total={total_pending_withdrawals}
            data={all_pending_withdrawals}
          />
          <SuccessfulTable
            total={total_successful_withdrawals}
            data={all_successful_wothdrawls}
          />
          <RequestTable
            total={total_affiliate_request}
            data={all_affiliate_request}
          />
        </div>
      )}
    </div>
  );
};

export default Affiliate;
