import React, { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
// import { IoImageOutline } from "react-icons/io5";
import { useStateContext } from "pages/context/StateContext";
import { format } from "date-fns";

const BudgetDeliveryDetails = () => {
  const { singleUps, isLoading, getSingleUps, imageUrl } =
    useStateContext();
  const { id } = useParams(); // Get the ID of the express shipment from the URL params

  useEffect(() => {
    // Make sure id is defined before calling getSingleUps
    if (id) {
      getSingleUps(id); // Fetch the details of the express shipment when the component mounts
    }
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>; // Render a loading indicator while data is being fetched
  }

  // Format the date of shipment departure
  const formattedDepartureDate = singleUps?.created_at ? format(new Date(singleUps.created_at), "MMMM dd, yyyy") : "";


  return (
    <div className="md:mt-[-2rem]">
      <div className="flex md:justify-around md:mr-[27rem] ml-8 md:my-8 space-x-8 md:space-x-0 font-bold md:font-normal mb-5 md:mb-0 ">
        <Link to="/home/mobile/budgetdelivery">
          <span className="md:ml-[14rem] ml-5  font-bold text-2xl md:text-4xl cursor-pointer hover:text-gray-400 transition ease-in-out duration-300 ">
            <BsArrowLeft />
          </span>
        </Link>
        <p className="flex text-xl md:text-3xl mt-7 md:mt-8 md:pr-3">
          UPS Shipping Details
        </p>
      </div>

      {/* Render the details of the express shipment */}
      <span>
        <button className="w-44 h-9 bg-red-900 hover:bg-red-700 active:bg-red-600 transition ease-in-out duration-300 mt-4 ml-[2rem] md:ms-[68rem] items-end rounded text-sm text-white font-semibold ">
          Change Delivery status
        </button>
      </span>

      <div className="border-b-2 ">
        <div className="ml-[2rem] md:ml-[7rem] mt-5 md:flex md:space-x-[14rem] mb-5 space-y-6 md:space-y-0">
          {/* Render sender information */}
          <div>
            <h1 className="font-bold text-xl mb-2">Sender Information</h1>
            <div className="space-y-3">
              <p>Name: {singleUps?.sender_name}</p>
              <p>PickUp Country: {singleUps?.sender_country}</p>
              <p>PickUp State: {singleUps?.sender_state}</p>
              <p>Address: {singleUps?.sender_address}</p>
              <p>Phone Number: {singleUps?.sender_phone}</p>
              <p>Date of shipment Departure: {formattedDepartureDate}</p>
            </div>
          </div>
          {/* Render receiver information */}
          <div>
            <h1 className="font-bold text-xl mb-2">Receiver Information</h1>
            <div className="space-y-3">
              <p>Name: {singleUps?.receiver_name}</p>
              <p>Country: {singleUps?.receiver_country}</p>
              <p>State: {singleUps?.receiver_state}</p>
              <p>Zip Code: {singleUps?.receiver_zip_code}</p>
              <p>Phone Number: {singleUps?.receiver_phone}</p>
              <p>Address: {singleUps?.receiver_address}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-[2rem] md:ml-[7rem] mt-5 md:flex md:space-x-[18.5rem] mb-5 space-y-6 md:space-y-0">
        {/* Render package information */}
        <div>
          <h1 className="font-bold text-xl mb-2">Package Information</h1>
          <div className="space-y-3">
          <p>What are you shipping:</p>
          {singleUps?.shipment_item && singleUps?.shipment_item.map((item, index) => (
              <p key={index}>{item.shipment_item}</p>
            ))}
            <p>Weight of goods: {singleUps?.total_shipment_kg}</p>
            <div className="">
              <p className="p-1">Picture of good:</p>
              {singleUps?.picture_of_package &&
                singleUps?.picture_of_package && (
                  <div className="">
                    <span className="text-gray-600">
                      {/* <IoImageOutline /> */}
                    </span>
                    {/* Move the <img> tag inside the <p> element */}
                    <p>
                      <img
                        src={`${imageUrl}/${singleUps?.picture_of_package}`}
                        alt="Package"
                        className="h-24"
                      />
                    </p>

                    {/* Add a download link */}
                    <a
                      href={`${imageUrl}/${singleUps?.picture_of_package}`}
                      download="picture_of_package.jpg"
                      className="fle space-x-1 items-center text-gray-600"
                    >
                      <FiDownload className="w-24 h-10" />
                    </a>
                      <span>Download</span>
                  </div>
                )}
            </div>
            <div>
              <p> Insurance: {singleUps?.insurance ? "Yes" : "No"}</p>
              <p>Value of Package: {singleUps?.value_of_package}</p>
            </div>
          </div>
        </div>
        {/* Render other information */}
        <div>
          <h1 className="font-bold text-xl mb-2 ">Other Information</h1>
          <div className="space-y-3">
            <p>Amount of Goods: {singleUps?.goods_amount}</p>
            <p>Insurance Amount: {singleUps?.insurance_amount}</p>
            <p>Total Amount to be paid: {singleUps?.price}</p>
            <p>
              Status of payment:{" "}
              <span className="text-[#049805]">
                {singleUps?.payment_status}
              </span>
            </p>
            <p>
              Status of Delivery:{" "}
              <button className="w-24 h-8 rounded bg-[#049805] text-white">
                {singleUps?.delivery_status}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetDeliveryDetails;
