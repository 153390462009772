import React, { useState, useEffect } from "react";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
// import { useNavigate } from "react-router-dom";
import EditsuccessMsg from "../SuccessMsg/EditSuccessMsg";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import { toast } from "react-toastify";
import { Spinner } from "react-activity";

const EditModal = ({ onClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState(initialData);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { config, token, baseUrl, getAllCustomers } = useStateContext();
  //   const navigate = useNavigate();

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // console.log(initialData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}/admin/update-customer/${initialData?.customer_id}`;
    try {
      const res = await axios.put(
        url,
        { ...formData, customer_name: formData?.customer_fname },
        config(token)
      );
      toast.success("Customer Edited successfully");
      getAllCustomers();
      onClose();
      // window.location.reload();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSuccessMsg = () => {
    setShowSuccessMsg(false);
    onClose();
  };

  if (!formData) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 ">
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-lg w-full max-w-md p-6 mx-2 md:mx-0"
      >
        <span
          className="absolute top-2 right-2 cursor-pointer"
          onClick={onClose}
        >
          &times;
        </span>
        <div>
          <img src={MyAfrimall} alt="logo" className="w-30 h-12" />
        </div>
        <div className="text-center font-bold">
          <p>Edit Customer Details</p>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">
            Full Name:
            <input
              type="text"
              name="customer_fname"
              value={formData?.customer_fname || ""}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mt-1"
            />
          </label>
          <label className="block text-sm font-medium mb-2">
            Phone Number:
            <input
              type="tel"
              name="customer_phone"
              value={formData?.customer_phone || ""}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mt-1"
            />
          </label>
          <label className="block text-sm font-medium mb-2">
            Email:
            <input
              type="email"
              name="customer_email"
              value={formData?.customer_email || ""}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mt-1"
            />
          </label>
          <label className="block text-sm font-medium mb-2">
            Address:
            <input
              type="text"
              name="customer_address"
              value={formData?.customer_address || ""}
              onChange={handleInputChange}
              className="w-full p-2 border rounded mt-1"
            />
          </label>
        </div>
        <div className="flex flex-col sm:flex-row justify-between">
          <button
            className="bg-red-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2 sm:mb-0"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#049805] text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex itemss-center justify-center"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Update Customer Details"}
          </button>
        </div>
      </form>

      {showSuccessMsg && (
        <EditsuccessMsg
          isOpen={showSuccessMsg}
          onClose={handleCloseSuccessMsg}
          message="You have Successfully updated Customer Details!"
        />
      )}
    </div>
  );
};

export default EditModal;
