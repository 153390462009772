import { useStateContext } from "pages/context/StateContext";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const Confirmation = () => {
  const [paymentMode, setPaymentMode] = useState("");
  const [textValue, setTextValue] = useState("");
  const [isVat, setIsVat] = useState("");
  const {
    shipmentDetails,
    setShipmentDetails,
    handleShipmentDetails,
    confirmShipment,
    isLoading,
  } = useStateContext();

  useEffect(() => {
    const price = shipmentDetails?.price;
    if (isVat === "Yes") {
      const withVat = 0.075 * +price;
      setShipmentDetails({
        ...shipmentDetails,
        total_price: (+price + withVat)?.toString(),
      });
    } else {
      setShipmentDetails({
        ...shipmentDetails,
        total_price: price?.toString(),
      });
    }
  }, [isVat]);

  const navigateTo = useNavigate();

  const sendRequest = async (event) => {
    event.preventDefault();
    const req = await confirmShipment();
    if (req === "success") {
      setTimeout(() => {
        navigateTo("/home/create");
      }, 2000);
      setShipmentDetails({});
    }
  };

  return (
    <div className="p-6 bg-white my-6 min-h-screen">
      <ToastContainer />
      <div className="p-3">
        <Link to="/home/create/createShipmentContd">Back</Link>
      </div>
      <h1 className="font-bold text-center p-6 ">Confirmation Page</h1>
      <div className="border-b-2 text-sm leading-loose">
        <p>Name of Customer: {shipmentDetails?.receiver_name} </p>
        <p>
          Shipment Type:{" "}
          {shipmentDetails?.shipment_type_id == 1 ? "Air" : "Sea"} Cargo
        </p>
        {/* <p>Shipment Batch ID: {shipmentDetails?.batch_id}</p> */}
      </div>
      <div className="border-b-2 font-lighter py-2">
        <h1>Sub-Total: ₦{shipmentDetails?.price} </h1>
      </div>
      <div className="my-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Add Vat
        </label>
        <div className="flex items-center">
          <label className="inline-flex items-center mr-4">
            <input
              type="radio"
              className="form-radio h-5 w-5 text-gray-600"
              name="insurance"
              value="Yes"
              checked={isVat === "Yes"}
              onChange={() => setIsVat("Yes")}
              required
            />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio h-5 w-5 text-gray-600"
              name="insurance"
              value="No"
              checked={isVat === "No"}
              onChange={() => setIsVat("No")}
              required
            />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="py-3 font-bold">
        <h1>Total-Price: ₦ {shipmentDetails?.total_price} </h1>
      </div>

      <div >
        <h1>Choose Payment Status</h1>
        <select
          name="payment_status"
          value={shipmentDetails?.payment_status || ""}
          onChange={handleShipmentDetails}
          className="bg-[#D9D9D980]"
        >
          <option value="">Select</option>
          <option value="Not Paid">Not Paid</option>
          <option value="Paid">Paid</option>
        </select>
      </div>
      <div className="py-3">
        <p>Mode of Payment</p>
        <select
          name="mode_of_payment"
          value={shipmentDetails?.mode_of_payment || ""}
          onChange={handleShipmentDetails}
          className="block my-4 bg-[#D9D9D980]"
        >
          <option value="">Select</option>
          <option value="pos">POS</option>
          <option value="bank_transfer">Bank Transfer</option>
        </select>

        <label>Box Packaging</label>
        <input
          type="text"
          placeholder="Enter number of Box Packages"
          className="border rounded px-2 py-1 w-full mb-4 bg-[#D9D9D980]"
          name="box_packaging"
          value={shipmentDetails?.box_packaging || ""}
          onChange={handleShipmentDetails}
        />
        <div className="text-center">
          <button
            type="submit"
            onClick={sendRequest}
            className="bg-[#049805] text-white px-4 py-2 rounded flex items-center justify-center w-2/6 mx-auto"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
