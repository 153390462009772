import React from 'react'
import Confirmation from './components/createShipment/Confirmation'
const ConfirmationPage = () => {
  return (
    <div>
     <Confirmation/> 
    </div>
  )
}

export default ConfirmationPage
