import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, LabelList } from "recharts";

const Chart = ({ rate }) => {
  // Assuming rate is between 0 and 100
  const data = [
    { name: "rate", value: rate },
    { name: "empty", value: 100 - rate },
  ];

  const COLORS = ["#049805", "#ced4da"]; // Green for rate, Grey for empty space

  // Calculate angle for the needle based on the rate
  const needleAngle = (180 * rate) / 100;

  // Calculate endpoint of the needle to align with the outer edge of the green segment
  const needleEndX = 50 + Math.cos((needleAngle - 90) * (Math.PI / 180)) * 20; // adjust length as needed
  const needleEndY = 50 + Math.sin((needleAngle - 90) * (Math.PI / 180)) * 20; // adjust length as needed

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          innerRadius="75%"
          outerRadius="100%"
          paddingAngle={1}
          fill="#8884d8"
        >
          <LabelList
            dataKey={({ name, value }) => (name === "rate" ? `${value}%` : "")}
            position="center"
            fill="#049805"
            className="text-3xl font-bold"
            // content={({ name, value }) => (name === "rate" ? `${value}%` : "")}
          />

          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>
        {/* Needle */}
        {/* <line
          x1="50%"
          y1="50%"
          x2={`${needleEndX}%`}
          y2={`${needleEndY}%`}
          stroke="#ff0000" // Red color
          strokeWidth="2"
        /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Chart;
