import React from 'react';
import AddNews from './components/News/AddNews';

const NewsPage = () => {
  return (
    <div>
      <AddNews/>
    </div>
  )
}

export default NewsPage
