import React, { useState, useRef } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import TanTable from "Utils/TanTable";
import { useStateContext } from "pages/context/StateContext";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpsPrice = () => {
  const { isLoading, allUpsPrice, getAllUpsPrice, setIsLoading, baseUrl, uploadConfig } =
    useStateContext();
  
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const formatZonePrice = (value) => {
    if (value == null) return "₦ 0.00";
    const formattedValue = Number(value).toLocaleString("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `₦ ${formattedValue}`;
  };

  const columns = [
    { header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
    { header: "KG", accessorKey: "kg" },
    { header: "Zone 1", accessorKey: "zone_1", cell: ({ row }) => formatZonePrice(row.original.zone_1) },
    { header: "Zone 2", accessorKey: "zone_2", cell: ({ row }) => formatZonePrice(row.original.zone_2) },
    { header: "Zone 3", accessorKey: "zone_3", cell: ({ row }) => formatZonePrice(row.original.zone_3) },
    { header: "Zone 4", accessorKey: "zone_4", cell: ({ row }) => formatZonePrice(row.original.zone_4) },
    { header: "Zone 5", accessorKey: "zone_5", cell: ({ row }) => formatZonePrice(row.original.zone_5) },
    { header: "Zone 6", accessorKey: "zone_6", cell: ({ row }) => formatZonePrice(row.original.zone_6) },
    { header: "Zone 7", accessorKey: "zone_7", cell: ({ row }) => formatZonePrice(row.original.zone_7) },
    { header: "Zone 8", accessorKey: "zone_8", cell: ({ row }) => formatZonePrice(row.original.zone_8) },
  ];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("ups_price", file);

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/admin/ups-shipment-price`,
        formData,
        uploadConfig()
      );
      // console.log("Server Response:", response?.data);
      toast.success(response?.data?.message || "File uploaded successfully");

      await getAllUpsPrice();

      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      // console.error("Upload error:", error);
      toast.error(
        error?.response?.data?.message || "An error occurred during upload"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <div className="mx-auto mt-0 p-4 bg-white">
        <div className="p-2 flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-2 items-center md:mb-2 pt-3 mb-5">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">UPS Price</p>
          </div>

          <div>
          <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              ref={fileInputRef}
              className="mr-2"
            />
            <button
              className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
              onClick={handleFileUpload}
              disabled={!file}
            >
              Upload Price
            </button>
          </div>
        </div>
      </div>

      <h1 className="text-center bg-white">
        Below are the price list belonging to different countries based on their zones
      </h1>
      <div>
        <TanTable
          columnData={columns}
          data={allUpsPrice}
          loadingState={isLoading}
        />
      </div>
    </div>
  );
};

export default UpsPrice;
