import DateFormatter from "Utils/DateFormatter";
import React from "react";

const SingleUnsuccessful = ({ item, index }) => {
  const {
    account_name: name,
    amount_to_withdraw: AmountWithdrawn,
    status,
    account_number,
    bank_name,
    created_at,
  } = item || {};

  return (
    <div className="grid text-sm grid-cols-7 gap-2">
      <h2>{index + 1}</h2>
      <h2>{name}</h2>
      <h2>{account_number}</h2>
      <h2>{bank_name}</h2>
      <DateFormatter time={created_at} />
      <h2>₦{AmountWithdrawn | "0.00"}</h2>
      <h2>{status}</h2>
    </div>
  );
};

export default SingleUnsuccessful;
