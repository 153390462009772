import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { useState, useEffect } from "react";
import { TfiSearch } from "react-icons/tfi";
import { Spinner } from "react-activity";
import { useStateContext } from "pages/context/StateContext";
import deleteIcon from "../../../Assest/images/delete.png";
import TanTable from "Utils/TanTable";
import axios from "axios";
import { config } from "Utils/apiConfig";
import { toast } from "react-toastify";

function ZoneTable() {
  const { zones, isLoading, baseUrl, getAllZones } = useStateContext();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (id) => {
    const url = `${baseUrl}/admin/zone/${id}`;
    setIsDeleting(true);
    try {
      const res = await axios.delete(url, config());
      toast.success(res.data.message || "Delete success");
      getAllZones();
    } catch (error) {
      toast.error(error.response.data.message || "An error occured");
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const columns = [
    {
      header: "S/N",
      accessorKey: "id",
      cell: ({ row }) => <h2>{row.index + 1}</h2>,
    },

    {
      header: "Zone",
      accessorKey: "zone",
    },
    {
      header: "Actions",
      accessorKey: "action",
      cell: ({ row }) => {
        const itemId = row.original.id;
        return (
          <div className="">
            {isDeleting ? (
              <Spinner />
            ) : (
              <button onClick={() => handleDelete(itemId)} className="">
                <img src={deleteIcon} className="w-7" alt="delete" />
              </button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <TanTable columnData={columns} data={zones} loadingState={isLoading} />
    </div>
  );
}

export default ZoneTable;
