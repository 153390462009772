import React from 'react';
import ViewComplaint from './components/StaffApp/ViewComplaint';
const ViewComplaintPage = () => {
  return (
    <div>
      <ViewComplaint/>
    </div>
  );
}

export default ViewComplaintPage;
