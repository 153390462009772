import React from 'react'
import { FaHome, FaChevronRight } from "react-icons/fa";
import MobileCharts from './MobileCharts';

const MobileHome = () => {
    return (
      <div className="">
        <div className=" flex flex-col md:flex-row justify-between items-center mx-auto mt-0 p-4 bg-white">
          <div className="flex gap-2 items-center md:mb-0 pt-7 mb-5 ">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">Mobile App</p>
          </div>
        </div>
        <MobileCharts />
      </div>
    );
}

export default MobileHome