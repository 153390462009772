import React, { useState } from "react";
import SingleAdmin from "./SingleAdmin";
import Table from "Utils/Table";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const tableHeader = ["S/N", "name", "role", "Status", "Action"];

const Admin = () => {
  const [adminDetails, setAdminDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { token, config, baseUrl, getAllAdmins, allAdmin } = useStateContext();
  const handleAdminChange = (e) => {
    setAdminDetails({ ...adminDetails, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const url = `${baseUrl}/admin/add-admin`;
    setIsLoading(true);
    try {
      const res = await axios.post(url, adminDetails, config(token));
      toast.success(res.data.message);
      getAllAdmins();
      setAdminDetails({});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="p-8">
        <div className="flex gap-2 text-black p-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h1>Home</h1>
          <svg
            className="pt-2"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
              stroke="black"
              fill="#1F1E1E"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <h1 className="text-[#049805]">Add New Admin</h1>
        </div>
        {/* <p className="font-bold">Add new Admin</p> */}
      </div>
      <div className="flex items-center justify-center mb-10">
        <div className="bg-whit  rounde shadow-m w-96">
          <div className="text-center mb-6">
            <p className="text-2xl font-bold">Create New Admin</p>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label
                htmlFor="adminName"
                className="block text-sm font-medium text-gray-600 mb-2"
              >
                Admin Name:
              </label>
              <input
                type="text"
                id="adminName"
                value={adminDetails?.admin_name || ""}
                name="admin_name"
                onChange={handleAdminChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="adminRole"
                className="block text-sm font-medium text-gray-600 mb-2"
              >
                Admin Role:
              </label>
              <select
                onChange={handleAdminChange}
                className="w-full p-2 border rounded"
                value={adminDetails?.admin_role || ""}
                name="admin_role"
                id=""
              >
                <option value="">Select</option>
                <option value="1">Admin</option>
                <option value="2">Super Admin</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="adminRole"
                className="block text-sm font-medium text-gray-600 mb-2"
              >
                Admin Email:
              </label>
              <input
                type="text"
                id="adminRole"
                value={adminDetails?.admin_email || ""}
                name="admin_email"
                onChange={handleAdminChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-600 mb-2"
              >
                Password:
              </label>
              <input
                type="password"
                id="password"
                value={adminDetails?.password || ""}
                name="password"
                onChange={handleAdminChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-[#049805] text-white p-2 rounded focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 flex items-center justify-center"
            >
              {isLoading ? <Spinner /> : "Create New Admin"}
            </button>
          </form>
        </div>
      </div>
      <div className="p-4">
        <Table
          headerContent={tableHeader}
          minSize={"1000px"}
          loadingState={isLoading}
          cols={5}
          data={allAdmin}
        >
          {allAdmin?.map((item, index) => (
            <div key={index}>
              <SingleAdmin
                // openModal={handleOpenModal}
                item={item}
                index={index}
              />
              <hr className="my-4 border-green-50" />
            </div>
          ))}
        </Table>
      </div>
    </>
  );
};

export default Admin;
