import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import { useState, useEffect } from "react";
import { TfiSearch } from "react-icons/tfi";
import { Spinner } from "react-activity";
import { useStateContext } from "pages/context/StateContext";
import deleteIcon from "../../../../Assest/images/delete.png";

function SeaCargoPriceTable() {
  // const [tableData, setTableData] = useState([data]);
  // const handleDelete = (id) => {
  //   setTableData(tableData.filter((item) => item.id !== id));
  // };

  const data = [
    {
      id: 1,
      kg: "28kg - 70kg",
      price: "₦500",
      zone: "C",
      actions: <img src={deleteIcon} alt="delete" />,
    },
    {
      id: 2,
      kg: "71kg - 100kg",
      price: "₦10000",
      zone: "B",
      actions: "view",
    },
    {
      id: 3,
      kg: "28kg - 70kg",
      price: "₦7000",
      zone: "B",
      actions: "edit",
    },
    {
      id: 4,
      kg: "71kg - 100kg",
      price: "₦50000",
      zone: "B",
      actions: "delete",
    },
    {
      id: 5,
      kg: "28kg - 70kg",
      price: "₦8000",
      zone: "D",
      actions: "view",
    },
    {
      id: 6,
      kg: "71kg - 100kg",
      price: "₦60000",
      zone: "B",
      actions: "edit",
    },
    {
      id: 7,
      kg: "28kg - 70kg",
      price: "₦8500",
      zone: "C",
      actions: "edit",
    },
    {
      id: 8,
      kg: "71kg - 100kg",
      price: "₦12000",
      zone: "A",
      actions: "delete",
    },
    {
      id: 9,
      kg: "28kg - 70kg",
      price: "₦9000",
      zone: "B",
      actions: "view",
    },
    {
      id: 10,
      kg: "71kg - 100kg",
      price: "₦55000",
      zone: "B",
      actions: "edit",
    },
    {
      id: 11,
      kg: "28kg - 70kg",
      price: "₦8000",
      zone: "C",
      actions: "edit",
    },
    {
      id: 12,
      kg: "71kg - 100kg",
      price: "₦10000",
      zone: "B",
      actions: "delete",
    },
  ];

  const [tableData, setTableData] = useState(data);

  const handleDelete = (id) => {
    setTableData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const columns = [
    {
      header: "S/N",
      accessorKey: "id",
    },
    {
      header: "Kg",
      accessorKey: "kg",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    {
      header: "Zone",
      accessorKey: "zone",
    },
    {
      header: "Actions",
      accessorKey: "action",
      cell: ({ row }) => {
        const itemId = row.original.id;

        return (
          <div className="flex items-center pl-2">
            <button
              onClick={() => handleDelete(itemId)}
              className="flex items-center md:mr-[-7rem]"
            >
              <img src={deleteIcon} className="md:ml-20 w-7 h-7" alt="delete" />
            </button>
          </div>
        );
      },
    },
  ];

  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState("");
  const { isLoading } = useStateContext();

  const handleFilter = (value) => {
    if (value !== filters) {
      setFilters(value);
    }
  };

  useEffect(() => {
    // This will only run when sorting or filters change
    console.log("Sorting or filters changed:", sorting, filters);
  }, [sorting, filters]);

const table = useReactTable({
  data: tableData, // Use tableData state instead of data array directly
  columns,
  getCoreRowModel: getCoreRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  state: {
    sorting,
    globalFilter: filters,
  },
  onSortingChange: setSorting,
  onGlobalFilterChange: (newGlobalFilter) => {
    setFilters(newGlobalFilter);
  },
});


  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="flex flex-row">
        <div className="flex flex-col m-6 space-y-6 md:ml-2 mt-[0.3rem] w-1/2 md:w-full ">
          <div className="relative flex items-center ">
            {/* Input starts */}
            <input
              type="text"
              className="rounded-xl w-1/2  lg:w-[50rem] py-3 outline-none text-xl placeholder-gray-400 pl-12 bg-gray-100"
              placeholder="Search Sea Cargo..."
              value={filters}
              onChange={(e) => handleFilter(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-4 pointer-events-none ">
              <TfiSearch />
            </div>
            {/* <div>
              <img src={sortIcon} className="w-6 h-6 ml-12" />
            </div> */}
          </div>

          {/* Input Ends */}
          <table className="mx-auto w-full mt-4">
            <thead className="">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className=" p-4 text-center bg-gray-200"
                      key={header.id}
                      w={header.getSize()}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.column.columnDef.header}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading ? (
              <div className="flex items-center justify-center h-screen">
                <Spinner />
              </div>
            ) : (
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr className="" key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <>
                        <td
                          className="p-2 text-center "
                          key={cell.id}
                          w={cell.column.getSize()}
                        >
                          <div className="truncate-text">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        </td>
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>

          <div className="flex flex-row space-x-3">
            <button onClick={() => table.setPageIndex(0)}>First page</button>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              Previous page
            </button>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              Next page
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              Last page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeaCargoPriceTable;
