import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiMoreHorizontal } from "react-icons/fi";
import ActionModal from "../shipmentBatchDetails/ActionModal";
import TrackingModal from "./TrackingModal";
import UpdateAllTrackingModal  from "./UpdateAllTrackingModal";
import Table from "../../../Utils/Table";
import { Link } from "react-router-dom";
import SingleBatchDetails from "./SingleBatchDetails";
import { useStateContext } from "pages/context/StateContext";

const tableHeader = [
  "S/N",
  "Tracking No",
  "Batch",
  "Country",
  "Price",
  "Action",
];

const BatchDetails = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTrackingModalOpen, setTrackingModalOpen] = useState(false);
  const [isUpdateAllTrackingModal, setUpdateAllTrackingModal] = useState(false);
  const { batchDetails, getBatchDetails, isLoading, setSingleBacthDetail } =
    useStateContext();
  useEffect(() => {
    getBatchDetails();
  }, []);

  const handleOpenModal = (item) => {
    setModalOpen(true);
    setSingleBacthDetail(item);
    
  };
  return (
    <div className="grid justify-center">
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">shipment batch Details</h1>
      </div>

      <div className="p-3 md:p-6 max-w-full overflow-x-auto">
        <h1 className=" text-center md:text-left font-bold">
          Shipment Batch Details
        </h1>
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* csv */}
          <div className="m-4 flex text-xs gap-2 pr-6">
            <a
              href="your-csv-file.csv" //  CSV file path
              download
              className="bg-[#E5E5E5] text-black py-2 px-4 rounded"
            >
              CSV
            </a>

            <button
              // onClick={handleCopy}
              className="bg-[#E5E5E5] text-black px-4 rounded"
            >
              Copy
            </button>
          </div>

          {/* Search */}
          <div className="flex flex-col md:flex-row justify-between items-center m-2">
            <div className="relative mt-4 md:mt-0 w-full md:w-auto ">
              <input
                type="text"
                placeholder="Search..."
                className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 w-full focus:outline-none focus:ring focus:border-blue-300"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <AiOutlineSearch />
              </div>
            </div>
          </div>
        </div>
        {/* Table */}
        <Table
          headerContent={tableHeader}
          minSize={"1000px"}
          loadingState={isLoading}
          cols={6}
          data={batchDetails}
        >
          {batchDetails?.map((item, index) => (
            <div key={index}>
              <SingleBatchDetails
                openModal={handleOpenModal}
                item={item}
                index={index}
              />
              <hr className="my-4 border-green-50" />
            </div>
          ))}
        </Table>
      </div>
      <div>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          title="Actions"
          className="sm:w-[80%] md:w-[50%] w-auto"
        >
          <button
            onClick={() => setTrackingModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded lg:px-6 lg:py-3"
          >
            Update Tracking
          </button>
          <button
            // onClick={() => setViewBatchModalOpen(true)}
            className="bg-green-500 text-white px-4 py-2 rounded lg:px-6 lg:py-3"
          >
            <Link to={"viewBatchDetails"}>View Batch Details</Link>
          </button>

          {/* <button
            onClick={() => setUpdateAllTrackingModal(true)}
            className="bg-[#874C4C] text-white px-4 py-2 rounded lg:px-6 lg:py-3"
          >
           Update All Tracking
          </button> */}
        </ActionModal>
        <TrackingModal
          isOpen={isTrackingModalOpen}
          onClose={() => setTrackingModalOpen(false)}
        />
         <UpdateAllTrackingModal
          isOpen={isUpdateAllTrackingModal}
          onClose={() => setUpdateAllTrackingModal(false)}
        />

        {/* <ViewBatchModal
          isOpen={viewBatchModalOpen}
          onClose={() => setViewBatchModalOpen(false)}
        /> */}
      </div>
    </div>
  );
};

export default BatchDetails;
