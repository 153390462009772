import React from 'react'
import Query from './components/Query/Query'

const QueryPage = () => {
  return (
    <div>
      <Query/>
    </div>
  )
}

export default QueryPage
