import React from "react";
// import SingleHomeTable from "./SingleHomeTable";
// import TanTable from "../TanTable"
// import { Link } from "react-router-dom";
import { TanTable } from '../TanTable';

const HomeTable = ({ displayedData }) => {
  // const tableHeader = ["Name", "Date", "Status"];

  // Dummy data for the table
  const dummyData = [
    { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    // { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    // { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
    // { name: "Shola Tracy", date: "10/03/2024", status: "out of service" },
   
    // Add more rows as needed
    
  ];

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Date",
      accessorKey: "date",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    

    ]

  return (
      <div className="w-full max-w-4xl overflow-x-auto table-responsive lg:mt-[3.5rem]">
        {/* <h1 className="font-bold text-center">History</h1> */}
          <TanTable
          columnData={columns}
          data={dummyData}
          // loadingState={isLoading}
        />
    </div>
  );
};

export default HomeTable;
