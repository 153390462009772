import React from "react";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";
import DateFormatter from "Utils/DateFormatter";
import { MdDeleteOutline } from "react-icons/md";

const AllNew = () => {
  const { isLoading, allNews, imageUrl, deleteNews } = useStateContext();

  return (
    <>
      <div className="text-center font-bold">See All News</div>
      {isLoading ? (
        <div className="items-center justify-center flex my-20">
          <Spinner />
        </div>
      ) : allNews?.length < 1 ? (
        <h2 className="my-20 text-center">No news to display</h2>
      ) : (
        allNews?.map(
          ({ news_title, news_details, news_image, created_at, id }, index) => {
            const details = { __html: news_details };
            <div dangerouslySetInnerHTML={news_details} />;
            return (
              <div
                key={index}
                className="flex flex-wrap items-center justify-between p-8 rounded-md m-4 shadow-xl shadow-gray-200"
              >
                <div className="flex justify-between gap-4 w-full">
                  <img
                    src={`${imageUrl}${news_image}`}
                    alt="Author"
                    className="w-20 h-20 rounded-full object-cover shadow-xl"
                  />

                  <div className="flex flex-col md:flex-row md:items-center justify-between w-full gap-4">
                    <div>
                      <h2 className="font-bold text-gray-700">{news_title}</h2>
                      <div dangerouslySetInnerHTML={details} />
                      <DateFormatter time={created_at} />
                    </div>
                    <div className="flex items-center gap-2">
                      <button className="text-blue-500 font-semibold text-sm">
                        Read more
                      </button>
                      <button
                        onClick={() => deleteNews(id)}
                        className="text-red-500"
                      >
                        <MdDeleteOutline />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )
      )}
    </>
  );
};

export default AllNew;
