import React from 'react'
import { FaHome, FaChevronRight } from "react-icons/fa";
import ComplaintsTable from './ComplaintsTable';
import { useStateContext } from 'pages/context/StateContext';
const Complains = () => {
  const {allComplaints} = useStateContext()
  return (
    <div className="mx-auto mt-0 p-4 bg-white">
      <div className="p-2 flex flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center md:mb-0 pt-7 mb-5 ">
          <FaHome className="m-0" />
          <p className="text-black">Home</p>
          <FaChevronRight className="m-1 text-[#049805]" />
          <p className="text-[#049805]"> Complaints/Suggestions</p>
        </div>
      </div>
      <div className="flex items-center justify-center bg-gray-200 rounded-full p-4">
        <h1 className="text-4xl font-semibold text-black bg-gray-300 rounded-full p-4">
          Complaints/Suggestions
        </h1>
      </div>
      <h1 className="text-4xl font-semibold text-black p-4">
        Complaints <span className="text-green-600">({allComplaints.length})</span>
      </h1>
      <ComplaintsTable/>
    </div>
  );

 };

export default Complains;





















 