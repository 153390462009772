import DateFormatter from "Utils/DateFormatter";
import React from "react";

const SingleRequest = ({ item, index }) => {
  const {
    affiliate_commission,
    created_at,
    customer_address,
    customer_name: name,
    customer_phone,
    id,
    order_status,
    payment_status,
    price,
    user_id,
  } = item || {};
  return (
    <div>
      <div className="grid text-sm grid-cols-9 gap-2">
        <h2>{index + 1}</h2>
        <DateFormatter time={created_at} />
        <h2>{name}</h2>
        <h2> ₦ {price || "0.00"}</h2>
        <h2> ₦ {affiliate_commission || "0.00"}</h2>
        <h2>{payment_status}</h2>
        <h2>{order_status}</h2>
        <h2>{customer_phone}</h2>
        <h2>{customer_address}</h2>
      </div>
    </div>
  );
};

export default SingleRequest;
