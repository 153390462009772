import React from "react";
import Chart from "./Chart";

const AirCargoChart = () => {
  return (
    <div className="w-[19rem] md:w-[22rem] lg:w-[27.5rem] h-[18rem] border-2">
      <div className="py-2">
        <p className="font-bold pb-3">Air Cargo</p>
        <Chart rate={91} />
        <div className="relative bottom-12 ml-2 text-start  space-y-[-0.9rem] tracking-wide font-bold">
          <div className="flex  ml-2 text-[#FEAE0D] justify-between">
            <div className="flex space-x-3">
              <p className="text-4xl relative bottom-2">&#8226;</p>
              <p className="text-zinc-900 text-[12px] md:text-[16px] ml-[-5px] font-normal">
                Total number of processed items
              </p>
            </div>
            <p className="pe-3 ">1760</p>
          </div>
          <div className="flex  ml-2 text-[#3771C8] justify-between">
            <div className="flex space-x-3">
              <p className="text-4xl relative bottom-2 ">&#8226;</p>
              <p className="text-zinc-900 text-[12px] md:text-[16px] ml-[-5px] font-normal">
                Total number of sent items
              </p>
            </div>
            <p className="pe-3 font-bold">1360</p>
          </div>
          <div className="flex  ml-2 text-[#FF0036] justify-between">
            <div className="flex space-x-3">
              <p className="text-4xl relative bottom-2 ">&#8226;</p>
              <p className="text-zinc-900 text-[12px] md:text-[16px] ml-[-5px] font-normal">
                Total number of unsent items
              </p>
            </div>
            <p className="pe-3 ">400</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirCargoChart;
