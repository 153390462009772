import React, { useState } from "react";
import TanTable from "Utils/TanTable";
import { useStateContext } from "pages/context/StateContext";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponseModal from "./ResponseModal ";

const Query = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const { isLoading, allQuery, setAllQuery, setIsLoading, baseUrl, config } =
    useStateContext();

  const getmgtResponse = async (id, managementResponse) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${baseUrl}/admin/query/${id}`,
        { management_response: managementResponse },
        config()
      );

      const { data } = response;
      if (data?.status === true && data?.message) {
        const updatedQueries = allQuery.map((query) =>
          query.id === id
            ? { ...query, management_response: managementResponse }
            : query
        );
        setAllQuery(updatedQueries);

        toast.success(
          data.message || "Management response updated successfully!"
        );
      } else {
        toast.success("Management response updated successfully!");
      }
    } catch (error) {
      // console.error("Error updating management response:", error);
      toast.error("Failed to update management response.");
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const handleModalOpen = (id) => {
    setSelectedQueryId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Full Name",
      accessorKey: "user.fullname",
      cell: (item) => (
        <span>
          {item.row.original.user.firstname} {item.row.original.user.lastname}
        </span>
      ),
    },
    {
      header: "Query Title",
      accessorKey: "query_title",
    },
    {
      header: "Query Message",
      accessorKey: "query_message",
      cell: (item) => (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            width: "400px",
            maxWidth: "400px",
            overflowWrap: "break-word",
          }}
        >
          {item.getValue()}
        </div>
      ),
    },
    {
      header: "Staff Response",
      accessorKey: "staff_response",
      cell: (item) => (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            width: "400px",
            maxWidth: "400px",
            overflowWrap: "break-word",
          }}
        >
          {item.getValue()}
        </div>
      ),
    },
    {
      header: "Mgt Response",
      accessorKey: "management_response",
      cell: (item) => (
        <div
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            width: "400px",
            maxWidth: "400px",
            overflowWrap: "break-word",
          }}
        >
          {item.getValue()}
        </div>
      ),
    },
    {
      header: "Status",
      accessorKey: "query_status",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: (item) => (
        <button
          onClick={() => handleModalOpen(item.row.original.id)}
          className="bg-blue-500 text-white rounded px-2 py-2"
        >
          Add response
        </button>
      ),
    },
  ];

  return (
    <div>
      <TanTable
        columnData={columnData}
        data={allQuery}
        loadingState={isLoading}
      />

      {/* Render the ResponseModal */}
      <ResponseModal
        queryId={selectedQueryId}
        isOpen={showModal}
        onClose={handleModalClose}
        onSubmit={getmgtResponse}
      />
      <ToastContainer />
    </div>
  );
};

export default Query;
