import React from 'react'
import SeaEditShipmentOrder from './components/SeaCargoPlacement/SeaEditShipmentOrder'

const SeaEditShipmentOrderPage = () => {
  return (
    <div>
      <SeaEditShipmentOrder/>
    </div>
  )
}

export default SeaEditShipmentOrderPage
