import React from 'react'
import ViewAllSuccessWithdraw from "./components/Affiliate/ViewAllSuccessWithdraw"
const ViewAllSuccessfulPage = () => {
  return (
    <div>
      <ViewAllSuccessWithdraw/>
    </div>
  )
}

export default ViewAllSuccessfulPage
