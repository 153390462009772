import React, { useState } from "react";
import AllNew from "../News/AllNew";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useStateContext } from "pages/context/StateContext";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddNews = () => {
  const [showAllNews, setShowAllNews] = useState(false);
  const [newsTopic, setNewsTopic] = useState("");
  //const [newsDetails, setNewsDetails] = useState("");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  const { baseUrl, config, token, getNews } = useStateContext();

  const handleSeeAllNews = () => {
    setShowAllNews(true);
  };

  const handleGoBack = () => {
    setShowAllNews(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${baseUrl}/admin/create-news`;
    setIsLoading(true);
    const data = {
      news_title: newsTopic,
      // news_details: newsDetails,
      news_details: editorContent,
      news_image: file,
    };
    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      const res = await axios.post(url, formData, config(token));
      console.log(res.data);
      toast.success(res.data.message);
      getNews();
      setShowAllNews(true);
    } catch (error) {
      toast.error("An error occurred");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    // Handle form submission logic here
  };

  return (
    <div>
      <ToastContainer />
      {showAllNews ? (
        <div>
          <button
            className=" text-whit font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
            onClick={handleGoBack}
          >
            <FaArrowLeftLong />
          </button>
          <AllNew />
        </div>
      ) : (
        <div>
          <div className="flex justify-around item-center p-4">
            <div className="flex gap-2 text-black">
              {/* ... SVG and heading code ... */}
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
              onClick={handleSeeAllNews}
            >
              See All News
            </button>
          </div>

          <div className="flex gap-2 text-black p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h1>Home</h1>
            <svg
              className="pt-2"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
                stroke="black"
                fill="#1F1E1E"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h1 className="text-[#049805]">News</h1>
          </div>

          <p className="text-center font-bold">Add News</p>
          <div className="w-full max-w-md mx-auto p-6">
            <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
              <div className="mb-8">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="newsTopic"
                >
                  Enter News Topic
                </label>
                <input
                  className="bg-[#D9D9D980] shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="newsTopic"
                  type="text"
                  placeholder="News Topic"
                  value={newsTopic}
                  onChange={(e) => setNewsTopic(e.target.value)}
                  required
                />
              </div>
              <div className="mb-8">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="newsDetails"
                >
                  Enter Details about News
                </label>

                <ReactQuill
                  className="bg-[#D9D9D980] quill-editor"
                  value={editorContent}
                  onChange={(value) => setEditorContent(value)}
                  required
                />
              </div>

              <div className="mb-8">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="file"
                >
                  Kindly upload a picture concerning the news
                </label>
                <input
                  className="border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="file"
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="bg-[#049805] w-full text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline flex items-center justify-center"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Publish"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNews;
