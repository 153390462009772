import React, { useState, useEffect } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CountryModal from "./CountryModal";
import CountryTable from "./CountryTable";
import { useStateContext } from "pages/context/StateContext";

const Country = () => {
  const { allCountry, getAllCountry, baseUrl, config, token, } = useStateContext();
  console.log("allcountry:", allCountry);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [zone, setZone] = useState([]);

  // useEffect(() => {
  //   getAllCountry();
  // }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const handleAddZone = (newZone) => {
  //   setZone([...zone, newZone]);
  //   setIsModalOpen(false); // Close modal after adding zone
  //   toast.success("Country added successfully!"); // Show success toast message
  // };

  const handleAddZone = () => {
    // const newCountry = {
    //   id: zone.length + 1, // Generate a unique ID for the new country
    //   country: newZone.country, // Assuming newZone contains country and zone properties
    //   zone: newZone.zone,
    // };
  
    // // Update the state with the new country
    // setZone([...zone, newCountry]);
    setIsModalOpen(false); // Close modal after adding country and zone
    toast.success("Country added successfully!"); // Show success toast message
  };

  return (
    <div>
      <div className="mx-auto mt-0 p-4 bg-white">
        <div className="p-2 flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-2 items-center md:mb-2 pt-3 mb-5">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">Country</p>
          </div>
          <button
            className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
            onClick={() => setIsModalOpen(true)}
          >
            Add Country
          </button>
        </div>
      </div>
      {/* Render CountryTable with zones data */}
      {allCountry.length > 0 ? (
        <CountryTable country={allCountry} />
      ) : (
        <p className="text-gray-500 text-center my-20">
          No country have been added yet
        </p>
        // <ZoneTable zones={zones} />
      )}
      {/* Render CountryModal if isModalOpen is true */}
      <CountryModal
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleAddZone={handleAddZone}
      />
      <ToastContainer />
    </div>
  );
};

export default Country;
