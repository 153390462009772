import React, { useState, useMemo } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { Spinner } from "react-activity";
import { useStateContext } from "pages/context/StateContext";
// import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import TanTable from "Utils/TanTable";
import axios from "axios";
import { toast } from "react-toastify";
import EditCountryModal from "./EditCountryModal "




const CountryTable = () => {
  const { allCountry, isLoading, config, baseUrl, getAllCountry } = useStateContext();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);


  const handleDelete = async (id) => {
    const url = `${baseUrl}/admin/shipment-country/${id}`;
    setIsDeleting(true);
    try {
      const res = await axios.delete(url, config());
      if (res.data && res.data.message) {
        toast.success(res.data.message);
      } else {
        toast.success("Delete success");
      }
      getAllCountry();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred");
      }
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };


  const handleEdit = (rowData) => {
    setEditData(rowData);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditData(null);
    setIsEditModalOpen(false);
  };

  const handleSave = () => {
    setIsEditModalOpen(false);
    getAllCountry();
  };

  

  // const mData = [
  //   {
  //     id: 1,
  //     country_name: "Nigeria",
  //     zones: "C",
  //   },
  // ];

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Country Name",
      accessorKey: "country",
    },
    {
      header: "Zones ",
      accessorKey: "zone",
      //   cell: (item) => <span>{item.row.original.phone_number}</span>,
    },

    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => {
        // const itemId = row.original.id;
        const rowData = row.original;
        return (
          <div className="flex gap-4">
            {isDeleting ? (
              <Spinner />
            ) : (
              <>
                <RiDeleteBinLine
                  onClick={() => handleDelete(rowData.id)}
                  className="text-[#ff4242] cursor-pointer"
                />
                  <FiEdit2
                     onClick={() => handleEdit(rowData)}
                    className="cursor-pointer text-[#4d6cf6]" />
              </>
            )}
          </div>
        );
      },
    },
    
  ];

 

  return (
    <div className=" mx-auto mt-0 p-4 bg-white ">
      <TanTable columnData={columnData} data={allCountry} loadingState={isLoading}    />
      <EditCountryModal
      isOpen={isEditModalOpen}
      handleCloseModal={handleEditModalClose}
      handleSave={handleSave}
      editData={editData}
    />
    </div>
  );
};

export default CountryTable;
