import React from "react";

const SingleSuccessful = ({ item, index }) => {
  const { account_name, amount_to_withdraw, bank_name, status } = item || {};
  return (
    <div>
      <div className="grid text-sm grid-cols-6 gap-2">
        <h2>{index + 1}</h2>
        <h2>{account_name}</h2>
        <h2>{amount_to_withdraw}</h2>
        <h2>{bank_name}</h2>
        <h2>{status}</h2>
      </div>
    </div>
  );
};

export default SingleSuccessful;
