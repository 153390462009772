import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    month: "Jan",
    "Air Cargo": 1800,
    "UPS Delivery": 870,
    "DHL Shipping": 1800,
    "Sea Cargo": 870,
  },
  {
    month: "Feb",
    "Air Cargo": 1100,
    "UPS Delivery": 350,
    "DHL Shipping": 1100,
    "Sea Cargo": 350,
  },
  {
    month: "Mar",
    "Air Cargo": 1200,
    "UPS Delivery": 1350,
    "DHL Shipping": 1000,
    "Sea Cargo": 850,
  },
  {
    month: "Apr",
    "Air Cargo": 1200,
    "UPS Delivery": 750,
    "DHL Shipping": 1000,
    "Sea Cargo": 750,
  },
  {
    month: "May",
    "Air Cargo": 850,
    "UPS Delivery": 1050,
    "DHL Shipping": 1350,
    "Sea Cargo": 850,
  },
  {
    month: "June",
    "Air Cargo": 1100,
    "UPS Delivery": 580,
    "DHL Shipping": 950,
    "Sea Cargo": 950,
  },
  {
    month: "July",
    "Air Cargo": 1200,
    "UPS Delivery": 1500,
    "DHL Shipping": 950,
    "Sea Cargo": 550,
  },
  {
    month: "Aug",
    "Air Cargo": 900,
    "UPS Delivery": 1555,
    "DHL Shipping": 900,
    "Sea Cargo": 1250,
  },
  {
    month: "Sept",
    "Air Cargo": 500,
    "UPS Delivery": 850,
    "DHL Shipping": 1205,
    "Sea Cargo": 1100,
  },
  {
    month: "Oct",
    "Air Cargo": 1400,
    "UPS Delivery": 750,
    "DHL Shipping": 1600,
    "Sea Cargo": 1200,
  },
  {
    month: "Nov",
    "Air Cargo": 600,
    "UPS Delivery": 1200,
    "DHL Shipping": 600,
    "Sea Cargo": 750,
  },
  {
    month: "Dec",
    "Air Cargo": 1300,
    "UPS Delivery": 855,
    "DHL Shipping": 700,
    "Sea Cargo": 800,
  },
];

const BarChartComponent = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <p>Total Number of Shipments </p>
      <BarChart
        data={data}
        margin={{ top: 15, right: 30, left: 18, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="1 4" />
        <XAxis dataKey="month" />
        <YAxis domain={[0, 1800]} />
        <Tooltip />
        <Legend />
        <Bar dataKey="Air Cargo" fill="#049805" />
        <Bar dataKey="UPS Delivery" fill="#FEAE0D" />
        <Bar dataKey="DHL Shipping" fill="#3771C8" />
        <Bar dataKey="Sea Cargo" fill="#FC6A5A" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
