import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
// import DeleteSuccessMsg from "../SuccessMsg/DeleteSuccessMsg";

const ActionModal = ({
  showModal,
  setShowModal,
  setShowUpdateModal,
  setOpenUpdateModal,
  // handleEdit, // New prop
  // currentEditItem, // New prop
  // onDelete,
}) => {
  const navigate = useNavigate();
  const { initializeEditData } = useStateContext();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  //   const handleEditClick = () => {
  //     const { Action, ...serializableRowData } = currentEditItem;

  //     initializeEditData(serializableRowData);  // <-- Initialize context data

  //     navigate("/home/aircargo/edit", {
  //         state: { dataToEdit: serializableRowData },
  //     });
  // };

  // const handleEditClick = () => {
  //   const { Action, ...serializableRowData } = currentEditItem;
  //   navigate("/home/aircargo/edit", {
  //     state: { dataToEdit: currentEditItem },
  //   });
  // };

  return (
    <div>
      {showModal ? (
        <div className="fixed inset-0 z-50 flex items-start justify-between py-20 px-10 ">
          <div></div>
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 blur opacity-90"></div>
          <div className="bg-white p-5 rounded shadow-lg w-1/8 mt-10 ml-10 z-10">
            <span className="block text-right">
              <button onClick={() => setShowModal(false)}> &times;</button>
            </span>
            <div className="flex flex-col space-y-4 text-sm">
              <button
                className="bg-[#94a3b8] text-white py-1 px-3 rounded"
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setShowUpdateModal(true); // Open UpdateTrackingModal
                }}
              >
                Update Tracking
              </button>
              <button
                className="bg-green-500 text-white py-1 px-3 rounded"
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setOpenUpdateModal(true); // Open UpdateModal
                }}
              >
                Update
              </button>
              {/* <button
                onClick={handleEditClick}
                className="bg-[#3771C8] text-white py-1 px-3 rounded"
              >
                Edit
              </button> */}

              {/* {showDeleteConfirmation ? (
                <>
                  <p>Do you want to delete the row?</p>
                  <button
                    className="bg-[#FF0036] text-white py-1 px-3 rounded"
                    onClick={() => {
                      onDelete(currentEditItem);
                      setShowDeleteConfirmation(false);
                    }}
                  >
                    Confirm Delete
                  </button>
                  <button onClick={() => setShowDeleteConfirmation(false)}>
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setShowDeleteConfirmation(true)}
                  className="bg-[#FF0036] text-white py-1 px-3 rounded"
                >
                  Delete
                </button>
              )}  */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ActionModal;
