import React, { useState } from "react";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "pages/context/StateContext";

const InventoryModal = ({ handleCloseModal }) => {
  const { config, baseUrl, getInventory } = useStateContext();

  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const url = `${baseUrl}/admin/create-inventory`;
      const options = {
        name: name,
        quantity: quantity,
        unit: unit,
      };
      const response = await axios.post(url, options, config());
      handleCloseModal(""); // Close the modal
      toast.success(response.data.message, {
        onClose: () => {
          getInventory(); // Update the table data
          setName(""); // Reset form fields
          setQuantity("");
          setUnit("");
        },
      }); // Send toast message
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Add New Item
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Item Name:
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Quantity:
                </label>
                <input
                  type="text"
                  name="quantity"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Select Unit:
                </label>
                <select
                  name="unit"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <option value="">Select Unit</option>
                  <option value="Pack">Pack</option>
                  <option value="Pair">Pair</option>
                  <option value="Box">Box</option>
                  <option value="Pieces">Pieces</option>
                  <option value="Bag">Bag</option>
                  <option value="Bowl">Bowl</option>
                  <option value="Portion">Portion</option>
                  <option value="Kg">Kg</option>
                  <option value="Yard">Yard</option>
                  <option value="Cm">Cm</option>
                  <option value="Liters">Liters</option>
                  <option value="Bottle">Bottle</option>
                  <option value="Sachets">Sachets</option>
                  <option value="Bundles">Bundles</option>
                </select>
              </div>
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default InventoryModal;
