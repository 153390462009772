import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CreateShipment = () => {
  const {
    handleShipmentDetails,
    shipmentDetails,
    setShipmentDetails,
    baseUrl,
    config,
    token,
    countries,
    allAffiliates,
    setImage,
  } = useStateContext();
  const [isLoading, setIsLoading] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [shipmentBatch, setShipmentBatch] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(isChecked);
    if (!isChecked) {
      alert("Please agree to the Terms & Conditions before submitting.");
    } else {
      console.log("first");
      navigate("createShipmentContd");
    }
  };

  const getShipmentBatches = async () => {
    setIsLoading(true);
    const url = `${baseUrl}/admin/get-shipment-batch/${shipmentDetails?.shipment_type_id}`;
    try {
      const res = await axios.get(url, config(token));
      setShipmentBatch(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(shipmentDetails?.shipment_type_id);
    if (shipmentDetails?.shipment_type_id) {
      getShipmentBatches();
    }
  }, [shipmentDetails?.shipment_type_id]);

  useEffect(() => {
    setShipmentDetails({
      ...shipmentDetails,
      affiliate_id: null,
      insurance_amount: null,
    });
  }, []);

  return (
    <div className="">
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Create Shipment</h1>
      </div>
      <div className="p-4 font-bold">
        <h1>Create a Shipment</h1>
      </div>

      <form
        className=" shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleSubmit}
      >
        {/* First row */}
        <div className="flex mb-10">
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Choose Type
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="type"
              name="shipment_type_id"
              onChange={handleShipmentDetails}
              value={shipmentDetails?.shipment_type_id || ""}
              required
            >
              {/* Add your options here */}
              <option value="">Select Type</option>
              <option value="1">Air Cargo</option>
              <option value="2">Sea Cargo</option>
            </select>
          </div>
          <div className="w-1/2 pr-2">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Choose a Batch
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="batch"
              name="batch_id"
              onChange={handleShipmentDetails}
              value={shipmentDetails?.batch_id || ""}
              required
            >
              <option value="" selected>
                Select Batch
              </option>
              {shipmentBatch?.map(({ batch_number, batch_id }, ind) => (
                <option key={ind} value={batch_id}>
                  {batch_number}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Second row */}
        <div className="my-10">
          <div className="font-bold my-3">
            <p>Receiver Information</p>
          </div>
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-bold mb-2 ">
                Country shipping to
              </label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="batch"
                name="country_id"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.country_id || ""}
                required
              >
                <option value="" selected>
                  Select Country
                </option>
                {countries?.map(({ country_id, country_name }, ind) => (
                  <option key={ind} value={country_id}>
                    {country_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Full Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                name="receiver_name"
                type="text"
                placeholder="Name of Receiver"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.receiver_name || ""}
                required
                // value={formData.name}
              />
            </div>
          </div>

          {/* Third row */}
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                Address
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="address"
                name="receiver_address"
                type="text"
                placeholder="Address of Receiver"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.receiver_address || ""}
                required
                // value={formData.address}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm mb-2">
                Phone number
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phone"
                name="receiver_phone"
                type="tel"
                placeholder="Please enter a valid Phone Number"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.receiver_phone || ""}
                required
                // value={formData.phone}
              />
            </div>
          </div>

          {/* Fourth row */}
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                name="receiver_email"
                type="email"
                placeholder="e.g mikered34@gmail.com"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.receiver_email || ""}
                required
                // value={formData.email}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm mb-2">City</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="city"
                name="city"
                type="text"
                placeholder="Please enter your City"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.city || ""}
                required
                // value={formData.city}
              />
            </div>
          </div>

          {/* Fifth row */}
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                State
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="state"
                name="state"
                type="text"
                placeholder="Enter your state"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.state || ""}
                required
                // value={formData.state}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm mb-2">
                Zip Code
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="zip"
                name="zip_code"
                type="text"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.zip_code || ""}
                required
                // value={formData.zip}
              />
            </div>
          </div>
        </div>
        {/* Sixth row */}
        <div className="my-10">
          <div className="font-bold my-3">
            <p>Sender Information</p>
          </div>
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                Full Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="sendername"
                name="sender_name"
                type="text"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.sender_name || ""}
                required
                // value={formData.sendername}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm mb-2">
                Address
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="senderaddress"
                name="sender_address"
                type="text"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.sender_address || ""}
                required
                // value={formData.senderaddress}
              />
            </div>
          </div>

          {/* Seventh row */}
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                Phone Number
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="sendernumber"
                name="sender_phone"
                type="tel"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.sender_phone || ""}
                required
                // value={formData.sendernumber}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm mb-2">Email</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="senderemail"
                name="sender_email"
                type="email"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.sender_email || ""}
                required
                // value={formData.senderemail}
              />
            </div>
          </div>
        </div>

        {/* Eighth row */}
        <div className="my-10">
          <div className="font-bold my-3">
            <p>Other Information</p>
          </div>
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                Declared Value
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dropoffdate"
                name="goods_declared_value"
                type="text"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.goods_declared_value || ""}
                required
                // value={formData.dropoffdate}
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-gray-700 text-sm mb-2">
                Drop-Off Date
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dropoffdate"
                name="drop_off_date"
                type="date"
                placeholder=""
                onChange={handleShipmentDetails}
                value={shipmentDetails?.drop_off_date || ""}
                required
                // value={formData.dropoffdate}
              />
            </div>
          </div>

          {/* Ninth row */}
          {/* affiliate and affilaite id */}
          <div className="flex mb-4">
            <div className="w-1/2 pr-2">
              <label className="block text-gray-700 text-sm font-lighter mb-2">
                Name of Affiliate who referred the Client
              </label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="batch"
                name="affiliate_id"
                onChange={handleShipmentDetails}
                value={shipmentDetails?.affiliate_id || ""}
              >
                <option value="" selected>
                  Select Affiliate
                </option>
                {allAffiliates?.map(({ firstname, lastname, id }, ind) => (
                  <option key={ind} value={id}>
                    {firstname} {lastname}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* insurance */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Insurance
          </label>
          <div className="flex items-center">
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-gray-600"
                name="insurance"
                value="yes"
                checked={shipmentDetails?.insurance === "yes"}
                onChange={handleShipmentDetails}
                required
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-gray-600"
                name="insurance"
                value="no"
                checked={shipmentDetails?.insurance === "no"}
                onChange={handleShipmentDetails}
                required
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>
        {shipmentDetails?.insurance === "yes" && (
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2">
              Insurance Amount
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="affiliateid"
              name="insurance_amount"
              type="text"
              placeholder=""
              onChange={handleShipmentDetails}
              value={shipmentDetails?.insurance_amount || ""}
              // value={formData.affiliateid}
            />
          </div>
        )}

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Upload File
          </label>
          <input
            type="file"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            onChange={(e) => setImage(e.target.files[0])}
            required
          />
        </div>

        <label className="inline-flex items-center mr-4">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-gray-600"
            name="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            required
          />
          <span className="ml-2">I agree to the Terms & Conditions</span>
        </label>

        {/* Save button */}
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className={`bg-[#049805]  cursor-pointer text-white font-bold py-2 px-4 md:w-1/4 md:self-center rounded-lg focus:outline-none focus:shadow-outline`}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateShipment;
