import React from 'react'
import PendingPayment from './components/PendingPaymentOrder/PendingPayment'

const PendingPaymentOrderPage = () => {
  return (
    <div>
     <PendingPayment/> 
    </div>
  )
}

export default PendingPaymentOrderPage
