import React from "react";

function Modal({ isOpen, onClose, title, children }) {
    const [widthClass, setWidthClass] = React.useState('');

    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 767) {
                setWidthClass('modal-sm');
            } else if (width > 767 && width <= 1023) {
                setWidthClass('modal-md');
            } else {
                setWidthClass('');
            }
        };

        // Call handleResize once to set the initial width class
        handleResize();
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="absolute inset-0 bg-black opacity-60"></div>
            <div 
                className={`absolute top-0 right-0 m-[9rem] mx-12 bg-white p-6 rounded-xl shadow-lg z-10 ${widthClass}`} 
                // Removed inline style for width
            >
                <div className="flex justify-between items-start">
                    <h2 className="text-xl font-bold">{title}</h2>
                    <button onClick={onClose} className="text-lg">
                        &times;
                    </button>
                </div>
                <div className="mt-4 flex flex-col gap-3">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;
