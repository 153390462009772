import React from "react";

const SinglePendingPayment = ({ item, index, openModal }) => {
  const { amount_due, batch, customer_name, id, shipment_type } = item || {};
  return (
    <div className="grid text-sm grid-cols-5 gap-2">
      <h2>{index + 1}</h2>
      {/* <h2>{BatchNumber}</h2> */}
      <h2>{customer_name}</h2>
      <h2>{shipment_type}</h2>
      <h2>{batch}</h2>
      <h2>₦{amount_due}</h2>
    </div>
  );
};

export default SinglePendingPayment;
