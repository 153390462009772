import React from 'react'
import Customer from '../pages/components/customer/Customer'

const CustomerPage = () => {
  return (
    <div>
      <Customer/>
    </div>
  )
}

export default CustomerPage
