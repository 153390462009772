import React, { useState } from "react";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";

const UpdateModal = ({
  isOpen,
  onClose,
  paymentStatus,
  setPaymentStatus,
  data,
}) => {
  const { updatePayment, paymentLoading } = useStateContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updatePayment({
      id: data?.id,
      status: paymentStatus,
      type: "sea",
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded w-1/2 h-2/2 overflow-y-auto">
        <button onClick={onClose} className="float-right">
          X
        </button>
        <form onSubmit={handleSubmit} className="m-3">
          <img src={MyAfrimall} alt="logo" className="w-[15rem] m-6" />
          <div className="p-6">
            <h1 className="font-bold text-center">Update</h1>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Payment Status
            </label>
            <select
              value={paymentStatus || ""}
              onChange={(e) => setPaymentStatus(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name=""
              id=""
            >
              <option value="Not Paid">Not Paid</option>
              <option value="Paid">Paid</option>
            </select>
          </div>

          <button
            type="submit"
            className="bg-[#049805] w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            {paymentLoading ? <Spinner /> : "Update"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateModal;
