import React, { useEffect, useState } from "react";
import LocationCard from "./LCard";
import { useStateContext } from "pages/context/StateContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-activity";

const Location = () => {
  const { getAllLocations, token, config, baseUrl, getAllCountries } =
    useStateContext();
  const [location, setLocation] = useState("");
  const [country, setcountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCountryLoading, setIsCountryLoading] = useState(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const url = `${baseUrl}/admin/add-location`;
    try {
      const res = await axios.post(
        url,
        { location_name: location },
        config(token)
      );
      toast.success("Location Added successfully");
      setLocation("");
      getAllLocations();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const addCountry = async (e) => {
    setIsCountryLoading(true);
    e.preventDefault();
    const url = `${baseUrl}/admin/add-country`;
    try {
      const res = await axios.post(
        url,
        { country_name: country },
        config(token)
      );
      toast.success("Country Added successfully");
      setcountry("");
      getAllCountries();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
    } finally {
      setIsCountryLoading(false);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Location</h1>
      </div>

      <div className="mx-3 font-bold">
        <h1>Location</h1>
      </div>
      <form className="rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
        {/* First row */}
        <div className="flex mb-4">
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2">
              Enter a country
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="country"
              name="country"
              type="text"
              placeholder="e.g Nigeria"
              onChange={(e) => setcountry(e.target.value)}
              value={country || ""}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="block text-gray-700 text-sm mb-2">
              Enter a location
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="location"
              name="location"
              type="text"
              //   require
              placeholder="e.g oyo"
              onChange={(e) => setLocation(e.target.value)}
              value={location || ""}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center p-6 gap-4 md:gap-0">
          <button
            onClick={addCountry}
            disabled={isCountryLoading}
            type="submit"
            className="bg-[#049805] cursor-pointer text-white font-bold py-2 px-4 w-full md:w-1/4 rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            {isCountryLoading ? <Spinner /> : "  Add Country"}
          </button>

          <button
            type="submit"
            className="bg-[#049805] cursor-pointer text-white font-bold py-2 px-4 w-full md:w-1/4 rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Add Location"}
          </button>
        </div>
        <LocationCard />
      </form>
    </div>
  );
};

export default Location;
