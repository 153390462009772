import React, { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "pages/context/StateContext";
import axios from "axios";

const EditCargoPriceModal = ({ isOpen, handleCloseModal, priceData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [shipment, setShipment] = useState(""); 
  const { baseUrl, config, getAllCargoPrice } = useStateContext(); 
  
  useEffect(() => {
    if (priceData) {
      setPrice(priceData.price_per_kg);
      setShipment(priceData.shipment_type);
    }
  }, [priceData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const url = `${baseUrl}/admin/cargo-price/${priceData.id}`;

    try {
      const res = await axios.put(url, { 
        price_per_kg: price,
        shipment_type: shipment 
      }, config()); 
      
      handleCloseModal();
      toast.success(res.data.message || "Details updated successfully!");
      getAllCargoPrice();
      setPrice(""); 
      setShipment(""); 
    } catch (error) {
      console.error("Error editing shipment price:", error);
      toast.error("Failed to edit shipment price. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center lg:ml-[13rem] bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Edit Shipment Price
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Shipment Type:
                </label>
                <select
                  name="shipment"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={shipment}
                  onChange={(e) => setShipment(e.target.value)}
                >
                  <option value="">Choose Shipment Type</option>
                  <option value="air">Air</option>
                  <option value="sea">Sea</option>
                </select>
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter Price (per kg):
                </label>
                <input
                  type="text"
                  name="price"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default EditCargoPriceModal;
