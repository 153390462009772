import React from "react";
import { useNavigate } from "react-router-dom";

import { useStateContext } from "../../context/StateContext";

const SeaActionModal = ({
  showModal,
  setShowModal,
  setShowUpdateModal,
  setOpenUpdateModal,
  currentEditItem, // New prop
  onDelete,
}) => {
  const navigate = useNavigate();

  const { initializeEditData } = useStateContext();

  // const handleEditClick = () => {
  //   const { Action, ...serializableRowData } = currentEditItem;
  //   navigate("/home/sea/edit", {
  //     state: { dataToEdit: currentEditItem },
  //   });
  // };

  // console.log(currentEditItem)

  return (
    <>
      {showModal ? (
        <div className="fixed inset-0 z-50 flex items-start justify-between py-20 px-10 ">
          <div></div>
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 blur opacity-90"></div>
          <div className="bg-white p-5 rounded shadow-lg w-1/8 mt-10 ml-10 z-10">
            <span className="block text-right">
              <button onClick={() => setShowModal(false)}> &times;</button>
            </span>
            <div className="flex flex-col space-y-4 text-sm">
              <button
                className="bg-[#94a3b8] text-white py-1 px-3 rounded"
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setShowUpdateModal(true); // Open UpdateTrackingModal
                }}
              >
                Update Tracking
              </button>
              <button
                className="bg-green-500 text-white py-1 px-3 rounded"
                onClick={() => {
                  setShowModal(false); // Close ActionModal
                  setOpenUpdateModal(true); // Open UpdateModal
                }}
              >
                Update
              </button>
              {/* <button
                onClick={handleEditClick}
                className="bg-[#3771C8] text-white py-1 px-3 rounded"
              >
                Edit
              </button> */}
              {/* <button className="bg-[#FF0036] text-white py-1 px-3 rounded">
                Delete
              </button> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SeaActionModal;
