import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const ScannerCodeLineChart = () => {
    const dataset1 = [10, 20, 30, 75, 85, 90, 120, 100, 110, 120, 130, 140];
    const dataset2 = [20, 25, 40, 50, 65, 100, 90, 105, 110, 115, 90, 125];
    const dataset3 = [30, 35, 40, 45, 60, 60, 95, 100, 105, 110, 115, 120];
    
    
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

    const chartConfig = {
        type: "line",
        data: {
          labels: months,
          datasets: [
            {
              label: "Scanner Code",
              data: [
                ...dataset1.map((value, index) => ({
                  x: index,
                  y: value,
                })),
                ...dataset2.map((value, index) => ({
                  x: index,
                  y: value,
                })),
                ...dataset3.map((value, index) => ({
                  x: index,
                  y: value,
                })),
              ],
              borderColor: "rgba(255, 99, 132, 1)",
              tension: 0.1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              min: 0,
              max: 4000,
              ticks: {
                stepSize: 1000,
              },
            },
            x: {
              grid: {
                display: false, // Hide the x-axis grid lines
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      };

	const chartRef = useRef(null);

	useEffect(() => {
		const ctx = chartRef.current.getContext("2d");
		const lineChart = new Chart(ctx, chartConfig);

		return () => lineChart.destroy();
	}, []);

	const Indicator = ({ color, label }) => {
		return (
			<div className='flex items-center gap-1'>
				<div className={`w-2 h-2 rounded-full ${color}`} />
				<h2>{label}</h2>
			</div>
		);
	};

	return (
		<div className='bg-white p-4 mt-6'>
			{/* <h3 className='font-medium'>Generated Codes</h3> */}
			<div className='flex flex-wrap md:flex-row md:items-center gap-4 md:justify-end text-sm my-4 text-gray-600'>
				{/* <Indicator label='Generated Codes' color={"bg-purple-600"} />
				<Indicator label='Accepted Codes' color={"bg-purple-400"} />
				<Indicator label='Rejected Codes' color={"bg-purple-800"} /> */}
			</div>
			<canvas ref={chartRef}></canvas>
		</div>
	);
};

export default ScannerCodeLineChart;
