import React from 'react'
import CreateShipment from '../pages/components/createShipment/CreateShipment'

const CreateShipmentPage = () => {
  return (
    <div>
      <CreateShipment />
      
    </div>
  )
}

export default CreateShipmentPage
