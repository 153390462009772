import React from "react";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";

const Pagination = ({ table, buttons }) => {
  return (
    <div>
      <div className="flex items-center justify-between mt-5">
        <div className="text-white bg-green-600 py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {buttons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
