import React, { useEffect, useRef } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { LiaCopySolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import SeaSingleViewItem from "./SeaSingleViewItem";
import Table from "Utils/Table";
import { useStateContext } from "pages/context/StateContext";
import { MdPrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import logo from "../../Assest/images/MyAfrimall.png";
const tableHeader = ["S/N", "Item Name", "kg"];

const SeaViewItem = () => {
  const { seaaItems, isLoading, imageUrl } = useStateContext();
  const printComp = useRef(null);

  const { data, package_information } = seaaItems;
  const handlePrint = useReactToPrint({
    content: () => printComp.current,
  });

  // console.log(seaaItems);
  return (
    <div className="">
      <div className="p-6">
        <Link to="/home/sea">
          {" "}
          <AiOutlineArrowLeft />
        </Link>
      </div>

      <div className="font-bold flex items-center justify-between m-4">
        <h2>View Items</h2>
        <MdPrint onClick={handlePrint} size={30} />
      </div>
      <div ref={printComp} className="p-4">
        <img className="w-32" src={logo} alt="" />
        <div className="lg:flex justify-center items-center gap-12 leading-loose p-4">
          <div>
            <h1 className="font-bold"> Shipment Type: Air Cargo</h1>
            <h1 className="font-bold">Reciever Information</h1>
            <p>Full Name: {data?.full_name}</p>
            <p>Phone Number: {data?.phone_number}</p>
            <p>Address: {data?.address}</p>
            <p>Declared Value: ₦{data?.declared_value}</p>
          </div>
          <div>
            <h1 className="font-bold">
              {" "}
              Payment Status: {data?.payment_status}
            </h1>
            <p>Special Delivery Notes: {data?.special_delivery_note}</p>
            <p>Box Packages: {data?.box_packaging}</p>
            <p>Insurance: {data?.insurance}</p>
            <p>Insurance Amount: ₦{data?.insurance_amount}</p>
          </div>
          <div>
            <h1 className="font-bold">Total Kg:{data?.total_kg} </h1>
          </div>
          <img
            src={`${imageUrl}${data?.picture_of_the_package}`}
            className="w-20 mx-auto my-2"
            alt=""
          />
        </div>
        <div>
          <h1 className="font-bold ml-12 p-6 text-center">
            Package Information
          </h1>
        </div>
        <div>
          <div className="p-3 md:p-6 max-w-full overflow-x-auto">
            <div className="table-responsive ">
              <Table
                headerContent={tableHeader}
                minSize={"700px"}
                loadingState={isLoading}
                cols={3}
                data={package_information}
                className="w-full "
                notPaginated={true}
              >
                {package_information?.map((item, index) => (
                  <div key={index} className="w-full">
                    <SeaSingleViewItem
                      // openModal={handleOpenModal}
                      item={item}
                      index={index}
                    />
                    <hr className="my-4 border-green-50" />
                  </div>
                ))}
              </Table>
            </div>
          </div>

          {/* <p>Showing 1 to 3 of 3 entries</p> */}
        </div>
      </div>
    </div>
  );
};

export default SeaViewItem;
