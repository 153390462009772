import { useStateContext } from "pages/context/StateContext";
import React from "react";

const LocationCard = () => {
  const { allLocations, countries } = useStateContext();
  return (
    <div className="flex flex-wrap gap-4">
      {/* First Card */}
      <div className="border bg-white p-4 rounded-lg shadow-md flex-1 md:max-w-1/2">
        <h2 className="text-2xl font-semibold mb-4">Countries We Ship To</h2>
        <ol className="list-decimal list-inside space-y-2">
          {countries?.length < 1 ? (
            <li>No Country at the moment</li>
          ) : (
            countries?.map(({ country_name }, ind) => {
              return <li key={ind}>{country_name}</li>;
            })
          )}
        </ol>
      </div>

      {/* Second Card */}
      <div className="border bg-white p-4 rounded-lg shadow-md flex-1 md:max-w-1/2">
        <h2 className="text-2xl font-semibold mb-4">Our Office Locations</h2>
        <ol className="list-decimal list-inside space-y-2">
          {allLocations.length < 1 ? (
            <li>No location at the moment</li>
          ) : (
            allLocations?.map((locat, ind) => (
              <li key={ind}>{locat?.location_name}</li>
            ))
          )}
        </ol>
      </div>
    </div>
  );
};

export default LocationCard;
