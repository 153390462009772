import React from 'react'

import ScannerItems from './components/Scanner/ScannerItems';

const ScannerItemsPage = () => {
  return (
    <div>
    <ScannerItems/>  
    </div>
  )
}

export default ScannerItemsPage
