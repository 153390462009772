import React from "react";
import DHLShippingChart from "./DHLShippingChart";
import BudgetDeliveryChart from "./BudgetDeliveryChart";
import SeaCargoChart from "./SeaCargoChart";
import AirCargoChart from "./AirCargoChart";
import PieChart from "./PieChart";
import ShipmentsChart from "./MobileListings/ShipmentsChart";

const MobileCharts = () => {
  return (
    <div>
      <div className="grid justify-center md:grid-cols-2 gap-5 mx-14  lg:mx-14 my-6  text-center py-2">
        <DHLShippingChart />
        <BudgetDeliveryChart />
        <AirCargoChart />
        <SeaCargoChart />
      </div>
      <div className="grid justify-center  md:grid-cols-2 gap-5 mx-14  lg:mx-14 my-6  text-center py-2 ">
        <div className="w-[19rem] md:w-[22rem] lg:w-[40rem] h-[21rem] border-2">
          <p className="py-2 font-bold">
            <ShipmentsChart />
          </p>
        </div>
        <div className="w-[19rem] md:w-[22rem] lg:w-[17rem] h-[21rem] border-2 lg:ml-[10.5rem]">
          <PieChart />
        </div>
      </div>
    </div>
  );
};

export default MobileCharts;
