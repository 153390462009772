import React from 'react'
import ViewAllUnsuccessful from "./components/Affiliate/ViewAllUnsuccessful"
const ViewAllUnsuccessfulPage = () => {
  return (
    <div>
<ViewAllUnsuccessful/>
    </div>
  )
}

export default ViewAllUnsuccessfulPage
