import React, { useState } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import ZoneTable from "./ZoneTable";
import ZoneModal from "./ZoneModal";
import { useStateContext } from "pages/context/StateContext";

const Zones = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { zones } = useStateContext();
  //console.log(zones);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddZone = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="mx-auto mt-0 p-4 bg-white">
        <div className="p-2 flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-2 items-center md:mb-2 pt-3 mb-5">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">Zone</p>
          </div>
          <button
            className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
            onClick={() => setIsModalOpen(true)}
          >
            Add Zone
          </button>
        </div>
      </div>
      {zones.length > 0 ? (
        <ZoneTable zones={zones} />
      ) : (
        <p className="text-gray-500 text-center my-20">
          No zones have been added yet
        </p>
        // <ZoneTable zones={zones} />
      )}
      {isModalOpen && (
        <ZoneModal
          isOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          handleAddZone={handleAddZone}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Zones;
