import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";

const PendingLeaveTable = () => {
  const { allLeave, getPendingLeaveRequest } = useStateContext();
  const [isLoading, setIsLoading] = useState(false)

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Name",
      accessorKey: "name",
      cell: (item) => <span>{item.row.original.user.firstname}</span>,
    },
    {
      header: "Type",
      accessorKey: "request_type",
    },
    {
      header: "Date",
      accessorKey: "leave_start_date",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (item) => (
        <p className="flex items-center justify-start">
          <button
            onClick={() => handleViewButton(item.row.original.id)}
            className="ml-2 text-white bg-[#3771C8] border p-2 px-4 rounded" disabled={isLoading[item.row.original.id]}
          >
            {isLoading[item.row.original.id] ? <Spinner/> : "View"}
          </button>
        </p>
      ),
    },
  ];
  const data = useMemo(() => allLeave, [allLeave]);
  const columns = useMemo(() => columnData, [columnData]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);

  const pageSize = 10;

    const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };

  const table = useReactTable(options);
  
  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            // navigate.push(
            //   {
            //     pathname: router.pathname,
            //     query: { page: i + 1 },
            //   },
            //   undefined,
            //   { shallow: true }
            // );
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  }, [startPage, endPage, currentPage]);

  const handleFilter = (value) => {
    setFilters(value);
  };
  const navigate = useNavigate();

  const handleViewButton = async (id) => {
    setIsLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    try {
      await getPendingLeaveRequest(id);
      navigate(`/home/leave/pending/${id}`);
    } catch (error) {
    } finally {
      setIsLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }
  };
 
  return (
    <div>
      <input
        type="text"
        placeholder="search"
        className="bg-gray-200 text-black border border-gray-300 rounded px-5 py-2 focus:outline-none focus:border-black-400"
        value={filters}
        onChange={(e) => handleFilter(e.target.value)}
      />
      <table className="mx-auto w-full mt-4">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className=" p-6 text-left bg-gray-200"
                  key={header.id}
                  w={header.getSize()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr className="" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <>
                  <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                    <div className="truncate-text">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-end">
        {/* <Button onClick={() => table.setPageIndex(0)}>First page</Button> */}

        <button
          isDisabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
          className="mr-5"
        >
          -
        </button>
        <ul className="flex justify-end gap-5">
          {paginationButtons.map((u, index) => (
            <li key={index}> {u}</li>
          ))}
        </ul>

        <button
          isDisabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
          className="ml-5"
        >
          +
        </button>

        <div>
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>
      </div>
    </div>
  );
};

export default PendingLeaveTable;
