import React from 'react'
import LeaveRequests from './components/StaffApp/LeaveRequests';
const LeaveRequestPage = () => {
  return (
    <div>
      <LeaveRequests/>
    </div>
  )
}

export default LeaveRequestPage
