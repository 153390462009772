import React, { useState, useEffect } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import { MdCancel } from "react-icons/md";
import { useStateContext } from "pages/context/StateContext"; // Import useStateContext from the context file
import axios from "axios";

const EditCountryModal = ({
  isOpen,
  handleCloseModal,
  handleSave,
  editData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [zone, setZone] = useState("");
  const { baseUrl, zones, getAllZones, config } = useStateContext(); // Destructure baseUrl and config from the context

  useEffect(() => {
    if (editData) {
      setCountry(editData.country || "");
      setZone(editData.zone || "");
      getAllZones();
    }
  }, [editData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const url = `${baseUrl}/admin/shipment-country`;

    try {
      const res = await axios.put(
        `${url}/${editData.id}`,
        { country, zone },
        config()
      );
      toast.success(res.data.message || "Details updated successfully!");
      handleSave(); // Call the handleSave function to refresh the data
      handleCloseModal();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to save details. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center lg:ml-[13rem] bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              <MdCancel />
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Edit Country
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter Country Name:
                </label>
                <input
                  type="text"
                  name="country"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="e.g America"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Select Zone:
                </label>
                <select
                  name="zone"
                  required
                  className="w-full p-2 border rounded mt-1"
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                >
                  <option value="">Select Zone</option>
                  {zones.map((zone) => (
                    <option key={zone.id} value={zone.zone}>
                      {zone.zone}
                    </option>
                  ))}
          
                </select>
              </div>

              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default EditCountryModal;
