import React from "react";
import priceUp from "../../Assest/images/priceup.png";
import priceDown from "../../Assest/images/pricedown.png";

const Prices = () => {
  return (
    <div className="flex space-x-5 font-bold text-xl">
      {/* DHL Price */}
      <div className="bg-gray-100 w-[15rem] h-32 rounded-md">
        <div className="flex justify-between px-3 py-2 mt-1">
          <p>DHL Price</p>
          <img src={priceUp} className="w-8 h-8 " />
        </div>
        <div className="flex px-3 mt-2">
          <p>
            <span>N80, 000</span> - <span>N200, 000</span>
          </p>
        </div>
        <p className="text-[#049805] text-[16px] font-medium text-center mt-2">
          9% increase since last month
        </p>
      </div>

      {/* UPS Price */}
      <div className="bg-gray-100 w-[15rem] h-32 rounded-md">
        <div className="flex justify-between px-3 py-2 mt-1">
          <p>UPS Price</p>
          <img src={priceUp} className="w-8 h-8 " />
        </div>
        <div className="flex px-3 mt-2">
          <p>
            <span>N80, 000</span> - <span>N200, 000</span>
          </p>
        </div>
        <p className="text-[#049805] text-[16px] font-medium text-center mt-2">
          3% increase since last month
        </p>
      </div>

      {/* Air Cargo Price */}
      <div className="bg-gray-100 w-[15rem] h-32 rounded-md">
        <div className="flex justify-between px-3 py-2 mt-1">
          <p>Air Cargo Price</p>
          <img src={priceDown} className="w-8 h-8 " />
        </div>
        <div className="flex px-3 mt-2">
          <p>
            <span>N80, 000</span> - <span>N200, 000</span>
          </p>
        </div>
        <p className="text-[#E13535] text-[16px] font-medium text-center mt-2">
          3% decrease since last month
        </p>
      </div>

      {/* Sea Cargo Price */}
      <div className="bg-gray-100 w-[15rem] h-32 rounded-md">
        <div className="flex justify-between px-3 py-2 mt-1">
          <p className="font-semibold">Sea Cargo Price</p>
          <img src={priceUp} className="w-8 h-8 " />
        </div>
        <div className="flex px-3 mt-2">
          <p>
            <span>N80, 000</span> - <span>N200, 000</span>
          </p>
        </div>
        <p className="text-[#049805] text-[16px] font-medium text-center mt-2">
          9% increase since last month
        </p>
      </div>
    </div>
  );
};

export default Prices;
