import React, { useState } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import TanTable from "Utils/TanTable";
import { useStateContext } from "pages/context/StateContext";
import { FiEdit2 } from "react-icons/fi";
import PriceModal from "./PriceModal";
import EditCargoPriceModal from "./EditCargoPrizeModal";

const CargoPrices = () => {
  const { isLoading, allCargoPrice } = useStateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal
  const [selectedPriceData, setSelectedPriceData] = useState(null); // State for selected row data

  const handleEdit = (rowData) => {
    setSelectedPriceData(rowData); // Set the selected row data
    setIsEditModalOpen(true); // Open the edit modal
  };

  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Price/kg",
      accessorKey: "price_per_kg",
    },
    {
      header: "Shipment",
      accessorKey: "shipment_type",
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <div className="flex gap-4">
            <FiEdit2
              onClick={() => handleEdit(rowData)} // Pass the entire row data to handleEdit
              className="cursor-pointer text-[#4d6cf6]"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex gap-2 items-center md:mb-2 p-6 mb-5">
        <FaHome className="m-0" />
        <p className="text-black">Home</p>
        <FaChevronRight className="m-1 text-[#049805]" />
        <p className="text-[#049805]">Cargo Prices</p>
      </div>
      <div className="flex justify-end p-4">
        <button
          className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
          onClick={() => setIsModalOpen(true)} // Open the modal on click
        >
          Add Price
        </button>
      </div>
      <TanTable
        columnData={columnData}
        data={allCargoPrice}
        loadingState={isLoading}
      />
      <PriceModal
        isOpen={isModalOpen} // Pass the modal open state
        handleCloseModal={() => setIsModalOpen(false)} // Close the modal
      />
      <EditCargoPriceModal
        isOpen={isEditModalOpen} // Pass the edit modal open state
        handleCloseModal={() => setIsEditModalOpen(false)} // Close the edit modal
        priceData={selectedPriceData} // Pass the selected row data to the edit modal
      />
    </div>
  );
};

export default CargoPrices;
