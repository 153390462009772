import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import DateFormatter from "Utils/DateFormatter";
const ViewComplaint = () => {
  const [data, setData] = useState();
  const { config, token, baseUrl } = useStateContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const GetData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/admin/suggestion/${id}`,
        config(token)
      );
      const res = response.data.data;
      setData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <div className="py-10 p-4">
      <div className="bg-white p-8 rounded-md">
        <p className="capitalize">
          FullName: {data?.user?.firstname} {data?.user?.lastname}
        </p>
        <div className="flex gap-2 items-center">
          <p>Date: </p>
          <span>
            <DateFormatter time={data?.user?.created_at} />
          </span>
        </div>
        <h2 className="mt-4 font-medium">Complaint:</h2>
        <p className=""> {data?.comment}</p>
      </div>
    </div>
  );
};

export default ViewComplaint;
