import React, { useState } from "react";

const Pagination = ({ totalEntries, numToShow, onNumToShowChange }) => {
  const [searchTerm, setSearchTerm] = useState(''); // Add this state for searchTerm
  
  const endRange = numToShow < totalEntries ? numToShow : totalEntries;

  return (
    <div className="flex justify-between my-3">
      <div className="flex items-center">
        <label htmlFor="entries" className="mr-2">
          Show entries:
        </label>
        <select
          id="entries"
          className="border rounded px-2 py-1"
          onChange={onNumToShowChange}
          value={numToShow}
        >
          <option value="10">10</option> {/* I corrected the displayed value for this option from 2 to 10 */}
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>

      {/* <div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border rounded px-2 py-1"
          />
        </div> */}
       {/* <div className="mt-4">
         Showing 1 to {endRange} of {totalEntries} entries 
      </div>  */}
    </div>
  );
};

export default Pagination;
