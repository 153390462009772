import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import React from "react";
import { useState } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
import EditModal from "./EditModal";
// import { FiMoreHorizontal } from "react-icons/fi";

const SingleAdmin = ({ item, index, openModal }) => {
  const { name, role, id, status } = item || {};
  console.log(item);
  const { baseUrl, config, token, getAllAdmins } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isReLoading, setIsReLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const openEditModal = () => {
    setIsEditing(true);
  };

  const closeEditModal = () => {
    setIsEditing(false);
  };

  const deleteAdmin = async () => {
    const url = `${baseUrl}/admin/delete-admin/${id}`;
    setIsLoading(true);
    try {
      const res = await axios.delete(url, config(token));
      toast.success(res.data.message);
      getAllAdmins();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const reactivateAdmin = async () => {
    const url = `${baseUrl}/admin/update-admin/${id}`;
    setIsReLoading(true);
    try {
      const res = await axios.post(url, {}, config(token));
      console.log(res);
      toast.success(res.data.message);
      getAllAdmins();
    } catch (error) {
      console.log(error);
    } finally {
      setIsReLoading(false);
    }
  };

  return (
    <>
      <div className="grid text-sm grid-cols-5 gap-2 capitalize">
        <h2>{index + 1}</h2>
        <h2>{name}</h2>
        <h2>{role}</h2>
        <h2>{status === "active" ? "Active" : "Inactive"}</h2>
        <div className="flex gap-2">
          <button
            className="bg-[#3771C8] text-white px-4 py-1 rounded w-fit"
            onClick={openEditModal}
          >
            Edit
          </button>
          <div>
            {status === "active" ? (
              <button
                onClick={deleteAdmin}
                disabled={isLoading}
                className="bg-red-600 text-white px-4 py-1 rounded w-fit flex items-center justify-center"
              >
                {isLoading ? <Spinner /> : "Deactivate"}
              </button>
            ) : (
              <button
                onClick={reactivateAdmin}
                className="bg-gray-600 text-white px-4 py-1 rounded w-fit"
              >
                {isReLoading ? <Spinner /> : "Reactivate"}
              </button>
            )}
          </div>
        </div>
      </div>

      {isEditing && (
        <EditModal
          closeModal={closeEditModal}
          adminData={item}
          getAllAdmins={getAllAdmins}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default SingleAdmin;
