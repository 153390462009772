import React, { useState } from "react";
import MyAfrimall from "../../Assest/images/MyAfrimall.png";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";

const UpdateAllTrackingModal = ({ isOpen, onClose, data }) => {
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [trackingDescription, setTrackingDescription] = useState("");
  const { updateTracking, paymentLoading } = useStateContext();

  const statusOptions = [
    "Picked Up",
    "Processed",
    "Cancelled",
    "Enroute",
    "Awaiting Customs Clearance",
    "Clearance Customs",
    "Out for Delivery",
    "Returned",
    "Shipment Completed",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    await updateTracking({
      id: data?.id,
      status: shipmentStatus,
      type: "batch",
      desc: trackingDescription,
    });
    setShipmentStatus("");
    setTrackingDescription("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded w-1/2 h-2/2 overflow-y-auto">
        <button onClick={onClose} className="float-right">
          X
        </button>
        <form onSubmit={handleSubmit} className="m-3">
          <img src={MyAfrimall} alt="logo" className="w-[15rem] m-6" />
          <p className="text-center font-bold">Update Tracking</p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Shipment Status
            </label>
            <select
              value={shipmentStatus || ""}
              onChange={(e) => setShipmentStatus(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select Shipment Status</option>
              {statusOptions.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Update Tracking Description
            </label>
            <textarea
              value={trackingDescription || ""}
              onChange={(e) => setTrackingDescription(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter tracking description"
              rows="4"
            />
          </div>
          <button
            type="submit"
            disabled={paymentLoading}
            className="bg-[#049805] w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
          >
            {paymentLoading ? <Spinner /> : "Update"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateAllTrackingModal;
