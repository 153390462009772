import React from 'react';
import ViewPendingLvs from './components/StaffApp/ViewPendingLvs';
const ViewPendingLeaveRequestPage = () => {
  return (
    <div>
      <ViewPendingLvs/>
    </div>
  );
}

export default ViewPendingLeaveRequestPage;
