import DateFormatter from "Utils/DateFormatter";
import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";
const SingleTotalAffiliate = ({ item, index, openModal }) => {
  const {
    address,
    country,
    email,
    email_verfied,
    created_at,
    firstname,
    id,
    lastname,
    phone_number,
    platform,
    profile_photo,
    referral_code,
    state,
    status,
  } = item || {};
  // console.log(item);
  return (
    <div>
      <div className="grid text-sm grid-cols-5 gap-2">
        <h2>{index + 1}</h2>
        <h2>
          {firstname} {lastname}
        </h2>
        {/* <h2>{0}</h2> */}
        <h2>{phone_number}</h2>
        <DateFormatter time={created_at} />

        <button
          className=" px-4 py-1 rounded"
          onClick={() => openModal(item)} // Set Modal to open when button is clicked
        >
          <FiMoreHorizontal />
        </button>
      </div>
    </div>
  );
};

export default SingleTotalAffiliate;
