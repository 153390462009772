import React from 'react'
import Ups from './components/Prices/DHL/UpsPrice'

const UpsPricePage = () => {
  return (
    <div>
      <Ups/>
    </div>
  )
}

export default UpsPricePage
