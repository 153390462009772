import React, { useState, useEffect } from "react";
import { FaHome, FaChevronRight } from "react-icons/fa";
import InventoryModal from "./InventoryModal";
import InventoryTable from "./InventoryTable";
import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "pages/context/StateContext";

const Inventory = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {inventory} = useStateContext();

  const handleAddInventory = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="mx-auto mt-0 p-4 bg-white flex flex-col h-screen">
        <div className="p-2 flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-2 items-center md:mb-0 pt-7 mb-5">
            <FaHome className="m-0" />
            <p className="text-black">Home</p>
            <FaChevronRight className="m-1 text-[#049805]" />
            <p className="text-[#049805]">Inventory</p>
          </div>
          <button
            className="bg-[#049805] cursor-pointer text-white px-4 py-2 rounded w-full md:w-auto"
            onClick={handleAddInventory}
          >
            Add Inventory
          </button>
        </div>

        <div className="flex-grow flex w-full">
          {inventory.length > 0 ? (
            <InventoryTable /> // Render table when there is data
          ) : (
            <p className="text-gray-500 text-lg">You have no inventories yet</p> // Render text when there is no data
          )}
        </div>
      </div>
      {isModalOpen && <InventoryModal handleCloseModal={handleCloseModal} />}
      <ToastContainer />
    </>
  );
};

export default Inventory;
