import React, { useState, useMemo } from "react";
import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { useStateContext } from "pages/context/StateContext";
import { Spinner } from "react-activity";
import DeleteInventoryModal from "./DeleteInventoryModal";
import EditInventoryModal from "./EditInventoryModal";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
const InventoryTable = () => {
  const { inventory, isLoading } = useStateContext();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null); // State for selected item data
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal visibility
  const handleEditClick = (item) => {
    setSelectedItem(item); // Populate the state with the selected item's data
    setIsEditModalOpen(true); // Open the edit modal
  };

  const handleDeleteClick = (itemId) => {
    setSelectedItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnData = [
    {
      header: "S/N",
      accessorKey: "serial_number",
      cell: (item) => <span>{item.row.index + 1}</span>,
    },
    {
      header: "Item Name",
      accessorKey: "name",
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
    },
    {
      header: "Unit",
      accessorKey: "unit",
    },
    {
      header: "Created at",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const date = row.original.created_at;
        return <div className="capitalize">{formatDate(date)}</div>;
      },
    },
    {
      header: "Updated at",
      accessorKey: "updated_at",
      cell: ({ row }) => {
        const date = row.original.created_at;
        return <div className="capitalize">{formatDate(date)}</div>;
      },
    },

    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => {
        const itemId = row.original.id;
        const item = row.original;
        
        const handleEdit = () => {
          handleEditClick(item);
        };

        const handleDelete = () => {
          handleDeleteClick(itemId);
        };

        return (
          <div className="flex items-center">
            <button
              onClick= {handleEdit}
              className="flex items-center text-blue-600 mr-2"
            >
              <AiOutlineEdit className="mr-1" />
            </button>
            <button
              onClick={handleDelete}
              className="flex items-center text-blue-600"
            >
              <FaTrash className="mr-1" />
            </button>
          </div>
        );
      },
    },
  ];
  const data = useMemo(() => inventory, [inventory]);
  const columns = useMemo(() => columnData, [columnData]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [filters, setFilters] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const options = {
    data,
    columns,
    state: {
      globalFilter: filters,
      sorting: sorting,
      columnFilters: columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFilters,
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  };
  const table = useReactTable(options);
  const handleFilter = (value) => {
    setFilters(value);
  };
  const startPage = useMemo(() => {
    return Math.max(0, pageIndex - Math.floor(pageSize / 2));
  }, [pageIndex, pageSize]);

  const endPage = useMemo(() => {
    return Math.min(table.getPageCount() - 1, startPage + pageSize - 1);
  }, [table, startPage, pageSize]);

  function isPageActive(pageIndex, currentPage) {
    return pageIndex === currentPage;
  }

  const currentPage = table.getState().pagination.pageIndex;
  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            table.setPageIndex(i);
            setPageIndex(i);
          }}
          className={`{${
            isPageActive(i, currentPage)
              ? "current-page text-white bg-[#2B55B7] shadow-md"
              : "text-[#A7B1C5]"
          }} transition-background-color duration-300
          transition-text-color duration-300
          rounded-md px-2 py-1 flex items-center text-sm cursor-pointer`}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPage, endPage, currentPage]);
  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row justify-between items-center p-4">
        <div className="flex-1 relative mt-4 md:mt-0 w-1/3 md:w-1/2 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-1/3 focus:outline-none focus:ring focus:border-blue-300"
            value={filters}
            onChange={(e) => handleFilter(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch />
          </div>
        </div>
      </div>

      <table className="mx-auto w-full mt-4">
        <thead className="justify-between">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className=" p-5 text-left bg-gray-200"
                  key={header.id}
                  w={header.getSize()}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading ? (
          <div className="flex items-center justify-center h-screen">
            <Spinner />
          </div>
        ) : (
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr className="" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <>
                    <td className="p-3" key={cell.id} w={cell.column.getSize()}>
                      <div className="truncate-text">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  </>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <div className="flex items-center justify-between">
        <div className="text-white bg-[#2B55B7] py-1 px-3 rounded-md">
          <span className="border-2 p-1.5 inline-flex items-center w-7 h-6 mr-2 font-semibold">
            {" "}
            {table.getState().pagination.pageIndex + 1}{" "}
          </span>
          <span className="text-[#A7B1C5]">of {table.getPageCount()}</span>
        </div>

        <div className="flex">
          <button
            isDisabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="mr-5"
          >
            <BsChevronDoubleLeft />
          </button>
          <ul className="flex justify-end gap-5">
            {paginationButtons.map((u, index) => (
              <li key={index}> {u}</li>
            ))}
          </ul>

          <button
            isDisabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="ml-5"
          >
            <BsChevronDoubleRight />
          </button>
        </div>
      </div>
      <DeleteInventoryModal
        isOpen={isDeleteModalOpen}
        handleCloseModal={() => setIsDeleteModalOpen(false)}
        itemId={selectedItemId}
      />
      <EditInventoryModal
        isOpen={isEditModalOpen}
        handleCloseModal={() => setIsEditModalOpen(false)}
        item={selectedItem}
      />
    </div>
  );
};

export default InventoryTable;
