import React from "react";
import AppRoute from "./pages/Routes";
// import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-activity/dist/library.css";

const App = () => {
  return <AppRoute />;
};

export default App;
