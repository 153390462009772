import React from 'react';
import { useState } from 'react';
// import { FaRegTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AdminEditForm from "./AdminEditForm";
import { FaArrowLeftLong } from "react-icons/fa6";

const data = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
      >
        <path
          d="M7.66667 8.00016H18.3333M7.66667 13.3335H15.6667M21 1.3335C22.0609 1.3335 23.0783 1.75492 23.8284 2.50507C24.5786 3.25521 25 4.27263 25 5.3335V16.0002C25 17.061 24.5786 18.0784 23.8284 18.8286C23.0783 19.5787 22.0609 20.0002 21 20.0002H14.3333L7.66667 24.0002V20.0002H5C3.93913 20.0002 2.92172 19.5787 2.17157 18.8286C1.42143 18.0784 1 17.061 1 16.0002V5.3335C1 4.27263 1.42143 3.25521 2.17157 2.50507C2.92172 1.75492 3.93913 1.3335 5 1.3335H21Z"
          stroke="#1F1E1E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    Request:
      'You have a request from  one of our Affiliates Moses Abimombola',
    Accept: 'Accept Request ',
    Reject: 'Reject Request' ,
  },

  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
      >
        <path
          d="M7.66667 8.00016H18.3333M7.66667 13.3335H15.6667M21 1.3335C22.0609 1.3335 23.0783 1.75492 23.8284 2.50507C24.5786 3.25521 25 4.27263 25 5.3335V16.0002C25 17.061 24.5786 18.0784 23.8284 18.8286C23.0783 19.5787 22.0609 20.0002 21 20.0002H14.3333L7.66667 24.0002V20.0002H5C3.93913 20.0002 2.92172 19.5787 2.17157 18.8286C1.42143 18.0784 1 17.061 1 16.0002V5.3335C1 4.27263 1.42143 3.25521 2.17157 2.50507C2.92172 1.75492 3.93913 1.3335 5 1.3335H21Z"
          stroke="#1F1E1E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    Request:
      'You have a request from  one of our Affiliates Moses Abimombola',
    Accept: 'Accept Request ',
    Reject: 'Reject Request' ,
  },

  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
      >
        <path
          d="M7.66667 8.00016H18.3333M7.66667 13.3335H15.6667M21 1.3335C22.0609 1.3335 23.0783 1.75492 23.8284 2.50507C24.5786 3.25521 25 4.27263 25 5.3335V16.0002C25 17.061 24.5786 18.0784 23.8284 18.8286C23.0783 19.5787 22.0609 20.0002 21 20.0002H14.3333L7.66667 24.0002V20.0002H5C3.93913 20.0002 2.92172 19.5787 2.17157 18.8286C1.42143 18.0784 1 17.061 1 16.0002V5.3335C1 4.27263 1.42143 3.25521 2.17157 2.50507C2.92172 1.75492 3.93913 1.3335 5 1.3335H21Z"
          stroke="#1F1E1E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    Request:
      'You have a request from  one of our Affiliates Moses Abimombola',
    Accept: 'Accept Request ',
    Reject: 'Reject Request' ,
  },

  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
      >
        <path
          d="M7.66667 8.00016H18.3333M7.66667 13.3335H15.6667M21 1.3335C22.0609 1.3335 23.0783 1.75492 23.8284 2.50507C24.5786 3.25521 25 4.27263 25 5.3335V16.0002C25 17.061 24.5786 18.0784 23.8284 18.8286C23.0783 19.5787 22.0609 20.0002 21 20.0002H14.3333L7.66667 24.0002V20.0002H5C3.93913 20.0002 2.92172 19.5787 2.17157 18.8286C1.42143 18.0784 1 17.061 1 16.0002V5.3335C1 4.27263 1.42143 3.25521 2.17157 2.50507C2.92172 1.75492 3.93913 1.3335 5 1.3335H21Z"
          stroke="#1F1E1E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    Request:
      'You have a request from  one of our Affiliates Moses Abimombola',
    Accept: 'Accept Request ',
    Reject: 'Reject Request' ,
  },
  // Add other data objects here...
];

const AdminOfficer = () => {
  const [showAdminEditForm, setShowAdminEditForm] = useState(false);

  const handleAcceptRequest = () => {
    // Add any logic here you might need before showing the AdminEditForm
    setShowAdminEditForm(true);
  }
  const handleGoBack = () => {
    setShowAdminEditForm(false);
  };

  return (
    <>
       
      {showAdminEditForm ? (
        <div>
        <button
          className=" text-whit font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
          onClick={handleGoBack}
        >
          <FaArrowLeftLong />
        </button>
        <AdminEditForm/>
       
      </div>
        ) : (
          <>
      <div className="flex gap-2 text-black p-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M20.5 19.5V11C20.5 10.8448 20.4639 10.6916 20.3944 10.5528C20.325 10.4139 20.2242 10.2931 20.1 10.2L13.1 4.95C12.9269 4.82018 12.7164 4.75 12.5 4.75C12.2836 4.75 12.0731 4.82018 11.9 4.95L4.9 10.2C4.7758 10.2931 4.675 10.4139 4.60557 10.5528C4.53614 10.6916 4.5 10.8448 4.5 11V19.5C4.5 19.7652 4.60536 20.0196 4.79289 20.2071C4.98043 20.3946 5.23478 20.5 5.5 20.5H9.5C9.76522 20.5 10.0196 20.3946 10.2071 20.2071C10.3946 20.0196 10.5 19.7652 10.5 19.5V16.5C10.5 16.2348 10.6054 15.9804 10.7929 15.7929C10.9804 15.6054 11.2348 15.5 11.5 15.5H13.5C13.7652 15.5 14.0196 15.6054 14.2071 15.7929C14.3946 15.9804 14.5 16.2348 14.5 16.5V19.5C14.5 19.7652 14.6054 20.0196 14.7929 20.2071C14.9804 20.3946 15.2348 20.5 15.5 20.5H19.5C19.7652 20.5 20.0196 20.3946 20.2071 20.2071C20.3946 20.0196 20.5 19.7652 20.5 19.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1>Home</h1>
        <svg
          className="pt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M1.60047 0.800049L0.480469 2.00005L6.40047 8.00005L0.480469 14L1.60047 15.2L8.80047 8.00005L1.60047 0.800049Z"
            stroke="black"
            fill="#1F1E1E"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <h1 className="text-[#049805]">Account Officer</h1>
      </div>

      <div className="font-medium p-4 flex justify-between items-center">
        <p>You have 7 Pending requests. Kindly click on them to view </p>
        <Link to="#" className="text-[#049805] pr-6">
          View all
        </Link>
      </div>

    
      
            {data.map((item, index) => (
              <div
                key={index}
                className="flex flex-wrap items-center justify-between p-8 rounded-md m-4 shadow-xl shadow-gray-200"
              >
                <div className="flex justify-between gap-4 w-full">
                  {typeof item.icon === 'object' ? (
                    <>{item.icon}</>
                  ) : (
                    <img
                      src={item.icon}
                      alt="Author"
                      className="w-20 h-20 rounded-full object-cover"
                    />
                  )}

                  <div className="flex flex-col md:flex-row md:items-center justify-between w-full gap-4">
                    <div>
                      <h2 className="font-bold text-gray-700">{item.name}</h2>
                      <p className="text-gray-600">{item.Request}</p>
                    </div>
                    <div className="flex items-center text-xs gap-3">
                      <button
                        className="bg-blue-500 text-white font-semibold px-4 py-2 rounded"
                        onClick={handleAcceptRequest}
                      >
                        {item.Accept}
                      </button>
                      <button className="bg-red-500 text-white  font-semibold px-4 py-2 rounded">{item.Reject}</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default AdminOfficer;
