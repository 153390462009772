import React from "react";
// import Aircargo from "./components/AirCargoPlacement/Aircargo";
import { ToastContainer } from "react-toastify";
import AirCargoTable from "./components/AirCargoPlacement/AirCargoTable";

const AircargoPage = () => {
  return (
    <div>
      <AirCargoTable/>
        {/* <Aircargo/> */}
        <ToastContainer/>
    </div>
  );
};

export default AircargoPage;

