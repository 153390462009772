import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
// import { Link } from "react-router-dom";
import Table from "Utils/Table";
import SingleViewBatchDetails from "./SingleViewBatchDetails";
import { useStateContext } from "pages/context/StateContext";

const ViewBatchDetails = () => {
  const { singleBatchDetails } = useStateContext();

  console.log(singleBatchDetails);

  const {
    batch,
    country,
    good_declared_value,
    insurance,
    payment_mode,
    payment_status,
    price,
    receiver_address,
    receiver_email,
    receiver_name,
    receiver_phone,
    shipment_id,
    special_delivery_note,
    total_kg,
    tracking_number,
  } = singleBatchDetails;
  const Data = [
    {
      serialNo: 1,
      ReceiverName: receiver_name,
      TrackingNumber: tracking_number,
      ReceiverAddress: receiver_address,
      ReceiverEmail: receiver_email,
      ReceiverPhone: receiver_phone,
      Country: country,
      Price: `₦${price}`,
      GoodsDeclaredValue: `₦${good_declared_value}`,
      SpecialDeliveryNote: special_delivery_note,
      InsuranceTotalKg: `${insurance}/${total_kg}`,
      PayModePaymentStatus: `${payment_mode}/${payment_status}`,
      // PaymentStatus: "not paid",
      // TotalKg: "155",
    },
  ];

  const tableHeader = [
    "S/N",
    "Receiver Name",
    "Tracking Number",
    "Receiver Address",
    "Receiver Email",
    "Receiver Phone",
    "Country",
    "Price",
    "Goods Declared Value",
    "Special Delivery Note",
    "Insurance/TotalKg",
    "PayMode/Payment Status ",
    // "PaymentStatus ",
    // "TotalKg",
  ];
  return (
    <div className="bg-white">
      <div className=" text-center leading-loose">
        <h1 className="font-bold">View Batch Details</h1>
        <p>Batch Number {batch}</p>
        <p>Total Kg in Batch: {total_kg}</p>

        <img
          src="https://dictionary.cambridge.org/images/thumb/packag_noun_002_26133.jpg?version=5.0.357"
          className="w-20 mx-auto my-2"
          alt=""
        />
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mx-6 px-12">
        <div className="m-4 flex text-xs gap-2">
          <a
            href="file"
            download
            className="bg-[#E5E5E5] text-black py-2 px-4 rounded"
          >
            CSV
          </a>
          <button className="bg-[#E5E5E5] text-black py-2 px-4 rounded">
            Copy
          </button>
        </div>

        <div className="relative mt-4 md:mt-0 w-full md:w-1/2 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-200 text-gray-800 rounded-md py-2 pl-10 pr-4 w-full focus:outline-none focus:ring focus:border-blue-300"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch />
          </div>
        </div>
      </div>

      <div className="w-full max-w-5xl mb-4 overflow-x-auto table-responsive">
        <Table
          headerContent={tableHeader}
          minSize={"2000px"}
          // loadingState={isLoading}
          cols={12}
          data={Data}
        >
          {Data?.map((item, index) => (
            <div key={index}>
              <SingleViewBatchDetails
                // openModal={handleOpenModal}
                item={item}
                index={index}
              />
              <hr className="my-4 border-green-50" />
            </div>
          ))}
        </Table>
      </div>

      {/* <h1 className="font-bold text-center p-3">BATCH 1</h1> */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full px-4 md:px-20 border-b-2 p-3 leading-loose">
        <div className=" ">
          <h1>Tracking Number</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">MYF1345440059</p>
          <h1>Receiver Address</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">Indianapolis</p>
          <h1>Receiver Phone Number</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">13174140947</p>
          <h1>Price</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">91375</p>
          <h1>Special Delivery Note</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">USA 021</p>
          <h1>Mode of Payment</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">Bank Transfer </p>
          <h1>Total Kg</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">23</p>
        </div>

        <div className="">
          <h1>Tracking Number</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">MYF1345440059</p>
          <h1>Receiver Address</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">Indianapolis</p>
          <h1>Receiver Phone Number</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">13174140947</p>
          <h1>Price</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">91375</p>
          <h1>Special Delivery Note</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">USA 021</p>
          <h1>Mode of Payment</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">Bank Transfer </p>
          <h1>Total Kg</h1>
          <p className="bg-[#F8F8F8] rounded-lg p-2">23</p>
        </div>
      </div> */}
      {/* <p className="pl-12">Showing 1 to 10 of 4,123 entries</p> */}
    </div>
  );
};

export default ViewBatchDetails;
