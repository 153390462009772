import { config } from "Utils/apiConfig";
import axios from "axios";
import { useStateContext } from "pages/context/StateContext";
import React, { useState } from "react";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const ZoneModal = ({ isOpen, handleCloseModal }) => {
  const [zones, setZones] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { getAllZones, baseUrl } = useStateContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `${baseUrl}/admin/zone`;
    setIsLoading(true);

    try {
      const res = await axios.post(url, { zone: zones }, config());
      handleCloseModal();
      toast.success(res.data.message || "success");
      setZones("");
      getAllZones();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center lg:ml-[13rem] bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded w-1/3 h-1/2 overflow-y-auto">
            <button onClick={handleCloseModal} className="float-right">
              X
            </button>
            <h2 className="text-xl font-semibold mb-4 flex justify-center">
              Add Zone
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-sm font-medium mb-2">
                  Enter Zone Name:
                </label>
                <input
                  type="text"
                  name="zone"
                  required
                  className="w-full p-2 border rounded mt-1"
                  placeholder="e.g A,B,C"
                  value={zones}
                  onChange={(e) => setZones(e.target.value)}
                />
              </div>

              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#049805] text-white flex items-center justify-center px-4 py-2 w-full rounded"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default ZoneModal;
